import React, { useState } from "react";
import { FormSteper } from "../../common";

import VermittlerDashboardStep from "./vermittlerDashboard";
import ActionCodeAddStep from "./actionCodeAdd.step";

function VermittlerDashboardStepManager() {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 2;

  const HandleCurrentStep = (step) => {
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  return (
    <FormSteper
      currentStep={currentStep}
      setCurrentStep={HandleCurrentStep}
      loading={loading}
      noHeader={[1, 2]}
      noFooter={[1, 2]}
      headerTitles={["", ""]}
    >
      {/* Step 1 */}
      <VermittlerDashboardStep handleCurrentStep={HandleCurrentStep} />
      <ActionCodeAddStep handleCurrentStep={HandleCurrentStep} />
    </FormSteper>
  );
}

export default VermittlerDashboardStepManager;
