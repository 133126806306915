import React from "react";
const PassToolTipContent = () => {
  return (
    <div
      style={{
        marginTop: "10px",
        padding: "20px",
        backgroundColor: "#E3F2FD",
        borderRadius: "8px",
      }}
    >
      {"Zu den starken Passwörtern gehören:"}
      <br />
      {"-Mindestens 8 Zeichen"} <br />
      {"-Mindestens ein Großbuchstabe"} <br />
      {"-Mindestens eine Ziffer"} <br />
      {"-Mindestens ein Sonderzeichen (@$!%*#?&^_-)"}
    </div>
  );
};

export default PassToolTipContent;
