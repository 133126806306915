import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { dateString, longDateTo10 } from "../../../core/utils/convert";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { ACTION_CODE_TYPE } from "../../../core/constants";

const getActionCodeCustomerDataFunc = async (params, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const today = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }); //+"T00:00:00Z";

  let typeFilter = params.actionCodeType
    ? `<condition attribute="arades_typ"  operator="eq" value="${params.actionCodeType}" />`
    : `<condition attribute="arades_typ"  operator="ne" value="${ACTION_CODE_TYPE.TRANSFER}" />`;

  let activeFilter = params.justActive
    ? `<condition attribute="statecode"  operator="eq" value="0" />`
    : "";

  var fetchQuery = `
  <fetch mapping="logical" version="1.0">
    <entity name="arades_aktionscode_nutzer" alias="au">  
      <attribute name="arades_aktionscode_nutzerid" />    
      <filter>
        <condition attribute="arades_kunde"  operator="eq" value="${params.customerId}" />
        ${activeFilter}
      </filter>     
      <link-entity name="arades_aktionscode" from="arades_aktionscodeid" to="arades_aktionscode" alias="a" link-type="inner">
        <attribute name="arades_aktionscodeid"   />
        <attribute name="arades_typ"  />
        <attribute name="arades_treue_monate"   />
        <attribute name="arades_aktionscode_limitiert"  />
        <attribute name="arades_aktionscode_unbegrenzt_gueltig"   />
        <attribute name="arades_name" />  
        <attribute name="createdon"  />
        <attribute name="arades_startdatum"  />	     
        <attribute name="arades_enddatum"   />
        <attribute name="arades_prozent"  />
        <attribute name="arades_maximum_anzahl"  />
        <attribute name="arades_aktuelle_anzahl"  />
        <filter type="and">
          <condition attribute="statuscode" operator="eq" value="1" />
          <condition attribute="statecode" operator="eq" value="0" />
          ${typeFilter}
           
        </filter>
      </link-entity>      
    </entity>
  </fetch>`;
  //  <filter type="or">
  //    <condition attribute="arades_aktionscode_unbegrenzt_gueltig" operator="eq"  value="true" />
  //    <filter type="and">
  //      <condition attribute="arades_enddatum" operator="ge" value="${today}" />
  //      <condition attribute="arades_startdatum" operator="le" value="${today}" />
  //     </filter>;
  //   </filter>

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/arades_aktionscode_nutzers?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Aktionscoden könnten nicht geladen werden"); //("customer Action code not fetched");

  let toShowList = result.data.data.value.sort((a, b) =>
    a?.createdon >= b?.createdon ? -1 : 1
  );

  let list = [];
  toShowList.map((item) => {
    if (
      item["a.arades_aktionscode_unbegrenzt_gueltig"] ||
      (longDateTo10(new Date(item["a.arades_startdatum"])) <=
        longDateTo10(new Date()) &&
        longDateTo10(new Date(item["a.arades_enddatum"])) >=
          longDateTo10(new Date()))
    ) {
      let tmp = {
        actionCodeUserId: item["arades_aktionscode_nutzerid"],
        id: item["a.arades_aktionscodeid"],
        actionCode: item["a.arades_name"],
        arades_prozent: item["a.arades_prozent"] ? item["a.arades_prozent"] : 0,
        discount:
          (item["a.arades_prozent"] ? item["a.arades_prozent"] : 0) + "%",
        allowRegisterCount: item["a.arades_maximum_anzahl"],
        registeredCount: item["a.arades_aktuelle_anzahl"] ?? 0,
        loyalityMonthCount: item["a.arades_treue_monate"],
        usersUnLimited: item["a.arades_aktionscode_limitiert"] == false,
        validForever: item["a.arades_aktionscode_unbegrenzt_gueltig"],
        arades_typ: item["a.arades_typ"],
        startDate: dateString(item["a.arades_startdatum"]),
        endDate: dateString(item["a.arades_enddatum"]),
      };

      list.push(tmp);
    }
  });

  return list;
};

export default getActionCodeCustomerDataFunc;
