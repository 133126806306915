import React, { useState } from "react";
import { FormSteper } from "../../../common";
import BankAccountStep from "../sharedSteps/bankAccount.step";
import StorageFeeStep from "../storageFeeStep.step";
import OtherPersonInfo from "./otherPersonInfo.step";
import Verification from "../sharedSteps/Verification.step";
import Security from "./Security.step";
import SignStorageContract from "../sharedSteps/signStorageContract.step";
import GWG from "../sharedSteps/gwg.step";
import PEP from "../sharedSteps/pep.step";
import StorageUnits from "../sharedSteps/StorageUnits.step";
import StorageInformation from "../sharedSteps/StorageInformation.step";
import StorageCreated from "../sharedSteps/StorageCreated.step";
//import StoragePaymentStep from "../sharedSteps/storagePaymentStep.step";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetAll } from "../../../../redux/slices/storage/storage.slice";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../../core/constants";

function ShareWithPartnerWith2FAStepManager({
  handleCurrentStep: handlerParentStep,
  parent,
}) {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 12;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HandleCurrentStep = (step, parent) => {
    if (parent) {
      handlerParentStep(parent);
    } else {
      if (isNaN(parseInt(step))) {
        if (step === "next" && currentStep < stepsCount) {
          setCurrentStep((prev) => prev + 1);
        } else if (step === "prev" && currentStep > 1) {
          setCurrentStep((prev) => prev - 1);
        }
      } else {
        if (currentStep <= stepsCount && currentStep >= 1) {
          setCurrentStep(step);
        }
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(`/${URL.STORAGE}`);
  };

  return (
    <FormSteper
      parent={parent}
      currentStep={currentStep}
      setCurrentStep={HandleCurrentStep}
      loading={loading}
      noBackButton={[11, 12]}
      hasCancel={[1, 3, 4, 5, 6, 7, 8, 9, 10]}
      onCancel={handleCancel}
      noFooter={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
      headerTitles={[
        "Zweiter Inhaber der Lagerplätze",
        "Bestätigung des Partners",
        "Einrichtungsgebühr",
        "Angaben zur Verfügungsberechtigung",
        <div>
          &nbsp;Bankverbindung <br></br> &nbsp;{" "}
          <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
            für Auszahlung von Erlösen
          </span>
        </div>,
        "Einheiten",
        "Ihr Wunsch zur Einhaltung der Widerrufsfrist",
        <div>
          &nbsp; Angaben gemäß Geldwäschegesetz (GwG)<br></br> &nbsp;
          <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
            Wirtschaftlich Berechtigter
          </span>
        </div>,
        <div>
          &nbsp; Angaben gemäß Geldwäschegesetz (GwG)<br></br> &nbsp;
          <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
            Politisch Exponierte Person
          </span>
        </div>,
        "Ihre Vertragsunterlagen",
        "Zwei-Faktor-Authentifizierung",
        "Ihre Lagerplätze", // change it at bank account component becuase it has custom header
        //"Payment",
      ]}
    >
      {/* Step 1- Other person OtherPersonInfo */}
      <OtherPersonInfo handleCurrentStep={HandleCurrentStep} />

      {/* Step 2- Other person acceptance*/}
      <Verification forPartner={true} handleCurrentStep={HandleCurrentStep} />

      {/* Step 3- PromotionCode */}
      <StorageFeeStep handleCurrentStep={HandleCurrentStep} />

      {/* Step 4- Security*/}
      <Security handleCurrentStep={HandleCurrentStep} />

      {/* Step 5- BankAccountStep */}
      <BankAccountStep handleCurrentStep={HandleCurrentStep} />

      {/* Step  6- Storage Type*/}
      <StorageUnits handleCurrentStep={HandleCurrentStep} />

      {/* Step  7- Storage information*/}
      <StorageInformation handleCurrentStep={HandleCurrentStep} />

      {/* Step  8- GWG */}
      <GWG handleCurrentStep={HandleCurrentStep} />

      {/* Step  9- PEP */}
      <PEP handleCurrentStep={HandleCurrentStep} />

      {/* Step 10- SignStorageContract*/}
      <SignStorageContract handleCurrentStep={HandleCurrentStep} />

      {/* Step 11 step authentication*/}
      <Verification handleCurrentStep={HandleCurrentStep} />

      {/* Step 12- Storage created*/}
      <StorageCreated handleCurrentStep={HandleCurrentStep} />

      {/* Step 13- StoragePaymentStep*/}
      {/* <StoragePaymentStep handleCurrentStep={HandleCurrentStep} /> */}
    </FormSteper>
  );
}

export default ShareWithPartnerWith2FAStepManager;
