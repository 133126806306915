import React, { useState, useEffect } from "react";
import { DataTable, Select, FilterArrow, Input} from "../common";
import { URL, CELL_RENDER_TYPE, ERROR_CONTACT_US } from "../../core/constants";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { useDispatch, useSelector } from "react-redux";
import {
  getVermittlerStorageListAction,
  getVermittlerBuyInvoiceListAction,
} from "../../redux/slices/vermittlerDashboard";
import { useSnackbar } from "notistack";
import { Typography, Box, Grid, Paper } from "@mui/material";
import { Pie, Bar } from "react-chartjs-2";
import { Currency, GermanFloat } from "../../core/utils/convert";
import {
  totalMetalPricesList,
  depositMonthly,
  globalData,
  resetError,
  resetActionStatus,
  customerStorages,
} from "../../redux/slices/vermittlerDashboard/vermittlerDashboard.slice";
import LoadingOverlay from "../common/LoadingOverlay/LoadingOverlay";
import { useTranslation } from "react-i18next";
import { METAL_CHARS } from "../../core/constants";
import { MetalPart } from "../../core/utils/business";

const CustomerStorageList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [filter, setFilter] = useState({
    metalType: "0",
  });

  
  const depositMonthlyFields = useSelector(depositMonthly);
  console.log(depositMonthlyFields);
  const totalMetalPricesListFields = useSelector(totalMetalPricesList);

  const storageList = useSelector(customerStorages);
  const vermittlerListsGlobalData = useSelector(globalData);
  
  const [filteredList, setFilteredList] = useState([]);

  const depositChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      datalabels: {
        color: "#fff",
        formatter: function (value, context) {
          return "";
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              " " + context.dataset.label + ": " + Currency(context.parsed.y)
            );
          },
        },
      },
    },
    // scales: {
    //   xAxes: {
    //     ticks: { mirror: true, fontSize: 18, stepSize: 1, color: "black" },
    //     grid: {
    //       display: false,
    //       drawBorder: false,
    //     },
    //   },
    //   yAxes: {
    //     display: false,
    //     grid: {
    //       display: false,
    //       drawBorder: false,
    //     },
    //   },
    // },
  };
  const depositChartData = {
    labels: depositMonthlyFields.map((item) => item.month),
    datasets: [
      {
        categoryPercentage: 1.0,
        barPercentage: 0.94,
        borderRadius: 3,
        label: t("metals.gold"),
        data: depositMonthlyFields.map((item) => item.priceGold),
        backgroundColor: [
          "rgba(238, 204, 36, 1)",
          "rgba(238, 204, 36, 1)",
          "rgba(238, 204, 36, 1)",
        ],
      },
      {
        categoryPercentage: 1.0,
        barPercentage: 0.94,
        borderRadius: 3,
        label: t("metals.silver"),
        data: depositMonthlyFields.map((item) => item.priceSilver),
        backgroundColor: [
          "rgba(204, 204, 204, 1)",
          "rgba(204, 204, 204, 1)",
          "rgba(204, 204, 204, 1)",
        ],
      },
      {
        categoryPercentage: 1.0,
        barPercentage: 0.94,
        borderRadius: 3,
        label: "Platin",
        data: depositMonthlyFields.map((item) => item.pricePlatin),
        backgroundColor: [
          "rgba(227, 242, 253, 1)",
          "rgba(227, 242, 253, 1)",
          "rgba(227, 242, 253, 1)",
        ],
      },
      {
        categoryPercentage: 1.0,
        barPercentage: 0.94,
        borderRadius: 3,
        label: "Palladium",
        data: depositMonthlyFields.map((item) => item.pricePalladium),
        backgroundColor: [
          "rgba(250, 192, 138, 0.6)",
          "rgba(250, 192, 138, 0.6)",
          "rgba(250, 192, 138, 0.6)",
        ],
      },
    ],
  };

  //parichehr : make pie chart and bar chart a component to be used in both forms
  const totalMetalPricesChartData = {
    labels: [
      ` ${"Gold"} (${totalMetalPricesListFields?.AU?.abbreviation}) `,
      ` ${"Silber"} (${totalMetalPricesListFields?.AG?.abbreviation}) `,
      ` ${"Platin"} (${totalMetalPricesListFields?.PT?.abbreviation}) `,
      ` ${"Palladium"} (${totalMetalPricesListFields?.PL?.abbreviation}) `,
    ],
    datasets: [
      {
        label: "",
        data: [
          totalMetalPricesListFields?.AU?.amount,
          totalMetalPricesListFields?.AG?.amount,
          totalMetalPricesListFields?.PT?.amount,
          totalMetalPricesListFields?.PL?.amount,
        ],
        backgroundColor: [
          "rgba(238, 204, 36, 1)",
          "rgba(204, 204, 204, 1)",
          "rgba(227, 242, 253, 1)",
          "rgba(252, 217, 185, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 255)",
          "rgba(255, 255, 255, 255)",
          "rgba(255, 255, 255, 255)",
          "rgba(255, 255, 255, 255)",
        ],
        borderWidth: 6,
      },
    ],
  };

  const totalMetalPricesChartOptions = {
    responsive: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              " " + context.label + ": " + GermanFloat(context.parsed) + " (g)"
            );
          },
        },
      },
    },
  };

  useEffect(() => {
    dispatch(
      getVermittlerBuyInvoiceListAction(localStorage.getItem(WORKSPACE_ID))
    );

    dispatch(
      getVermittlerStorageListAction({
        vermittlerId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  const onFilterChange = (event) => {
    //setFilter({ ...filter, [event.target.name]: event.target.value });
    let newFilter = { ...filter, [event.target.name]: event.target.value };
    setFilter(newFilter);

    let filteredData = storageList.filter(
      (x) =>
        (newFilter.storageNumber
          ? x.storageNumber
              ?.toLowerCase()
              .indexOf(newFilter.storageNumber.toLowerCase()) >= 0
          : true) &&
          (newFilter.customerName
            ? x.customerName
                ?.toLowerCase()
                .indexOf(newFilter.customerName.toLowerCase()) >= 0
            : true) &&
        (newFilter.metalType != "0"
          ? (newFilter.metalType == "1" &&
              MetalPart(x.storageNumber) == METAL_CHARS.GOLD) ||
            (newFilter.metalType == "2" &&
              MetalPart(x.storageNumber) == METAL_CHARS.SILVER) ||
            (newFilter.metalType == "3" &&
              MetalPart(x.storageNumber) == METAL_CHARS.PLATIN) ||
            (newFilter.metalType == "4" &&
              MetalPart(x.storageNumber) == METAL_CHARS.PALLADIUM)
          : true) 
  
    );

    setFilteredList(filteredData);
    console.log("HI");
    console.log(filteredData);
  };

  useEffect(() => {
    if (
      vermittlerListsGlobalData.actionStatus &&
      vermittlerListsGlobalData.actionType === "getCustomerstorages"
    ) {
      dispatch(resetActionStatus());
    }
  }, [vermittlerListsGlobalData.actionStatus]);

  useEffect(() => {
    if (vermittlerListsGlobalData.error != null) {
      enqueueSnackbar(vermittlerListsGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [vermittlerListsGlobalData.error]);

  useEffect(() => {
    setLoading(vermittlerListsGlobalData.loading);
  }, [vermittlerListsGlobalData.loading]);

  useEffect(() => {
    if (
      vermittlerListsGlobalData.actionStatus &&
      vermittlerListsGlobalData.actionType === "getVermittlerStorageList"
    ) {
      setFilteredList(storageList);
    }
  }, [vermittlerListsGlobalData.actionStatus]);

  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <Grid mt="20px" spacing={8} container>
          <Grid item xs={12} md={6} lg={7}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6">
                  {t("vermittler-dashboard.customer-storages")}
                </Typography>
                <Typography
                  sx={{ color: "#1976D2", fontWaight: 700 }}
                  variant="subtitle1"
                  component="h6"
                >
                  {t("vermittler-dashboard.view-all")}
                </Typography>
              </Box>
              <Box mt={"30px"}>
                <Bar options={depositChartOptions} data={depositChartData} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Typography variant="subtitle1" component="h6">
                {t("vermittler-dashboard.pricious-metals")}
              </Typography>
              <Box mt={"30px"}>
                <Pie
                  data={totalMetalPricesChartData}
                  options={totalMetalPricesChartOptions}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Box
          mb={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
            {t("customer-storage-list.title")}
          </Typography>
          <FilterArrow show={show} setShow={setShow}></FilterArrow>
        </Box>
        {show && (
          <Box>
            <Grid container spacing={8}>
            <Grid xs={12} lg={3} item>
              <Input
                name="customerName"
                label={t("customer-storage-list.customer")}
                value={filter.customerName}
                onChange={onFilterChange}
              />
            </Grid>
            <Grid xs={12} lg={3} item>
              <Input
                name="storageNumber"
                label={t("customer-storage-list.storage")}
                value={filter.storageNumber}
                onChange={onFilterChange}
              />
            </Grid>
              <Grid xs={12} lg={3} item>
                <Select
                  name="metalType"
                  label="Metal Type"
                  value={filter.metalType}
                  onChange={onFilterChange}
                  options={[
                    { key: "0", value: t("customer-storage-list.select") },
                    { key: "1", value: t("metals.gold") },
                    { key: "2", value: t("metals.silver") },
                    { key: "3", value: t("metals.platin") },
                    { key: "4", value: t("metals.palladium") },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <DataTable
          hideTitle={true}
          entityName={"msdyn_warehouse"}
          canAdd={false}
          canDelete={false}
          canEdit={false}
          nofetch={true}
          data={filteredList}
          columns={[
            {
              name: "customerName",
              caption: t("customer-storage-list.customer"),
            },
            {
              name: "storageNumber",
              caption: t("customer-storage-list.storage"),
            },
            {
              name: "createDate_formatted",
              caption: t("customer-storage-list.create-date"),
            },
            {
              name: "metalName",
              caption: t("customer-storage-list.metal-type"),
              cellRenderType: CELL_RENDER_TYPE.METAL_CELL,
            },
            {
              name: "arades_verfuegbare_menge_formatted",
              caption: t("customer-storage-list.weight"),
              alignment: "right",
            },
            {
              name: "totalPrice_formatted",
              caption: t("customer-storage-list.amount"),
              alignment: "right",
            },
            // {
            //   name: "contractSignDate",
            //   caption: t("customer-storage-list.sign-date"),
            // },
            {
              name: "storageStatus",
              caption: t("customer-storage-list.status"),
            },
          ]}
        ></DataTable>
      </div>
    </LoadingOverlay>
  );
};
export default CustomerStorageList;
