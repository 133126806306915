import { createAsyncThunk } from "@reduxjs/toolkit";
import { WORKSPACE_ID, TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { BASE_URL, BASE_URL_UI } from "../../../core/apiconfig/environment";
import { longDateTo10 } from "../../../core/utils/convert";
import axios from "axios";
//import { postCustomerActionCodeFunc } from "./";
import { postContactFunc, getActionCodeDataFunc } from "../../sharedFunctions";

const postCustomerInfoAction = createAsyncThunk(
  "customerList/postCustomerInfo",
  async (contactInfo, { getState }) => {
    // let saveObj = {
    //   ...contactInfo,
    //   ["arades_vermittler@odata.bind"]:
    //     "/accounts(" + localStorage.getItem(WORKSPACE_ID) + ")",
    //   ["arades_titel@odata.bind"]:
    //     "/arades_titels(" + contactInfo.arades_titel + ")",
    // };

    // const actionCode = contactInfo.actionCode;
    // delete saveObj.actionCode;
    // delete saveObj.emailRepeat;
    // delete saveObj.arades_titel;

    // let actionCodeData = actionCode
    //   ? await getActionCodeDataFunc(actionCode, { getState })
    //   : null;
    // if (actionCodeData) {
    //   if (!actionCodeData.isValidDate)
    //     throw Error("Action Code Date has expired");
    //   if (!actionCodeData.isValidUserNum)
    //     throw Error("This action code has reached max user number");
    //   if (!actionCodeData.isActive)
    //     throw Error("This Action code is not Active");
    // }

    // saveObj.birthdate = longDateTo10(new Date(contactInfo.birthdate));

    // let contactData = await postContactFunc(saveObj, { getState });

    // if (actionCodeData) {
    //   let acSaveObj = {
    //     ["arades_kunde_contact@odata.bind"]: `/contacts(${contactData.contactid})`,
    //     ["arades_aktionscode@odata.bind"]: `/arades_aktionscodes(${actionCodeData.id})`,
    //     statecode: 0,
    //   };
    //   await postCustomerActionCodeFunc(acSaveObj, {
    //     getState,
    //   });
    // }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let registerObj = {
      email: contactInfo.emailaddress1,
      birthdate: longDateTo10(contactInfo.birthdate),
      firstname: contactInfo.firstname,
      lastname: contactInfo.lastname,
      title: contactInfo.arades_titel,
      gender: contactInfo.arades_anrede,
      promotionCode: contactInfo.actionCode ? contactInfo.actionCode : null,
      dataPolicy: true,
      vermittlerId: localStorage.getItem(WORKSPACE_ID),
      redirectUrl: BASE_URL_UI + "definepass",
    };

    if (registerObj.title == "") registerObj.title = null;
    let registerResult = await axios.post(
      `${BASE_URL}auth/register`,
      registerObj,
      config
    );
    if (
      registerResult?.data?.status != 201 &&
      registerResult?.data?.status != 200
    ) {
      throw Error(registerResult?.data?.statusText);
    } else return true;
  }
);

export default postCustomerInfoAction;
