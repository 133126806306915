import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import DataGrid, { Column, Paging } from "devextreme-react/data-grid";
import InboxRow from "./inboxRow";
import SentMessageRow from "./sentMessageRow";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import { lists, globalData } from "../../redux/slices/inbox/inbox.slice";
import {
  getInboxListAction,
  getSentListAction,
} from "../../redux/slices/inbox";
import LoadingOverlay from "../common/LoadingOverlay/LoadingOverlay";
import { useTranslation } from "react-i18next";

const InboxList = ({ handleCurrentStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listsData = useSelector(lists);
  const inboxGlobalData = useSelector(globalData);

  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("1");
  const handleRowClick = ({ data }) => {
    handleCurrentStep("next", { ...data });
  };

  useEffect(() => {
    dispatch(getInboxListAction({ Received: true }));
    dispatch(getSentListAction({ Received: false }));
  }, []);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    setLoading(inboxGlobalData.loading);
  }, [inboxGlobalData.loading]);

  return (
    <LoadingOverlay show={loading}>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab}>
            <Tab label={t("inbox.received-list")} value="1" />
            <Tab label={t("inbox.sent-list")} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box mt="20px">
            <DataGrid
              id="gridContainer"
              dataSource={listsData.inbox}
              keyExpr="id"
              columnAutoWidth={true}
              showBorders={true}
              dataRowRender={InboxRow}
              onRowClick={handleRowClick}
            >
              <Column
                width={130}
                caption={t("inbox.from")}
                allowResizing={true}
                allowSorting={false}
              />
              <Column
                caption={t("inbox.subject")}
                allowResizing={true}
                allowSorting={false}
              />
              <Column
                width={280}
                caption={t("inbox.regarded-to")}
                allowResizing={true}
                allowSorting={true}
              />
              <Column
                width={150}
                caption={t("inbox.created-on")}
                allowResizing={true}
                allowSorting={false}
              />
              <Column caption="" allowResizing={true} allowSorting={false} />
              <Paging defaultPageSize={10} />
            </DataGrid>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box mt="20px">
            <DataGrid
              id="gridContainer"
              dataSource={listsData.sent}
              keyExpr="id"
              columnAutoWidth={true}
              showBorders={true}
              dataRowRender={SentMessageRow}
              onRowClick={handleRowClick}
            >
              <Column
                width={130}
                caption={t("inbox.to")}
                allowResizing={true}
                allowSorting={false}
              />
              <Column
                caption={t("inbox.subject")}
                allowResizing={true}
                allowSorting={false}
              />
              <Column
                width={280}
                caption={t("inbox.regarded-to")}
                allowResizing={true}
                allowSorting={true}
              />
              <Column
                width={150}
                caption={t("inbox.created-on")}
                allowResizing={true}
                allowSorting={false}
              />
              <Column caption="" allowResizing={true} allowSorting={false} />
              <Paging defaultPageSize={10} />
            </DataGrid>
          </Box>
        </TabPanel>
      </TabContext>
    </LoadingOverlay>
  );
};

export default InboxList;
