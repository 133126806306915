import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getInvoiceTransferDataFunc = async (params, { getState }) => {
  const { workspaceId } = params;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let fetchQuery = `
  <fetch version="1.0" aggregate="true" mapping="logical" distinct="false">
    <entity name="invoice">
      <attribute name="arades_ueberweisungsbetrag" alias="sum_amount" aggregate="sum"/>
      <filter type="and">        
        <condition attribute="arades_richtung" operator="in">
          <value>100000003</value>
          <value>100000004</value>
        </condition>
        <condition attribute="arades_verkaeufer" operator="eq" value="${workspaceId}"/>
      </filter>
    </entity>
  </fetch>`;

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/invoices/?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.data?.value?.length > 0)
    return result.data.data.value[0].sum_amount;
  else return null;
};

export default getInvoiceTransferDataFunc;
