import React, { useState, useEffect } from "react";
import SubmitModal from "../common/popups/submitModal";
import { LoadingOverlay, AutoComplete } from "../common";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { ACTION_CODE_TYPE, ERROR_CONTACT_US } from "../../core/constants";
import { Grid, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { postCustomerActionCodeAction } from "../../redux/slices/customerList";
import {
  customerActionCodeList,
  globalData,
  resetError,
  resetActionStatus,
} from "../../redux/slices/customerList/customerList.slice";
import CustomerActionCodeList from "./customerActionCodeList";
import { getActionCodeListAction } from "../../redux/slices/vermittlerDashboard";
import { actionCodeList } from "../../redux/slices/vermittlerDashboard/vermittlerDashboard.slice";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AssignActionCodeModal = ({
  open,
  onClose,
  customerName,
  contactId,
  accountId,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const customerGlobalData = useSelector(globalData);
  const sellerActionCodes = useSelector(actionCodeList);

  const customeractionCodes = useSelector(customerActionCodeList);

  const [sellerActionCodeList, setSellerActionCodeList] = useState();
  const [selectedActionCode, setSelectedActionCode] = useState();

  useEffect(() => {
    dispatch(
      getActionCodeListAction({ ownerId: localStorage.getItem(WORKSPACE_ID) })
    );
  }, []);

  const onSubmit = () => {
    dispatch(
      postCustomerActionCodeAction({
        actionCodeId: selectedActionCode.key,
        accountId: accountId,
        contactId: contactId,
      })
    );
  };

  useEffect(() => {
    if (
      customerGlobalData.actionStatus &&
      customerGlobalData.actionType == "postCustomerActionCode"
    ) {
      enqueueSnackbar("Die Änderungen wurden gespeichert", {
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
      reset();
      setSelectedActionCode(null);
      dispatch(resetActionStatus());
    }
  }, [customerGlobalData.actionStatus]);

  useEffect(() => {
    if (
      customerGlobalData.actionStatus &&
      customerGlobalData.actionType == "getCustomerActionCodes"
    ) {
      let selectableActionCodeList = [];

      sellerActionCodes.forEach((item) => {
        if (
          customeractionCodes.filter((x) => x.actionCode == item.actionCode)
            .length == 0
        )
          selectableActionCodeList.push({
            key: item.id,
            value:
              item.actionCode +
              " : (" +
              (item.arades_typ == ACTION_CODE_TYPE.STORAGE_FEE
                ? (item.discount ? item.discount : 0) +
                  " " +
                  t("action-code.fix-fee")
                : item.arades_typ == ACTION_CODE_TYPE.BECOME_SELLER
                ? t("action-code.become-seller")
                : item.arades_typ == ACTION_CODE_TYPE.LOYALTY
                ? (item.loyalityMonthCount ? item.loyalityMonthCount : 0) +
                  " " +
                  t("action-code.loyalty-months")
                : "") +
              (item.description ? " _ " + item.description : "") +
              ")",
          });
      });
      setSellerActionCodeList(selectableActionCodeList);
      dispatch(resetActionStatus());
    }
  }, [customerGlobalData.actionStatus]);

  useEffect(() => {
    if (
      customerGlobalData.error != null &&
      customerGlobalData.actionType == "postCustomerActionCode"
    ) {
      debugger;
      enqueueSnackbar(customerGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [customerGlobalData.error]);

  useEffect(() => {
    setLoading(customerGlobalData.loading);
  }, [customerGlobalData.loading]);

  const schema = yup.object().shape({
    //parichehr : must add control to autocomplete
    //selectedActionCode: yup.string().required(requiredMsg),
  });
  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      firstname: "",
      lastname: "",
      arades_titel: null,
      birthdate: null,
      actionCode: "",
      emailaddress1: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <SubmitModal
      open={open}
      title={t("action-code.assign")}
      acceptTitle={t("buttons.add")}
      rejectTitle={t("buttons.cancel")}
      size="lg"
      formIsValid={selectedActionCode}
      onAccept={handleSubmit(onSubmit)}
      onReject={() => {
        reset();
        setSelectedActionCode(null);
        onClose();
      }}
      content={
        <LoadingOverlay show={loading}>
          <Box sx={{ minWidth: "650px" }}>
            <Grid container spacing={6} mt={"10px"} sx={{ width: "100%" }}>
              <Grid sm={12} item sx={{ width: "100%" }}>
                <Typography
                  as="div"
                  sm={12}
                  sx={{
                    whiteSpace: "pre",
                    display: "flex",
                    width: "100%",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {customerName}
                </Typography>
              </Grid>
              <Grid sm={12} item sx={{ width: "100%" }}>
                <CustomerActionCodeList
                  customerId={contactId ? contactId : accountId}
                  customerName={customerName}
                ></CustomerActionCodeList>
              </Grid>
              <Grid sm={12} item sx={{ width: "100%" }} mt={10}>
                <AutoComplete
                  options={sellerActionCodeList}
                  name={"selectedActionCode"}
                  value={selectedActionCode}
                  onChange={(e) => setSelectedActionCode(e.target.value)}
                  label={t("action-code.select") + "*"}
                ></AutoComplete>
              </Grid>
            </Grid>
          </Box>
        </LoadingOverlay>
      }
    ></SubmitModal>
  );
};

export default AssignActionCodeModal;
