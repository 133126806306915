import React, { useState, useEffect } from "react";

import { Box, Button, Typography } from "@mui/material";
import { FormSteperStructure, Select } from "../../../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  StorageSecurityStep,
  setStorageSecurityStep,
} from "../../../../../redux/slices/storage/storage.slice";
import { useTranslation } from "react-i18next";

function Security({ handleCurrentStep, currentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const securityInfo = useSelector(StorageSecurityStep);

  const [selectedSecurity, setSelectedSecurity] = useState("100000004");

  useEffect(() => {
    setSelectedSecurity(
      securityInfo.selectedSecurityOption
        ? securityInfo.selectedSecurityOption
        : "100000004"
    );
  }, []);

  const onSubmit = () => {
    let securityObj = {
      ...securityInfo,
      selectedSecurityOption: selectedSecurity,
    };
    dispatch(setStorageSecurityStep(securityObj));
    handleCurrentStep("next");
  };
  return (
    <Box
      sx={{
        maxWidth: "816px",
        margin: "0 auto",
        marginTop: "32px",
      }}
    >
      <Typography
        variant="subtitle2"
        mt="30px"
        mb="13px"
        sx={{
          fontSize: "16px",
          fontWeight: "400",
        }}
      >
        Bitte geben Sie an, wer von Ihnen beiden in welcher Form über die
        Lagerplätze, Edelmetallkäufe und -verkäufe, sowie für alle übrigen
        Vertragsbelange des Minderjährigen verfügen darf:
      </Typography>

      <Box mt="120px" mb="120px">
        <Select
          name="selectedSecurity"
          value={selectedSecurity}
          onChange={(e) => setSelectedSecurity(e.target.value)}
          label="Security"
          options={securityInfo.securityOptions}
        />
      </Box>

      <FormSteperStructure.Footer>
        <Button
          onClick={onSubmit}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          Weiter
          {/* Next */}
        </Button>
      </FormSteperStructure.Footer>
    </Box>
  );
}

export default Security;
