import React from "react";
import PhoneInp from "react-phone-input-2";
import { Controller } from "react-hook-form";
import { FormHelperText } from "@mui/material";

import "./input.scss";
import "./phoneinput.scss";

const PhoneInput = ({
  label,
  width,
  height,
  value,
  onChange,
  type,
  placeholder,
  country,
  id,
  name,
  disabled,
  style,
  multiline,
  className,
  error,
  control = false,
  helperText,
  ...rest
}) => {
  const handleChange = (value, country) => {
    let event = {
      target: {
        name: name,
        value:
          value.length > 0 && value[0] == "+"
            ? value
            : value.length > 0
            ? "+" + value
            : value,
        country: country,
      },
    };
    onChange(event);
  };
  return (
    <>
      <div className="d-flex ml-4 mr-4 mt-2 flex-column ">
        <div className="row label mb-2 pl-2">
          <div className={`${className} ${disabled ? "disabled" : ""}`}>
            {label}
          </div>
        </div>
        {control ? (
          <Controller
            control={control}
            name={name}
            render={({
              field: {
                onChange: formOnChange,
                onBlur: formOnBlur,
                value: formValue,
                name: formName,
                ref: fromRef,
              },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <>
                <PhoneInp
                  id={id}
                  name={name}
                  value={value}
                  country={country}
                  onChange={(value, country) => {
                    let event = {
                      target: { name: name, value: value, country: country },
                    };
                    formOnChange(event);
                    if (onChange) onChange(event);
                  }}
                  onBlur={formOnBlur}
                  inputRef={fromRef}
                  placeholder={placeholder}
                  disabled={disabled}
                  {...rest}
                  containerClass=" phone"
                  inputClass={`phone-input ${className} ${
                    disabled ? "disabled" : ""
                  }`}
                />
                {helperText && (
                  <FormHelperText
                    sx={{
                      mx: 0,
                      color: error ? "red" : "initial",
                    }}
                  >
                    {helperText}
                  </FormHelperText>
                )}
              </>
            )}
          />
        ) : (
          <>
            <PhoneInp
              id={id}
              name={name}
              value={value}
              country={country}
              onChange={handleChange}
              placeholder={placeholder}
              disabled={disabled}
              {...rest}
              containerClass=" phone"
              inputClass={`phone-input ${className}  ${
                disabled ? "disabled" : ""
              }`}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PhoneInput;
