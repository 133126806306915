import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import {
  Input,
  FormSteperStructure,
  Datepicker,
  MUICheckBox,
  MUIRadioGroup,
  LoadingOverlay,
} from "../../common";
import moment from "moment";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  transferSelectRecipientFields,
  setSelectRecipient,
  transferGlobalState,
  transferFromStorage,
  resetError,
  resetActionStatus,
} from "../../../redux/slices/transfer/transfer.slice";
import {
  getTransferedStorageListAction,
  getStorageByNumberBirthAction,
} from "../../../redux/slices/transfer";
import { useDispatch, useSelector } from "react-redux";
import { WORKSPACE_ID } from "../../../core/apiconfig/constantApi";
import { ERROR_CONTACT_US } from "../../../core/constants";

function SelectRecipient({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const selectRecipientFields = useSelector(transferSelectRecipientFields);
  const transferData = useSelector(transferGlobalState);
  const fromStorage = useSelector(transferFromStorage);
  const [loading, setLoading] = useState(false);

  const requiredMsg = t("validation.required");

  useEffect(() => {
    if (!selectRecipientFields.recipientList) {
      dispatch(
        getTransferedStorageListAction({
          ownerId: localStorage.getItem(WORKSPACE_ID),
          metal: fromStorage._arades_edelmetall_typ_value,
        })
      );
    }
  }, []);

  const schema = yup.object().shape({
    recipient: yup.string().required(requiredMsg),
    storage_number: yup.string().when("recipient", {
      is: "new",
      then: yup.string().required(requiredMsg).nullable(),
      otherwise: yup.string(),
    }),
    birthdate_owner: yup.string().when("recipient", {
      is: "new",
      then: yup.string().required(requiredMsg).nullable(),
      otherwise: yup.string(),
    }),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...selectRecipientFields,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(setSelectRecipient(data));
    if (data.recipient == "new") {
      dispatch(
        getStorageByNumberBirthAction({
          storageNumber: data.storage_number,
          birthdate: data.birthdate_owner,
        })
      );
    } else {
      dispatch(
        setSelectRecipient({
          storage: selectRecipientFields.selectedRecipient.storage,
          contact: selectRecipientFields.selectedRecipient.owner,
          recipient: selectRecipientFields.selectedRecipient.storage.msdyn_name,
          selectedRecipient: selectRecipientFields.selectedRecipient,
          birthdate_owner: "",
        })
      );
      handleCurrentStep("next");
    }
  };

  useEffect(() => {
    if (
      transferData.actionStatus &&
      transferData.actionType == "getTransferedStorageList"
    ) {
      dispatch(resetActionStatus());
    }
    if (
      transferData.actionStatus &&
      transferData.actionType == "getStorageByNumberBirth"
    ) {
      dispatch(resetActionStatus());
      handleCurrentStep("next");
    }
  }, [transferData.actionStatus]);

  useEffect(() => {
    if (transferData.error != null) {
      enqueueSnackbar(transferData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [transferData.error]);

  useEffect(() => {
    setLoading(transferData.loading);
  }, [transferData.loading]);

  const renderRadioLabel = (item) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {item.storageNumber}
        <Typography variant="caption" sx={{ fontSize: 14 }}>
          &nbsp; ({item.ownerName})
        </Typography>
      </Box>
    );
  };
  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          maxWidth: "816px",
          margin: "0 auto",
          marginTop: "32px",
        }}
      >
        <Box mt="120px" mb="120px">
          <MUIRadioGroup control={control} name="recipient">
            {selectRecipientFields.recipientList &&
              selectRecipientFields.recipientList.map((item) => (
                <FormControlLabel
                  key={item.storageNumber}
                  value={item.storageNumber}
                  control={<Radio />}
                  label={renderRadioLabel(item)}
                  onClick={() => {
                    dispatch(
                      setSelectRecipient({
                        ...selectRecipientFields,
                        selectedRecipient: item,
                      })
                    );
                  }}
                />
              ))}
            <FormControlLabel
              value="new"
              control={<Radio />}
              label={t("transfer.new-recipient")}
            />
          </MUIRadioGroup>
          {getValues("recipient") === "new" && (
            <Grid container spacing={8}>
              <Grid xs={12} lg={6} item>
                <Input
                  control={control}
                  error={Boolean(errors.storage_number)}
                  helperText={t(errors.storage_number?.message)}
                  name="storage_number"
                  label={t("transfer.storage-number")}
                />
              </Grid>
              <Grid xs={12} lg={6} item>
                <Datepicker
                  label={t("transfer.birthday") + "*"}
                  control={control}
                  name="birthdate_owner"
                  maxDate={Date.now()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.birthdate_owner)}
                      helperText={t(errors.birthdate_owner?.message)}
                      onBlur={() => {
                        if (getValues("birthdate_owner") === "") {
                          setError("birthdate_owner", {
                            type: "manual",
                            message: requiredMsg,
                          });
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid xs={12} lg={6} item>
                <MUICheckBox
                  control={control}
                  error={Boolean(errors.agreed)}
                  helperText={t(errors.agreed?.message)}
                  name="save_recipient"
                  label={<span>Save this recipient for later</span>}
                />
              </Grid> */}
            </Grid>
          )}
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(onSubmit)}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectRecipient;
