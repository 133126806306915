import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Button, Typography } from "@mui/material";
import { FormSteperStructure, Select } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  StorageUnitsStep,
  setStorageUnitsStep,
} from "../../../../redux/slices/storage/storage.slice";
import {
  globalsWeightUnits,
  globalsCurrencies,
} from "../../../../redux/slices/globals/globals.slice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function StorageUnits({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currencyList = useSelector(globalsCurrencies);
  const weightUnitList = useSelector(globalsWeightUnits);
  const fields = useSelector(StorageUnitsStep);

  const requiredMsg = "validation.required";

  useEffect(() => {
    if (fields.currencyUnit || fields.weightUnit) return;

    let filteredCurrency = currencyList.filter(
      (x) => x?.value?.toLowerCase()?.indexOf("euro") >= 0
    );
    let filteredWeightUnit = weightUnitList.filter((x) =>
      x?.value?.toLowerCase()?.startsWith("gram")
    );
    let defaultCurrency =
      filteredCurrency.length > 0 ? filteredCurrency[0].key : "";
    let defaultWeightUnit =
      filteredWeightUnit.length > 0 ? filteredWeightUnit[0].key : "";

    dispatch(
      setStorageUnitsStep({
        currencyUnit: defaultCurrency,
        weightUnit: defaultWeightUnit,
      })
    );

    setValue("currencyUnit", defaultCurrency);
    setValue("weightUnit", defaultWeightUnit);
  }, []);

  const onSubmit = (data) => {
    dispatch(setStorageUnitsStep(data));
    handleCurrentStep("next");
  };

  const onError = (error) => {
    //error
  };

  const schema = yup.object().shape({
    currencyUnit: yup.string().required(requiredMsg),
    weightUnit: yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
    },
    resolver: yupResolver(schema),
  });

  return (
    <Box
      sx={{
        maxWidth: "816px",
        margin: "0 auto",
        marginTop: "32px",
      }}
    >
      <Box mt="80px" mb="60px">
        <Grid spacing={8} direction="column" container>
          <Grid item>
            <Typography
              variant="subtitle2"
              mb="10px"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {
                "Bitte wählen Sie, in welchen Währungs- und Gewichtseinheiten Sie Ihre"
              }
              <br></br>
              {" Edelmetallbestände angezeigt bekommen möchten:"}
            </Typography>
          </Grid>
          <Grid item>
            <Select
              error={Boolean(errors.currencyUnit)}
              helperText={errors.currencyUnit?.message}
              control={control}
              name="currencyUnit"
              label="Währung"
              options={currencyList}
            />
          </Grid>

          <Grid item>
            <Select
              error={Boolean(errors.weightUnit)}
              helperText={errors.weightUnit?.message}
              control={control}
              name="weightUnit"
              label="Gewichtseinheit"
              options={weightUnitList}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                width: "100%",
              }}
            >
              <center>
                {"Bitte wählen Sie die Währung sorgfältig!"}
                <br></br>
                {"Eine nachträgliche Änderung ist nicht mehr möglich."}
              </center>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <FormSteperStructure.Footer>
        <Button
          onClick={handleSubmit(onSubmit, onError)}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          Weiter
        </Button>
      </FormSteperStructure.Footer>
    </Box>
  );
}

export default StorageUnits;
