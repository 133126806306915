import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { dateString } from "../../../core/utils/convert";
//import { zeroPad } from "../../../core/utils/commonfuncs";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";

const getCustomerActionCodeListAction = createAsyncThunk(
  "clientDashboard/getCustomerActionCodeList",
  async (params, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let result = await axios.get(
      `${BASE_URL}data/arades_aktionscode_nutzers?$select=statecode&$filter=_arades_kunde_value eq ${params.ownerId}&$expand=arades_aktionscode($select=arades_aktionscodeid,arades_typ,arades_anlas,arades_treue_monate,arades_aktionscode_limitiert,arades_aktionscode_unbegrenzt_gueltig,arades_name,createdon,arades_startdatum,arades_enddatum,arades_prozent,arades_maximum_anzahl,arades_aktuelle_anzahl,statecode)`,
      config
    );

    if (result?.data?.status != 200)
      throw Error("Aktionscode könnte nicht geladen werden"); //("Action Codes not fetched");

    let toShowList = result.data.data.value.sort((a, b) =>
      a?.createdon >= b?.createdon ? -1 : 1
    );

    let list = [];
    for (let i = 0; i < toShowList?.length; i++) {
      let itemUser = toShowList[i];
      let item = itemUser?.arades_aktionscode;
      let tmp = {
        id: item?.arades_aktionscodeid,
        actionCode: item?.arades_name,
        discount: (item?.arades_prozent ? item?.arades_prozent : 0) + "%",
        allowRegisterCount: item?.arades_maximum_anzahl,
        registeredCount: item?.arades_aktuelle_anzahl,
        loyalityMonthCount: item?.arades_treue_monate,
        usersUnLimited: item?.arades_aktionscode_limitiert == false,
        validForever: item?.arades_aktionscode_unbegrenzt_gueltig,
        arades_typ: item?.arades_typ,
        startDate: dateString(item?.arades_startdatum),
        endDate: dateString(item?.arades_enddatum),
        description: item?.arades_anlas,
        active: itemUser?.statecode == 0 && item?.statecode == 0,
      };
      list.push(tmp);
    }

    return list;
  }
);

export default getCustomerActionCodeListAction;
