import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME, WORKSPACE_ID } from "../../../core/apiconfig/constantApi";

const postConnectionFunc = async (connectionInfo, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let value = {};

  value["record1id_contact@odata.bind"] =
    "/contacts(" + connectionInfo.record1id + ")";

  value["record2id_contact@odata.bind"] =
    "/contacts(" + connectionInfo.record2id + ")";

  let result = await axios.post(`${BASE_URL}data/connections/`, value, config);

  if (result?.data?.status != 201)
    throw Error("Partner könnte nicht gespeichert werden"); //("Connection not saved");
  return result.data.data;
};

export default postConnectionFunc;
