import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";
import {
  Datepicker,
  Input,
  Select,
  FilterArrow,
  LoadingOverlay,
} from "../common";
import { METAL_CHARS, ERROR_CONTACT_US } from "../../core/constants";
import { MetalPart } from "../../core/utils/business";
import { dateString, longDateTo10 } from "../../core/utils/convert";
import { Box, Typography, Grid } from "@mui/material";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { useDispatch, useSelector } from "react-redux";
import { resetAll } from "../../redux/slices/storage/storage.slice";
import { getCustomerDepositListAction } from "../../redux/slices/vermittler";
import { useSnackbar } from "notistack";
import {
  customerDeposits,
  globalData,
  resetError,
  resetActionStatus,
} from "../../redux/slices/vermittler/vermittlerLists.slice";
import { useTranslation } from "react-i18next";
import { Currency } from "../../core/utils/convert";

const CustomerDepositList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [sumValues, setSumValues] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const [filter, setFilter] = useState({
    storageNumber: "",
    metalType: "0",
    fromDate: null,
    toDate: null,
  });

  const depositList = useSelector(customerDeposits);
  const vermittlerListsGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getCustomerDepositListAction({
        vermittlerId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  const onFilterChange = (event) => {
    let newFilter = { ...filter, [event.target.name]: event.target.value };
    setFilter(newFilter);

    let filteredData = depositList.filter(
      (x) =>
        (newFilter.storageNumber
          ? x.storageNumber
              ?.toLowerCase()
              .indexOf(newFilter.storageNumber.toLowerCase()) >= 0
          : true) &&
        (newFilter.metalType != "0"
          ? (newFilter.metalType == "1" &&
              MetalPart(x.storageNumber) == METAL_CHARS.GOLD) ||
            (newFilter.metalType == "2" &&
              MetalPart(x.storageNumber) == METAL_CHARS.SILVER) ||
            (newFilter.metalType == "3" &&
              MetalPart(x.storageNumber) == METAL_CHARS.PLATIN) ||
            (newFilter.metalType == "4" &&
              MetalPart(x.storageNumber) == METAL_CHARS.PALLADIUM)
          : true) &&
        (newFilter.fromDate
          ? longDateTo10(new Date(x.createdon)) >=
            longDateTo10(new Date(newFilter.fromDate))
          : true) &&
        (newFilter.toDate
          ? longDateTo10(new Date(x.createdon)) <=
            longDateTo10(new Date(newFilter.toDate))
          : true)
    );

    setFilteredList(filteredData);
    calcSum(filteredData);
  };

  const calcSum = (data) => {
    let sum = 0;
    data.map((item) => {
      sum += item.arades_betrag;
    });

    setSumValues(Currency(sum));
  };

  useEffect(() => {
    if (
      vermittlerListsGlobalData.actionStatus &&
      vermittlerListsGlobalData.actionType === "getCustomerDeposits"
    ) {
      dispatch(resetActionStatus());
      setFilteredList(depositList);
      calcSum(depositList);
    }
  }, [vermittlerListsGlobalData.actionStatus]);

  useEffect(() => {
    if (vermittlerListsGlobalData.error != null) {
      enqueueSnackbar(vermittlerListsGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [vermittlerListsGlobalData.error]);

  useEffect(() => {
    setLoading(vermittlerListsGlobalData.loading);
  }, [vermittlerListsGlobalData.loading]);

  return (
    <div className="container-card">
      <Box
        sx={{
          display: "flex",
          height: "64px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
          {t("customer-deposit-list.title")}
        </Typography>
        <FilterArrow show={show} setShow={setShow}></FilterArrow>
      </Box>
      {show && (
        <Box>
          <Grid container spacing={8}>
            <Grid xs={12} lg={3} item>
              <Input
                name="storageNumber"
                label={t("customer-deposit-list.storage")}
                value={filter.storageNumber}
                onChange={onFilterChange}
              />
            </Grid>
            <Grid xs={12} lg={3} item>
              <Select
                name="metalType"
                label={t("customer-deposit-list.metal-type")}
                value={filter.metalType}
                onChange={onFilterChange}
                options={[
                  { key: "0", value: t("customer-deposit-list.select") },
                  { key: "1", value: t("metals.gold") },
                  { key: "2", value: t("metals.silver") },
                  { key: "3", value: t("metals.platin") },
                  { key: "4", value: t("metals.palladium") },
                ]}
              />
            </Grid>
            <Grid xs={12} lg={3} item>
              <Datepicker
                name="fromDate"
                value={filter.fromDate}
                label={t("customer-deposit-list.from")}
                onChange={onFilterChange}
              />
            </Grid>
            <Grid xs={12} lg={3} item>
              <Datepicker
                name="toDate"
                value={filter.toDate}
                label={t("customer-deposit-list.to")}
                onChange={onFilterChange}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <LoadingOverlay show={loading}>
        <Box>
          <Grid container spacing={8}>
            <Grid xs={12} lg={12} item>
              <Typography
                sx={{
                  fontWeight: 500,
                  marginRight: "20px",
                  display: "flex",
                  justifyContent: "end",
                }}
                variant="h6"
                component="h6"
              >
                {t("customer-deposit-list.total")} :{" " + sumValues}
              </Typography>
            </Grid>
          </Grid>
          <DataTable
            hideTitle={true}
            entityName={"msdyn_warehouse"}
            canAdd={false}
            canDelete={false}
            canEdit={false}
            nofetch={true}
            showPageSizeSelector={true}
            data={filteredList}
            columns={[
              {
                name: "customerName",
                caption: t("customer-deposit-list.customer-name"),
              },
              // { name: "arades_richtung_title", caption: "Direction" },
              {
                name: "storageNumber",
                caption: t("customer-deposit-list.storage"),
              },
              {
                name: "arades_ueberweisungsdatum",
                caption: t("customer-deposit-list.buy-date"),
              },
              {
                name: "unit_price_formatted",
                caption: t("customer-deposit-list.unit-price"),
              },
              {
                name: "arades_betrag_formatted",
                caption: t("customer-deposit-list.amount"),
                alignment: "right",
              },
              // {
              //   name: "createdon_formatted",
              //   caption: t("customer-deposit-list.created-on"),
              // },
              //{ name: "subStorageCount", caption: "Einnehfungsgebuhr?" }, // must complete it
            ]}
          ></DataTable>
        </Box>
      </LoadingOverlay>
    </div>
  );
};
export default CustomerDepositList;
