import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  HideAreaHandler,
  ConfirmModal,
  Steper,
  LoadingOverlay,
} from "../common";
import MdEmail from "@mui/icons-material/Email";
import MdLocalPhone from "@mui/icons-material/LocalPhone";
import MdFace from "@mui/icons-material/Face";
import MdModeEdit from "@mui/icons-material/ModeEdit";
import MdModeTag from "@mui/icons-material/LocalOffer";
import { Search } from "../common/inputs";
import { useNavigate } from "react-router-dom";
import { URL, ERROR_CONTACT_US, WORKSPACETYPE } from "../../core/constants";
import AddCustomerModal from "./addCustomerModal";
import AssignActionCodeModal from "./assignActionCodeModal";
import { useTranslation } from "react-i18next";
import { getCustomerDetailedListAction } from "../../redux/slices/customerList";
import {
  customerDetailedList,
  globalData,
  resetError,
  resetActionStatus,
  resetAll,
} from "../../redux/slices/customerList/customerList.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  CONTACT_ID,
  FULLNAME,
  WORKSPACE_ID,
  WORKSPACE_NAME,
  WORKSPACE_TYPE,
  TMP_CONTACT_ID,
  TMP_FULLNAME,
  TMP_WORKSPACE_ID,
  TMP_WORKSPACE_NAME,
  TMP_WORKSPACE_TYPE,
} from "../../core/apiconfig/constantApi";
import { useSnackbar } from "notistack";

function CustomerList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const customerDetailedListData = useSelector(customerDetailedList);
  const customerListGlobalData = useSelector(globalData);

  const [showData, setShowData] = useState(false);
  const [listData, setListData] = useState();
  const [tableData, setTableData] = useState();
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState();

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getCustomerDetailedListAction({
        vermittlerId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  useEffect(() => {
    if (
      customerListGlobalData.actionStatus &&
      customerListGlobalData.actionType === "getDetailedList"
    ) {
      setListData(customerDetailedListData);
      setTableData(customerDetailedListData);
      dispatch(resetActionStatus());
    }
  }, [customerListGlobalData.actionType]);

  useEffect(() => {
    setLoading(customerListGlobalData.loading);
  }, [customerListGlobalData.loading]);

  useEffect(() => {
    if (
      customerListGlobalData.error != null &&
      customerListGlobalData.actionType === "getDetailedList"
    ) {
      debugger;
      enqueueSnackbar(customerListGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [customerListGlobalData.error]);

  const handleSearch = (term) => {
    if (term) {
      setTableData(
        listData?.filter((item) => {
          return item.name.toLowerCase().includes(term.toLowerCase());
        })
      );
    } else {
      setTableData(listData);
    }
  };

  const switchToContact = (row) => {
    localStorage.setItem(TMP_CONTACT_ID, localStorage.getItem(CONTACT_ID));
    localStorage.setItem(TMP_WORKSPACE_ID, localStorage.getItem(WORKSPACE_ID));
    localStorage.setItem(
      TMP_WORKSPACE_TYPE,
      localStorage.getItem(WORKSPACE_TYPE)
    );
    localStorage.setItem(
      TMP_WORKSPACE_NAME,
      localStorage.getItem(WORKSPACE_NAME)
    );
    localStorage.setItem(TMP_FULLNAME, localStorage.getItem(FULLNAME));

    localStorage.setItem(CONTACT_ID, row.id);
    localStorage.setItem(WORKSPACE_ID, row.id);
    localStorage.setItem(WORKSPACE_TYPE, WORKSPACETYPE.CONTACT);
    localStorage.setItem(WORKSPACE_NAME, row.name);
    localStorage.setItem(FULLNAME, row.name);

    navigate(URL.DASHBOARD);
    window.location.reload();
  };
  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <ConfirmModal
          open={successModalOpen}
          title={t("customer-list.link-sent")}
          showCloseButton={false}
          message={<div>{t("customer-list.email-sent")}</div>}
          okTitle={"Ok"}
          onOk={() => {
            setSuccessModalOpen(false);
          }}
        ></ConfirmModal>
        <AddCustomerModal
          open={customerModalOpen}
          onSuccess={() => setSuccessModalOpen(true)}
          onClose={() => {
            setCustomerModalOpen(false);
            dispatch(
              getCustomerDetailedListAction({
                vermittlerId: localStorage.getItem(WORKSPACE_ID),
              })
            );
          }}
        ></AddCustomerModal>
        <AssignActionCodeModal
          open={assignModalOpen}
          contactId={selectedContact?.id}
          customerName={selectedContact?.name}
          onClose={() => {
            setAssignModalOpen(false);
          }}
        ></AssignActionCodeModal>
        <Box
          mb={10}
          sx={{
            display: "flex",
            height: "64px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
            {t("customer-list.title")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              height: "64px",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Search
              onSearch={handleSearch}
              placeholder={t("customer-list.search-title")}
            />
            <Button
              sx={{ fontWeight: 400, bgcolor: "info.main", marginLeft: 10 }}
              variant="contained"
              fullWidth
              onClick={() => setCustomerModalOpen(true)}
            >
              {t("customer-list.add")}
            </Button>
          </Box>
        </Box>
        <HideAreaHandler
          showArea={showData}
          handleShowArea={() => setShowData(true)}
          message={t("customer-list.not-shown")}
          btnText={t("customer-list.not-shown-btn")}
        >
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table sx={{ minWidth: 930 }} aria-label="simple table">
              <TableHead
                stickyHeader
                sx={{ backgroundColor: "rgba(33, 33, 33, 0.08)" }}
              >
                <TableRow>
                  <TableCell>{t("personal-info.name")}</TableCell>
                  <TableCell>{t("personal-info.ratior-key")}</TableCell>
                  <TableCell>{t("customer-list.status")}</TableCell>
                  {/* <TableCell>Lagerplatz</TableCell> */}
                  <TableCell>{t("customer-list.create-date")}</TableCell>
                  <TableCell>{t("customer-list.verification")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row, index) => (
                  <TableRow
                    key={"r" + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography> {row.name}</Typography>
                        {(row.type == "contact" && row.verifiedStatus == "3" )&& (
                          <Button
                            aria-label="login"
                            component="span"
                            onClick={() => switchToContact(row)}
                          >
                            {"Als Kunde einloggen"}
                          </Button>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{row.ratiorKey}</TableCell>
                    <TableCell>
                      <span
                        style={{
                          color:
                            row.status.toLowerCase() === "Invited".toLowerCase()
                              ? "rgba(0, 0, 0, 0.38)"
                              : "rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        {row.status}
                      </span>
                    </TableCell>
                    <TableCell>{row.createDate_formatted} </TableCell>
                    <TableCell>
                      <Steper
                        width="94px"
                        height="26px"
                        currentStep={row.verifiedStatus}
                        data={[
                          { id: "email", icon: <MdEmail />, color: "" },
                          { id: "mobile", icon: <MdLocalPhone />, color: "" },
                          { id: "nect", icon: <MdFace />, color: "" },
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Tag"
                        component="span"
                        onClick={() => {
                          setSelectedContact(row);
                          setAssignModalOpen(true);
                        }}
                      >
                        <MdModeTag />
                      </IconButton>
                      <IconButton
                        aria-label="Edit"
                        component="span"
                        onClick={() =>
                          navigate("/" + URL.CUSTOMERPROFILEBASE + "/" + row.id)
                        }
                      >
                        <MdModeEdit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </HideAreaHandler>
      </div>
    </LoadingOverlay>
  );
}

export default CustomerList;
