import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getDocumentsAction = createAsyncThunk(
  "storage/getDocuments",
  async (folderName, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let fetchQuery = `${BASE_URL}download/static/Freigegebene Dokumente/Vertragsunterlagen/${folderName}/`;
    const result = await axios.get(fetchQuery, config);
    if (!result?.data?.data) throw Error("Kein Dokument wurde gefunden"); //("No File Found");

    return result.data.data;
  }
);

export default getDocumentsAction;
