import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  CONTACT_ID,
  WORKSPACE_ID,
  TOKEN_NAME,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../../core/constants";

const updateCompanyInfoFunc = async (bankAccountId, { getState }) => {
  const taxDetailData = getState().becomeSeller.taxDetails;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let value = {};
  value.arades_steuernummer = taxDetailData.arades_steuernummer;
  value.arades_finanzamt_behoerde = taxDetailData.arades_finanzamt_behoerde;
  value.arades_umsatzsteuerid = taxDetailData.arades_umsatzsteuerid;
  value.arades_registernummer = taxDetailData.arades_registernummer;
  value.arades_amtsgericht = taxDetailData.arades_amtsgericht;
  value.arades_firmentyp = "100000001";
  value.arades_vermittlerstatus = "100000001";

  value["arades_konto@odata.bind"] = "/arades_kontos(" + bankAccountId + ")";

  let result = await axios.patch(
    `${BASE_URL}data/accounts(${localStorage.getItem(WORKSPACE_ID)})/`,
    value,
    config
  );

  if (!result?.data?.status == 200 || !result?.data?.data)
    throw Error("Firmendatenssatz könnte nicht aktualisiert werden"); //("company not updated");

  localStorage.setItem(WORKSPACE_TYPE, WORKSPACETYPE.SELLER_ACCOUNT_UNVERIFIED);
  return result.data.data;
};
export default updateCompanyInfoFunc;
