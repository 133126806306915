import { createSlice } from "@reduxjs/toolkit";
import {
  postUnderAgeInfoAction,
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
  getPersonalDataAction,
  getUnderAgeDocumentListAction,
} from ".";

const initialState = {
  loading: false,
  error: null,
  actionStatus: false,
  globalData: {
    countries: [],
    genders: [],
    geschlechtList: [],
    documents: [],
  },
  selectWorkspaceTypeStep: {
    workSpaceType: "company",
  },
  fullname: "",
  childDataStep: {
    contactid: null,
    address1_city: "",
    address1_postalcode: "",
    address1_line1: "",
    arades_anrede: "",
    arades_land: "",
    birthdate: "",
    emailaddress1: "",
    emailRepeat: "",
    firstname: "",
    lastname: "",
    arades_geschlecht: "",
    address1_line2: "",
    mobilephone: "",
    telephone1: "",
  },
  legalGuardianStatusStep: {
    legalStatus: "only",
    partnerKey: "",
    partnerBirthDate: "",
    partnerPhoneNumber: "",
  },
  verificationStep: {
    verification: false,
  },
  documentsStep: {
    uploadedDouments: {},
  },
};

export const underageSlice = createSlice({
  name: "underage",
  initialState,
  reducers: {
    setWorkspaceType: (state, action) => {
      state.selectWorkspaceTypeStep.workSpaceType = action.payload;
    },
    setChildId: (state, action) => {
      state.childDataStep.contactid = action.payload;
    },
    setFullname: (state, action) => {
      state.fullname = action.payload;
    },
    setChildData: (state, action) => {
      const {
        address1_city,
        address1_postalcode,
        address1_line1,
        arades_anrede,
        arades_land,
        birthdate,
        emailaddress1,
        emailRepeat,
        firstname,
        lastname,
        arades_geschlecht,
        address1_line2,
        mobilephone,
        telephone1,
      } = action.payload;

      state.childDataStep.firstname = firstname;
      state.childDataStep.lastname = lastname;
      state.childDataStep.arades_anrede = arades_anrede;
      state.childDataStep.birthdate = String(birthdate);
      state.childDataStep.emailaddress1 = emailaddress1;
      state.childDataStep.emailRepeat = emailRepeat;
      state.childDataStep.arades_land = arades_land;
      state.childDataStep.address1_city = address1_city;
      state.childDataStep.address1_line1 = address1_line1;
      state.childDataStep.address1_postalcode = address1_postalcode;
      state.childDataStep.arades_geschlecht = arades_geschlecht;
      state.childDataStep.address1_line2 = address1_line2;
      state.childDataStep.mobilephone = mobilephone;
      state.childDataStep.telephone1 = telephone1;
    },
    setLegalGuardianStatus: (state, action) => {
      state.legalGuardianStatusStep.legalStatus = action.payload.legalStatus;
      state.legalGuardianStatusStep.partnerKey = action.payload.partnerKey;
      state.legalGuardianStatusStep.partnerBirthDate =
        action.payload.partnerBirthDate;
    },
    setDocuments: (state, action) => {
      state.globalData.documents = action.payload;
    },
    setDocumentsStep: (state, action) => {
      state.documentsStep.uploadedDouments = action.payload;
    },
    resetAll: () => initialState,
    resetActionStatus: (state) => {
      state.actionStatus = false;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetVerification: (state) => {
      state.verificationStep.verification = false;
    },
  },
  extraReducers: {
    //get UnderAge Document List Action
    [getUnderAgeDocumentListAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getUnderAgeDocumentListAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.globalData.documents = action.payload;
    },
    [getUnderAgeDocumentListAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    // set data
    [postUnderAgeInfoAction.pending]: (state, action) => {
      state.loading = true;
    },
    [postUnderAgeInfoAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.childDataStep.contactid = action.payload.contactid;
      state.actionStatus = true;
    },
    [postUnderAgeInfoAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }, // get phone number
    [getPersonalDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getPersonalDataAction.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.value.length) {
        state.legalGuardianStatusStep.partnerPhoneNumber =
          action.payload.value[0].mobilephone;
      } else {
        state.error = "Not found any legal guardian!";
      }
    },
    [getPersonalDataAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }, //Verifications send
    [verificationSendSMSCodeAction.pending]: (state, action) => {
      state.loading = true;
    },
    [verificationSendSMSCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [verificationSendSMSCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }, // Verifications CheckBox
    [verificationCheckSMSCodeAction.pending]: (state, action) => {
      state.loading = true;
    },
    [verificationCheckSMSCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      const { data } = action.payload;

      if (data.status === 200 || data.status === 201)
        state.verificationStep.verification = true;
      else if (Object.keys(data?.data?.error).length > 0) {
        state.error = data.data.error.message;
      }
    },
    [verificationCheckSMSCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const underAgeCountries = (state) => state.underage.globalData.countries;
export const underAgeGenders = (state) => state.underage.globalData.genders;
export const underAgeGeschlechtList = (state) =>
  state.underage.globalData.geschlechtList;
export const underAgedocumnets = (state) => state.underage.globalData.documents;
export const documentsStepData = (state) => state.underage.documentsStep;
export const underAgeFullState = (state) => state.underage;
export const selectWorkspaceTypeFields = (state) =>
  state.underage.selectWorkspaceTypeStep;
export const childDataStepFields = (state) => state.underage.childDataStep;
export const legalGuardianStatusFields = (state) =>
  state.underage.legalGuardianStatusStep;
export const fullnameData = (state) => state.underage.fullname;
export const {
  setWorkspaceType,
  setChildData,
  setLegalGuardianStatus,
  setChildId,
  setDocumentsStep,
  setDocuments,
  setFullname,
  resetActionStatus,
  resetError,
  resetVerification,
  resetAll,
} = underageSlice.actions;
export default underageSlice.reducer;
