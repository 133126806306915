import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { longDateTo10 } from "../../../core/utils/convert";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getPersonalDataFunc = async (filter, { getState }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };
    const {
      data: { data },
    } = filter.id
      ? await axios.get(`${BASE_URL}data/contacts(${filter.id})/`, config)
      : filter.ratiorKey
      ? await axios.get(
          `${BASE_URL}data/contacts/?$filter=arades_ratior_key eq '${
            filter.ratiorKey
          }' and birthdate ge ${longDateTo10(
            new Date(filter.birthdate)
          )} and birthdate le ${longDateTo10(new Date(filter.birthdate))}`,
          config
        )
      : null;

    if (data) {
      data.documentsExpired =
        longDateTo10(new Date()) > data.arades_ablaufdatum_ausweisdokument;
      return data;
    }
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};

export default getPersonalDataFunc;
