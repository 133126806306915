import React, { useState, useEffect } from "react";
import { ConfirmModal, LoadingOverlay, Verification } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
} from "../../../redux/slices/exchange";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  globalData,
  verificationSteps,
  resetError,
  resetActionStatus,
  resetAll,
} from "../../../redux/slices/becomeSeller/becomeSeller.slice";
import { postSellerInfoAction } from "../../../redux/slices/becomeSeller";

import { useTranslation } from "react-i18next";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";

function VerificationStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const sellerGlobalData = useSelector(globalData);
  const verificationStepsData = useSelector(verificationSteps);

  const [successStatusModal, setSuccessStatusModal] = useState(false);
  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (!verificationStepsData.verification) {
      dispatch(
        verificationCheckSMSCodeAction({
          activationCode: activationCode,
          mobileNumber: localStorage.getItem("mobileNumber"),
        })
      );
    }
  };

  useEffect(() => {
    if (
      sellerGlobalData.actionStatus &&
      sellerGlobalData.actionType === "verification"
    ) {
      enqueueSnackbar("Verification successful", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetActionStatus());
      dispatch(postSellerInfoAction());
    } else if (
      sellerGlobalData.actionStatus &&
      sellerGlobalData.actionType === "postSellerInfo"
    ) {
      setSuccessStatusModal(true);
    }
  }, [sellerGlobalData.actionStatus]);

  useEffect(() => {
    if (sellerGlobalData.error != null) {
      enqueueSnackbar(sellerGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [sellerGlobalData.error]);

  useEffect(() => {
    setLoading(sellerGlobalData.loading);
  }, [sellerGlobalData.loading]);

  const sendSMS = (to = null) => {
    let phoneNumber;
    if (to) {
      phoneNumber = to;
    } else {
      phoneNumber = localStorage.getItem("mobileNumber");
    }
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: phoneNumber,
      })
    );
  };

  const resendCode = () => {
    sendSMS();
  };

  return (
    <>
      <ConfirmModal
        open={successStatusModal}
        title={"Herzlichen Glückwunsch"}
        showCloseButton={false}
        message={<div>{t("become-seller.email-contract")}</div>}
        okTitle={t("buttons.go-to-dashboard")}
        onOk={() => {
          dispatch(resetAll());
          navigate(URL.DASHBOARD);
        }}
      ></ConfirmModal>

      <LoadingOverlay show={loading}>
        <Verification
          number={localStorage?.getItem("mobileNumber")}
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          handleNext={onSubmit}
          resendCode={resendCode}
          verified={verificationStepsData.verification}
        />
      </LoadingOverlay>
    </>
  );
}

export default VerificationStep;
