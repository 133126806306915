import React, { useState, useEffect } from "react";
import { LoadingOverlay, Verification } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
  getStorageNumbersAction,
} from "../../../../redux/slices/storage";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  storageGlobalData,
  storageFullData,
  storageOtherPersonStep,
  setVerificationType,
  resetError,
  resetActionStatus,
  resetActionType,
} from "../../../../redux/slices/storage/storage.slice";
import { useTranslation } from "react-i18next";
import { ERROR_CONTACT_US } from "../../../../core/constants";
import { BYPASS_ADOBE_SIGN } from "../../../../core/apiconfig/environment";

function VerificationStep({ handleCurrentStep, forPartner = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const storageData = useSelector(storageGlobalData);
  const storageAllData = useSelector(storageFullData);
  const storageOtherPersonData = useSelector(storageOtherPersonStep);
  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (
      (storageData.verificationType == "myself" && storageData.verification) ||
      (storageData.verificationType == "partner" &&
        storageData.verificationPartner)
    ) {
      handleCurrentStep("next");
    } else {
      dispatch(
        verificationCheckSMSCodeAction({
          activationCode: activationCode,
          mobileNumber: forPartner
            ? storageOtherPersonData?.partnerPhoneNumber
            : localStorage?.getItem("mobileNumber"),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setVerificationType(forPartner ? "partner" : "myself"));
  }, []);

  useEffect(() => {
    if (storageAllData.error != null) {
      enqueueSnackbar(storageAllData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [storageAllData.error]);

  useEffect(() => {
    if (
      storageData.actionStatus &&
      storageAllData.globalData.actionType == "verificationCheckSMS"
    ) {
      enqueueSnackbar("Verification successful", {
        variant: "success",
        autoHideDuration: 3000,
      });

      dispatch(resetActionStatus());

      if (storageData.verificationType == "partner") {
        handleCurrentStep("next");
        dispatch(resetActionType());
      } else if (
        BYPASS_ADOBE_SIGN &&
        storageData.verificationType == "myself"
      ) {
        dispatch(getStorageNumbersAction());
      }
    } else if (
      storageData?.actionStatus &&
      !storageAllData.error &&
      storageAllData.globalData.actionType == "getStorageNumbers"
    ) {
      dispatch(resetActionStatus);
      handleCurrentStep("next");
    }
  }, [storageData.actionStatus]);

  useEffect(() => {
    if (storageAllData.error != null) {
      enqueueSnackbar(storageAllData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [storageAllData.error]);

  const sendSMS = () => {
    const currentAccountPhoneNumber = localStorage.getItem("mobileNumber");
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: currentAccountPhoneNumber,
      })
    );
  };

  useEffect(() => {
    setLoading(storageAllData.loading);
  }, [storageAllData.loading]);

  const resendCode = () => {
    sendSMS();
  };

  return (
    <>
      <LoadingOverlay
        show={loading}
        message={
          BYPASS_ADOBE_SIGN &&
          storageData.verificationType == "myself" &&
          storageAllData.globalData.actionType == "verificationCheckSMS"
            ? "Die Erstellung Ihrer Lagerplätze kann bis zu 30 Sekunden dauern. Bitte haben Sie etwas Geduld, wir sind gleich fertig"
            : ""
        }
      >
        <Verification
          number={
            forPartner
              ? storageOtherPersonData?.partnerPhoneNumber
              : localStorage?.getItem("mobileNumber")
          }
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          handleNext={onSubmit}
          resendCode={resendCode}
          verified={
            storageData.verificationType == "myself"
              ? storageData.verification
              : storageData.verificationPartner
          }
        />
      </LoadingOverlay>
    </>
  );
}

export default VerificationStep;
