import { ConvertGramToDestUnit } from "../../../core/utils/convert";
import { getStorageListFunc, getMetalPriceFunc } from "../";
import getStorageAmountFunc from "./getStorageAmount.func";
import getSubStoragesOfOneStorageFunc from "./getSubStoragesOfOneStorage.func";
import { METAL_CHARS } from "../../../core/constants";

const getStorageDetailedListFunc = async (params, { getState }) => {
  const { ownerId, fetchSubStorages, fetchAmount, fetchPrices } = params;
  try {
    let prices = { gold: 0, silver: 0, platin: 0, palladium: 0 };

    if (fetchPrices) {
      let result = {};

      result = await getMetalPriceFunc(
        { productName: METAL_CHARS.GOLD },
        { getState }
      );
      prices.gold = result.amount;

      result = await getMetalPriceFunc(
        { productName: METAL_CHARS.SILVER },
        { getState }
      );
      prices.silver = result.amount;

      result = await getMetalPriceFunc(
        { productName: METAL_CHARS.PLATIN },
        { getState }
      );
      prices.platin = result.amount;

      result = await getMetalPriceFunc(
        { productName: METAL_CHARS.PALLADIUM },
        { getState }
      );
      prices.palladium = result.amount;
    }

    let storageList = await getStorageListFunc(ownerId, getState);
    let list = [];
    for (let i = 0; i < storageList.length; i++) {
      let storage = storageList[i];
      let fetchedMenge = fetchAmount
        ? await getStorageAmountFunc(storage.msdyn_warehouseid, getState)
        : {};
      let subStorageList = fetchSubStorages
        ? await getSubStoragesOfOneStorageFunc(
            { storageId: storage.msdyn_warehouseid, fetchAmount: true },
            getState
          )
        : [];

      storage.subStorageCount = subStorageList.length;
      storage.subStorageList = subStorageList;
      storage.price = !storage.pending
        ? fetchedMenge.arades_verfuegbare_menge *
          (storage.metalName.toLowerCase().indexOf("gold") >= 0
            ? prices.gold
            : storage.metalName.toLowerCase().indexOf("silber") >= 0
            ? prices.silver
            : storage.metalName.toLowerCase().indexOf("platin") >= 0
            ? prices.platin
            : storage.metalName.toLowerCase().indexOf("palladium") >= 0
            ? prices.palladium
            : 0)
        : 0;
      storage.arades_verfuegbare_menge = !storage.pending
        ? fetchedMenge &&
          ConvertGramToDestUnit(
            fetchedMenge.arades_verfuegbare_menge,
            storage.weightUnit
          ) +
            " " +
            storage.weightUnit
        : "Pending";
      storage.arades_menge = !storage.pending
        ? fetchedMenge &&
          ConvertGramToDestUnit(fetchedMenge.arades_menge, storage.weightUnit) +
            " " +
            storage.weightUnit
        : "Pending";

      list.push(storage);
    }
    return list;
  } catch (err) {
    throw Error(err);
  }
};
export default getStorageDetailedListFunc;
