import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { longDateTo10 } from "../../../core/utils/convert";
import { storageIsPending } from "../../../core/utils/business";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getStorageByNumberBirthFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  debugger;

  let fetchQuery = `${BASE_URL}data/msdyn_warehouses/?$filter=msdyn_name eq '${filter.storageNumber}'`;

  const storageResult = await axios.get(fetchQuery, config);
  if (
    storageResult?.data?.status != 200 ||
    !storageResult?.data?.data?.value?.length > 0
  )
    throw Error("Lagernummer wurde nicht gefunden"); //("Storage number not found");

  let storage = storageResult.data.data.value[0];

  if (storageIsPending(storage))
    throw Error(
      "Lagerplatz Status ist ausstehend und kann nicht werwendet werden"
    ); //("This Storage status is pending , can not be used");

  let fetchQueryBirth = `${BASE_URL}data/arades_lagerplatz_persons/?$select=_arades_lagerplatz_value&$filter=_arades_lagerplatz_value eq '${
    storage.msdyn_warehouseid
  }'&$expand=arades_besitzer_contact($select=*;$filter= birthdate ge ${longDateTo10(
    new Date(filter.birthdate)
  )} and birthdate le ${longDateTo10(new Date(filter.birthdate))})`;

  const birthResult = await axios.get(fetchQueryBirth, config);

  if (
    birthResult?.data?.status != 200 ||
    !birthResult?.data?.data?.value?.length > 0
  )
    throw Error(
      "Es wurde kein Lagrplatz-Besitzer mit diesem Geburtsdatum gefunden"
    ); //("Storage with this person bithdate not found");

  let result = birthResult.data.data.value.filter(
    (x) => x.arades_besitzer_contact
  );
  if (result.length == 0)
    throw Error("Storage with this person bithdate not found");

  return { storage: storage, contact: result[0].arades_besitzer_contact };
};

export default getStorageByNumberBirthFunc;
