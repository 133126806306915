import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { dateString } from "../../../core/utils/convert";
import { QUOTE_DIRECTION } from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getVermittlerBuyInvoiceList = async (isContact, vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const contactsLink = `<link-entity name="contact" from="contactid" to="customerid" alias="c" link-type="inner" >
    <attribute name="contactid" />
    <attribute name="firstname" />
    <attribute name="lastname" />   
    <link-entity name="arades_vermittlerkonto" from="arades_vermittlerkontoid" to="arades_vermittlerkonto" link-type="inner" alias="ab">
      <filter type="and">
        <condition attribute="arades_firma" operator="eq" value="{${vermittlerId}}"/>
      </filter>
    </link-entity>
    <link-entity name="arades_titel" from="arades_titelid" to="arades_titel" alias="t" link-type="outer" >
      <attribute name="arades_name" />         
    </link-entity>
  </link-entity> `;

  const accountsLink = `<link-entity name="account" from="accountid" to="customerid" alias="a" link-type="inner" >
    <attribute name="name" />
    <link-entity name="arades_vermittlerkonto" from="arades_vermittlerkontoid" to="arades_vermittlerkonto" link-type="inner" alias="ab">
      <filter type="and">
        <condition attribute="arades_firma" operator="eq" value="{${vermittlerId}}"/>
      </filter>
    </link-entity>
  </link-entity>`;

  let firstDayOfYear = "01/01/" + new Date().getUTCFullYear();
  var fetchQuery = `
  <fetch mapping="logical" version="1.0">
    <entity name="invoice" alias="i">
      <attribute name="totalamount" />
      <attribute name="arades_ueberweisungsbetrag" />  
      <attribute name="createdon" />
      <attribute name="customerid" />
      <attribute name="arades_verkaeufer" />
      <attribute name="arades_lagerplatz_kaeufer" />
      <attribute name="arades_lagerplatz_verkaeufer" />
      <filter>
        <condition attribute="createdon"  operator="ge" value="${firstDayOfYear}" />
        <condition attribute="arades_richtung"  operator="eq" value="${
          QUOTE_DIRECTION.BUY
        }" />
      </filter>     
      <link-entity name="msdyn_warehouse" from="msdyn_warehouseid" to="arades_lagerplatz_kaeufer" alias="s" link-type="inner">
        <attribute name="msdyn_name" />       
        <link-entity name="product" from="productid" to="arades_edelmetall_typ" alias="p" link-type="inner" >
          <attribute name="name" />
          <attribute name="productnumber" />          
        </link-entity>          
      </link-entity>
      ${isContact ? contactsLink : accountsLink}
    </entity>
  </fetch>`;

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/invoices?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Vermittler Rechnung wurde nicht gefunden"); //("vermittler invoices not fetched");

  return result.data.data.value;
};

const getVermittlerBuyInvoiceListFunc = async (sellerId, { getState }) => {
  let transOfContacts = await getVermittlerBuyInvoiceList(true, sellerId);
  let transOfAccounts = await getVermittlerBuyInvoiceList(false, sellerId);

  let allInvoices = transOfContacts.concat(transOfAccounts);

  let list = [];

  //parichehr you can fetch other fields also , if this list is needed some where else
  allInvoices.map((item) => {
    let tmp = {
      totalPrice: item["totalamount"],
      createDate: item["createdon"],
      createDate_formatted: dateString(item["createdon"]),
      customerId: item["_customerid_value"],
      customerName: item["c.firstname"]
        ? item["t.arades_name"]
          ? item["t.arades_name"] +
            " " +
            item["c.firstname"] +
            " " +
            item["c.lastname"]
          : "" + item["c.firstname"] + " " + item["c.lastname"]
        : item["a.name"],
      metalName: item["p.name"],
      productNumber: item["p.productnumber"],
    };
    list.push(tmp);
  });

  return list.sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getVermittlerBuyInvoiceListFunc;
