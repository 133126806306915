import React, { useState, useEffect } from "react";
import SubmitModal from "../common/popups/submitModal";
import { Input, Select, Datepicker, AutoComplete } from "../common";
import moment from "moment";
import { Grid, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { postCustomerInfoAction } from "../../redux/slices/customerList";
import {
  globalData,
  resetError,
  resetActionStatus,
  resetAll,
  customerInfo,
} from "../../redux/slices/customerList/customerList.slice";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { ACTION_CODE_TYPE, ERROR_CONTACT_US } from "../../core/constants";
import {
  globalsTitles,
  globalsGenders,
} from "../../redux/slices/globals/globals.slice";

import { getActionCodeListAction } from "../../redux/slices/vermittlerDashboard";
import {
  actionCodeList,
  globalData as vermittlerGlobalData,
} from "../../redux/slices/vermittlerDashboard/vermittlerDashboard.slice";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AddCustomerModal = ({ open, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const requiredMsg = t("validation.required");
  const emailMismatchMsg = t("validation.email-mismatch");
  const emailIsValidMsg = t("validation.email-invalid");
  const [loading, setLoading] = useState(false);

  const customerGlobalData = useSelector(globalData);
  const sellerGlobalData = useSelector(vermittlerGlobalData);
  const titleList = useSelector(globalsTitles);
  const genderList = useSelector(globalsGenders);

  const sellerActionCodes = useSelector(actionCodeList);
  const [sellerActionCodeList, setSellerActionCodeList] = useState([]);

  const onSubmit = (data) => {
    if (!customerGlobalData.loading) dispatch(postCustomerInfoAction(data));
  };

  useEffect(() => {
    dispatch(
      getActionCodeListAction({
        ownerId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  useEffect(() => {
    if (
      customerGlobalData.actionStatus &&
      customerGlobalData.actionType == "postCustomerInfo"
    ) {
      onClose();
      onSuccess();
      reset();
      dispatch(resetActionStatus());
    }
  }, [customerGlobalData.actionStatus]);

  useEffect(() => {
    if (
      sellerGlobalData.actionStatus &&
      sellerGlobalData.actionType == "getSellerActionCodes"
    ) {
      setSellerActionCodeList(
        sellerActionCodes?.map((item) => {
          return {
            key: item.actionCode,
            value:
              item.actionCode +
              " : (" +
              (item.arades_typ == ACTION_CODE_TYPE.STORAGE_FEE
                ? (item.discount ? item.discount : 0) +
                  " " +
                  t("action-code.fix-fee")
                : item.arades_typ == ACTION_CODE_TYPE.BECOME_SELLER
                ? t("action-code.become-seller")
                : item.arades_typ == ACTION_CODE_TYPE.LOYALTY
                ? (item.loyalityMonthCount ? item.loyalityMonthCount : 0) +
                  " " +
                  t("action-code.loyalty-months")
                : "") +
              (item.description ? " _ " + item.description : "") +
              ")",
          };
        })
      );
      dispatch(resetActionStatus());
    }
  }, [sellerGlobalData.actionStatus]);

  useEffect(() => {
    if (
      customerGlobalData.error != null &&
      customerGlobalData.actionType === "postCustomerInfo"
    ) {
      debugger;
      enqueueSnackbar(customerGlobalData.error, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [customerGlobalData.error]);

  useEffect(() => {
    setLoading(customerGlobalData.loading);
  }, [customerGlobalData.loading]);

  const schema = yup.object().shape({
    firstname: yup.string().required(requiredMsg),
    arades_anrede: yup.string().required(requiredMsg),
    firstname: yup.string().required(requiredMsg),
    lastname: yup.string().required(requiredMsg),
    emailaddress1: yup.string().email(emailIsValidMsg).required(requiredMsg),
    emailRepeat: yup
      .string()
      .email(emailIsValidMsg)
      .oneOf([yup.ref("emailaddress1")], emailMismatchMsg)
      .required(requiredMsg),
    birthdate: yup
      .string()
      .nullable()
      .test("DOB", t("validation.age-above-18"), (value) => {
        return !value || moment().diff(moment(value), "years") >= 18;
      }),
  });
  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      firstname: "",
      lastname: "",
      arades_titel: null,
      birthdate: null,
      actionCode: "",
      emailaddress1: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <SubmitModal
      open={open}
      loading={loading}
      title={t("add-customer.title")}
      acceptTitle={t("buttons.add")}
      rejectTitle={t("buttons.cancel")}
      size="lg"
      formIsValid={true}
      onAccept={handleSubmit(onSubmit)}
      onReject={() => {
        reset();
        onClose();
      }}
      content={
        <Grid container spacing={6} mt={"10px"}>
          <Grid xs={12} lg={6} item>
            <Select
              control={control}
              name="arades_anrede"
              error={Boolean(errors.arades_anrede)}
              helperText={t(errors.arades_anrede?.message)}
              label={`${t("personal-info.gender")}*`}
              options={genderList}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <Select
              control={control}
              name={"arades_titel"}
              options={titleList}
              label={t("personal-info.title")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <Input
              control={control}
              error={Boolean(errors.firstname)}
              helperText={t(errors.firstname?.message)}
              name="firstname"
              label={`${t("personal-info.first-name")}*`}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <Input
              control={control}
              error={Boolean(errors.lastname)}
              helperText={t(errors.lastname?.message)}
              name="lastname"
              label={`${t("personal-info.last-name")}*`}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <Datepicker
              label={t("personal-info.birth-date")}
              control={control}
              name="birthdate"
              maxDate={Date.now()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.birthdate)}
                  helperText={t(errors.birthdate?.message)}
                />
              )}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <AutoComplete
              control={control}
              options={sellerActionCodeList}
              name={"actionCode"}
              label={t("action-code.select")}
            ></AutoComplete>
          </Grid>
          <Grid xs={12} lg={6} item>
            <Input
              control={control}
              error={Boolean(errors.emailaddress1)}
              helperText={t(errors.emailaddress1?.message)}
              name="emailaddress1"
              label={t("personal-info.email-address") + "*"}
              placeholder="Exp: mail@website.com"
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <Input
              control={control}
              error={Boolean(errors.emailRepeat)}
              helperText={t(errors.emailRepeat?.message)}
              name="emailRepeat"
              placeholder="Exp: mail@website.com"
              label={t("personal-info.repeat-email-address") + "*"}
            />
          </Grid>
        </Grid>
      }
    ></SubmitModal>
  );
};

export default AddCustomerModal;
