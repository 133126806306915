export const zeroPad = (num, places) => String(num).padStart(places, "0");

export const dateString = (datestr) => {
  if (!datestr) return datestr;
  let idate = new Date(datestr);

  if (localStorage.getItem("i18nextLng") == "de")
    return idate.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  else
    return idate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
};

//we need it to make backend date format or to compare dates in us order
export const longDateTo10 = (idate) => {
  if (!idate) return null;
  else {
    idate = new Date(idate);
    let month = idate.getMonth() + 1;
    let day = idate.getDate();
    return (
      idate.getFullYear() + "-" + zeroPad(month, 2) + "-" + zeroPad(day, 2)
    );
  }
};

export const dateTimeString = (m) => {
  if (!m) return null;
  m = new Date(m);
  return (
    dateString(m) +
    " " +
    ("0" + (m.getUTCHours() + 1)).slice(-2) +
    ":" +
    ("0" + (m.getUTCMinutes() + 1)).slice(-2) +
    ":" +
    ("0" + m.getUTCSeconds()).slice(-2)
  );
};

export const timeString = (m) => {
  if (!m) return null;
  let idate = new Date(m);
  return (
    ("0" + (idate.getUTCHours() + 1)).slice(-2) +
    ":" +
    ("0" + (idate.getUTCMinutes() + 1)).slice(-2)
  );
};

export function Currency(digit) {
  if (!digit) digit = 0;

  var formatter = new Intl.NumberFormat(
    localStorage.getItem("i18nextLng") == "de" ? "de-DE" : "en-US",
    {
      style: "currency",
      currency: "EUR",
    }
  );

  return formatter.format(digit);
}

export function ConvartToGramms(amount, unitStr) {
  let amountGrams = amount ? amount : 0;
  if (!unitStr) return;
  if (unitStr.toLowerCase().indexOf("kilo") >= 0) amountGrams *= 1000;
  else if (
    unitStr.toLowerCase().indexOf("unze") >= 0 ||
    unitStr.toLowerCase().indexOf("ounce") >= 0
  )
    amountGrams *= 28.3495;

  return amountGrams.toFixed(5);
}

export function ConvertGramToDestUnit(amount, unitStr) {
  let amountGrams = amount ? amount : 0;
  if (!unitStr) return;
  if (unitStr.toLowerCase().indexOf("kilo") >= 0) amountGrams /= 1000;
  else if (
    unitStr.toLowerCase().indexOf("unze") >= 0 ||
    unitStr.toLowerCase().indexOf("ounce") >= 0
  )
    amountGrams /= 28.3495;

  return GermanFloat(amountGrams);
}

export function GermanFloat(amount) {
  if (amount === null || amount == undefined) return "";
  let amountInt = Math.trunc(amount);
  let amountFloating = (amount - amountInt).toFixed(5);
  let commaSeperatedDecimal = amountInt
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return (
    commaSeperatedDecimal.toString() +
    "," +
    amountFloating.toString().substring(2, 7)
  );
}

export function GermanFloatToFloat(amount) {
  if (!amount) return amount;
  return parseFloat(amount.replace(/\./g, "").replace(",", "."));
}

export function MobileNumberHide(mobileNumber) {
  let hidedMobileNumber = mobileNumber.slice(0, 5);
  hidedMobileNumber += "******";
  hidedMobileNumber += mobileNumber.slice(11, mobileNumber.length);
  return hidedMobileNumber;
}
