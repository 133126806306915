import React, { useState } from "react";
import { FormSteper } from "../../common";

import { useDispatch } from "react-redux";
import { resetAll } from "../../../redux/slices/transfer/transfer.slice";
import { useNavigate, useParams } from "react-router-dom";
import { URL, WORKSPACETYPE } from "../../../core/constants";
import { WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";

import SelectRecipient from "./selectRecipient.step";
import EnterAmount from "../sharedSteps/enterAmount.step";
import SelectWhoPays from "./selectWhoPays.step";
import Summary from "./summary.step";
import VerificationStep from "./Verification.step";
import VerificationPartnerStep from "../sharedSteps/VerificationPartner.step";

function ShareWithPartnerStepManager({
  handleCurrentStep: handlerParentStep,
  parent,
}) {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 6;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const detailStorageId = useParams().storageid;

  const HandleCurrentStep = (step, parent) => {
    if (parent) {
      handlerParentStep(parent);
    } else {
      if (isNaN(parseInt(step))) {
        if (step === "next" && currentStep < stepsCount) {
          setCurrentStep((prev) => prev + 1);
        } else if (step === "prev" && currentStep > 1) {
          setCurrentStep((prev) => prev - 1);
        }
      } else {
        if (currentStep <= stepsCount && currentStep >= 1) {
          setCurrentStep(step);
        }
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(`/${URL.STORAGE}/${detailStorageId}`);
  };

  return (
    <FormSteper
      parent={parent}
      currentStep={currentStep}
      setCurrentStep={HandleCurrentStep}
      loading={loading}
      noFooter={[1, 2, 3, 4, 5, 6]}
      hasCancel={[1, 2, 3, 4, 5, 6]}
      noBackButton={[6]}
      onCancel={handleCancel}
      headerTitles={[
        t("transfer.select-recipient"),
        t("transfer.who-pays"),
        t("transfer.enter-amount"),
        t("transfer.summary"),
        t("transfer.verification"),
        `${
          localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE
            ? t("transfer.other-guardian")
            : t("transfer.storage-partner")
        } ${t("acceptance")}`,
      ]}
    >
      {/* Step 1- Select Recipient */}
      <SelectRecipient handleCurrentStep={HandleCurrentStep} />

      {/* Step 2- Select Who Pays */}
      <SelectWhoPays handleCurrentStep={HandleCurrentStep} />

      {/* Step 3- Select storage */}
      <EnterAmount handleCurrentStep={HandleCurrentStep} />

      {/* Step 4 */}
      <Summary handleCurrentStep={HandleCurrentStep} />

      {/* Step 5 */}
      <VerificationStep handleCurrentStep={HandleCurrentStep} />

      {/* Step 6- Verification partner or other guardian */}
      <VerificationPartnerStep handleCurrentStep={HandleCurrentStep} />
    </FormSteper>
  );
}

export default ShareWithPartnerStepManager;
