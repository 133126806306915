import React, { useEffect, useState } from "react";
import {
  WORKSPACE_ID,
  WORKSPACE_TYPE,
  WORKSPACE_NAME,
} from "../../core/apiconfig/constantApi";
import { Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  ACTION_CODE_TYPE,
  ERROR_CONTACT_US,
  WORKSPACETYPE,
} from "../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, DataTable } from "../common";
import {
  actionCodeList,
  globalData,
  resetError,
  resetActionStatus,
} from "../../redux/slices/clientDashboard/clientDashboard.slice";
import { getCustomerActionCodeListAction } from "../../redux/slices/clientDashboard";
import AssignCustomerActionCodeModal from "./assignCustomerActionCodeModal";
import { useTranslation } from "react-i18next";

const CustomerActionCodeBox = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const dashboardGlobalData = useSelector(globalData);
  const actionCodeListFields = useSelector(actionCodeList);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [actionCodes, setActionCodes] = useState([]);

  useEffect(() => {
    dispatch(
      getCustomerActionCodeListAction({
        ownerId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  useEffect(() => {
    let tmpList = [];
    for (let i = 0; i < actionCodeListFields.length; i++) {
      let item = actionCodeListFields[i];
      if (item.arades_typ != ACTION_CODE_TYPE.TRANSFER)
        tmpList.push({
          actionCode: item.actionCode,
          type:
            item.arades_typ == ACTION_CODE_TYPE.STORAGE_FEE
              ? (item.discount ? item.discount : 0) +
                " " +
                t("action-code.fix-fee")
              : item.arades_typ == ACTION_CODE_TYPE.BECOME_SELLER
              ? t("action-code.become-seller")
              : item.arades_typ == ACTION_CODE_TYPE.BUY_FEE
              ? t("action-code.buy-fee")
              : item.arades_typ == ACTION_CODE_TYPE.LOYALTY
              ? (item.loyalityMonthCount ? item.loyalityMonthCount : 0) +
                " " +
                t("action-code.loyalty-months")
              : "",
          activeStatus: item.active
            ? t("action-code.active")
            : t("action-code.inactive"),
          expiry: item.validForever ? t("action-code.no-limit") : item.endDate,
        });
    }
    setActionCodes(tmpList);
  }, [actionCodeListFields]);

  // useEffect(() => {
  //   if (dashboardGlobalData.error != null) {
  //     enqueueSnackbar(dashboardGlobalData.error + ERROR_CONTACT_US, {
  //       variant: "error",
  //       autoHideDuration: 20000,
  //     });
  //     dispatch(resetError());
  //   }
  // }, [dashboardGlobalData.error]);

  useEffect(() => {
    if (
      dashboardGlobalData.actionStatus &&
      dashboardGlobalData.actionType == "getCustomerActionCodes"
    ) {
      dispatch(resetActionStatus());
    }
  }, [dashboardGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(dashboardGlobalData.loading);
  }, [dashboardGlobalData.loading]);

  return (
    <>
      <AssignCustomerActionCodeModal
        open={assignModalOpen}
        contactId={
          localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.CONTACT ||
          localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE
            ? localStorage.getItem(WORKSPACE_ID)
            : null
        }
        accountId={
          !(
            localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.CONTACT ||
            localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE
          )
            ? localStorage.getItem(WORKSPACE_ID)
            : null
        }
        customerName={localStorage.getItem(WORKSPACE_NAME)}
        onClose={() => {
          setAssignModalOpen(false);
          dispatch(
            getCustomerActionCodeListAction({
              ownerId: localStorage.getItem(WORKSPACE_ID),
            })
          );
        }}
      ></AssignCustomerActionCodeModal>
      <LoadingOverlay show={loading}>
        <div className="container-card">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "10px",
            }}
          >
            <Typography variant="subtitle1" component="h6">
              {t("action-code.title")}
            </Typography>

            <Typography
              onClick={() => setAssignModalOpen(true)}
              className="dashboard-link"
              variant="subtitle2"
              sx={{ cursor: "pointer" }}
            >
              {`+ ${t("action-code.add")}`}
            </Typography>
          </Box>
          <Box mt={"20px"}>
            <DataTable
              hideTitle={true}
              entityName={"arades_aktionscode"}
              canAdd={false}
              canDelete={false}
              canEdit={false}
              nofetch={true}
              data={actionCodes}
              defaultPageSize="10"
              columns={[
                {
                  name: "actionCode",
                  caption: t("action-code.code"),
                },
                {
                  name: "type",
                  caption: t("action-code.type"),
                },

                { name: "activeStatus", caption: t("action-code.status") },
                {
                  name: "expiry",
                  caption: t("action-code.exp-date"),
                },
              ]}
            ></DataTable>
          </Box>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default CustomerActionCodeBox;
