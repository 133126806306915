import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { getMetalPriceFunc } from "../";
import {
  dateString,
  GermanFloat,
  zeroPad,
  Currency,
  ConvertGramToDestUnit,
} from "../../../core/utils/convert";
import { storageIsPending, storageStatus } from "../../../core/utils/business";
import {
  WORKSPACETYPE,
  STORAGE_PERSON_TYPE,
  STORAGE_STATUS,
  METAL_CHARS,
} from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getCustomerStorages = async (
  workspaceType,
  customerId,
  prices,
  fetchSubStorages
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let linkToSubStorages = fetchSubStorages
    ? `<link-entity name="msdyn_warehouse" from="arades_uebergeordneter_lagerplatz" to="msdyn_warehouseid" link-type="outer" >         
        <attribute name="msdyn_warehouseid" alias="subStorageCount" aggregate="countcolumn"  />
       </link-entity>`
    : "";

  var fetchQuery = `
  <fetch mapping="logical" version="1.0" aggregate="true">
    <entity name="arades_lagerplatz_person">
      <attribute name="arades_lagerplatz_personid" alias="arades_lagerplatz_personid" groupby="true" />
      <filter type="and">
        <condition attribute="arades_besitzer" operator="eq" value="${customerId}" />
        <filter type="or">
        <condition attribute="arades_typ" operator="eq" value="${
          workspaceType != WORKSPACETYPE.UNDER_AGE
            ? STORAGE_PERSON_TYPE.OWNER
            : STORAGE_PERSON_TYPE.UNDER_AGE
        }" />
        <condition attribute="arades_typ" operator="eq" value="${
          STORAGE_PERSON_TYPE.OTHER_PERSON
        }" />
        </filter>
      </filter>   
      <link-entity name="msdyn_warehouse" from="msdyn_warehouseid" to="arades_lagerplatz" link-type="inner">
        <attribute name="msdyn_warehouseid" alias="msdyn_warehouseid" groupby="true" />
        <attribute name="msdyn_name" alias="msdyn_name" groupby="true" />
        <attribute name="arades_uebergeordneter_lagerplatz" alias="arades_uebergeordneter_lagerplatz" groupby="true" />
        <attribute name="createdon" alias="createyear" groupby="true" dategrouping="year" />
	      <attribute name="createdon" alias="createmonth" groupby="true" dategrouping="month" />
	      <attribute name="createdon" alias="createday" groupby="true" dategrouping="day" />
        <attribute name="statecode" alias="statecode" groupby="true" />
        <attribute name="statuscode" alias="statuscode" groupby="true" />
        <attribute name="arades_lagerplatz_typ" alias="arades_lagerplatz_typ" groupby="true" />
        <attribute name="arades_verzicht_auf_widerruf" alias="arades_verzicht_auf_widerruf" groupby="true" />
        <attribute name="arades_edelmetall_typ" alias="arades_edelmetall_typ" groupby="true" />
        <attribute name="arades_gewichtseinheit" alias="arades_gewichtseinheit" groupby="true" />                
        <attribute name="arades_einrichtungsgebuehr_beglichen" alias="arades_einrichtungsgebuehr_beglichen" groupby="true" />
        <attribute name="arades_vertrag" alias="arades_vertrag" groupby="true" />
        <link-entity name="msdyn_productinventory" from="msdyn_warehouse" to="msdyn_warehouseid" link-type="outer" >
          <attribute name="msdyn_productinventoryid"  alias="msdyn_productinventoryid" groupby="true" />
          <attribute name="arades_verfuegbare_menge"  alias="arades_verfuegbare_menge" groupby="true" />
          <attribute name="arades_menge"  alias="arades_menge" groupby="true" />          
        </link-entity>
        ${linkToSubStorages}        
      </link-entity> 
    </entity>
  </fetch>`;

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/arades_lagerplatz_persons?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  let list = [];
  result.data.data.value.map((item) => {
    let metalName =
      item["arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"];

    let createDate =
      zeroPad(item.createyear, 4) +
      "-" +
      zeroPad(item.createmonth, 2) +
      "-" +
      zeroPad(item.createday, 0);

    let weightUnit =
      item["arades_gewichtseinheit@OData.Community.Display.V1.FormattedValue"];
    let weightUnitAbbreviation = weightUnit.toLowerCase().startsWith("gram")
      ? "g"
      : weightUnit.toLowerCase().startsWith("kilogram")
      ? "kg"
      : weightUnit;

    let unitPrice =
      metalName.toLowerCase().indexOf("gold") >= 0
        ? prices.gold
        : metalName.toLowerCase().indexOf("silber") >= 0
        ? prices.silver
        : metalName.toLowerCase().indexOf("platin") >= 0
        ? prices.platin
        : metalName.toLowerCase().indexOf("palladium") >= 0
        ? prices.palladium
        : 0;
    let tmp = {
      msdyn_warehouseid: item["msdyn_warehouseid"],
      msdyn_name: item["msdyn_name"],
      arades_uebergeordneter_lagerplatz:
        item["arades_uebergeordneter_lagerplatz"],
      statuscode: item["statuscode"],
      statecode: item["statecode"],
      arades_lagerplatz_typ: item["arades_lagerplatz_typ"],
      statusText: storageStatus(item.statuscode),
      weightUnit: weightUnit,
      arades_menge: item["arades_menge"] ?? 0,
      arades_verzicht_auf_widerruf: item["arades_verzicht_auf_widerruf"],
      arades_einrichtungsgebuehr_beglichen:
        item["arades_einrichtungsgebuehr_beglichen"],
      arades_vertrag: item["arades_vertrag"],
      createdon: createDate,
      createdon_formatted: dateString(createDate),
      metalName: metalName,
      unitPrice: unitPrice,
      subStorageCount: item.subStorageCount,
      subStorageList: [],
    };

    let isPending = storageIsPending(tmp);
    tmp.isPending = isPending;
    tmp.arades_verfuegbare_menge = !isPending
      ? item["arades_verfuegbare_menge"] ?? 0
      : 0;
    tmp.arades_menge = !isPending ? item["arades_menge"] ?? 0 : 0;
    tmp.arades_verfuegbare_menge_formatted = !isPending
      ? ConvertGramToDestUnit(
          item["arades_verfuegbare_menge"] ?? 0,
          weightUnit
        ) +
        " " +
        weightUnitAbbreviation
      : "Pending";
    tmp.arades_menge_formatted = !isPending
      ? ConvertGramToDestUnit(item["arades_menge"] ?? 0, weightUnit) +
        " " +
        weightUnitAbbreviation
      : "Pending";
    tmp.price = !isPending
      ? unitPrice * (item["arades_verfuegbare_menge"] ?? 0)
      : 0;
    tmp.price_formatted = Currency(tmp.price);

    list.push(tmp);
  });
  return list;
};

const getCustomerStorageListFunc = async (filter, { getState }) => {
  const { workspaceId, workspaceType, fetchSubStorages, fetchPrices } = filter;

  let prices = { gold: 0, silver: 0, platin: 0, palladium: 0 };

  if (fetchPrices) {
    let pResult = {};

    pResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.GOLD },
      { getState }
    );
    prices.gold = pResult.amount;

    pResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.SILVER },
      { getState }
    );
    prices.silver = pResult.amount;

    pResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.PLATIN },
      { getState }
    );
    prices.platin = pResult.amount;

    pResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.PALLADIUM },
      { getState }
    );
    prices.palladium = pResult.amount;
  }

  var storages = await getCustomerStorages(
    workspaceType,
    workspaceId,
    prices,
    fetchSubStorages
  );

  return storages.sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getCustomerStorageListFunc;
