import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

//parichehr : complete and use instead of all save contacts queries in forms
const postCustomerActionCodeFunc = async (
  actionCodeContactInfo,
  { getState }
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let result = await axios.post(
    `${BASE_URL}data/arades_aktionscode_nutzers/`,
    actionCodeContactInfo,
    config
  );

  if (result?.data?.status != 201)
    throw Error("Kontaktdaten könnten nicht gespeichert werden"); //("Contact not saved");
  return result.data.data;
};

export default postCustomerActionCodeFunc;
