import axios from "axios";
import { dateString, timeString } from "../../../core/utils/convert";
import {
  TOKEN_NAME,
  WORKSPACE_ID,
  WORKSPACE_NAME,
} from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";

const tablenameMap = {
  contact: "person",
  msdyn_warehouse: "warehouse",
  account: "company",
  arades_konto: "bank account",
  arades_zahlungsverkehr: "payment",
  quote: "quote",
  salesorder: "sales order",
  invoice: "invoice",
  msdyn_productinventory: "product inventory",
};
const getMessagesListFunc = async (params, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let directionFilter = `<condition attribute="participationtypemask" operator="eq" value="${
    params.Received ? "2" : "1"
  }" />`;
  let directionVersFilter = `<condition attribute="participationtypemask" operator="eq" value="${
    params.Received ? "1" : "2"
  }" />`;

  let contacOrAccountJoin = "";
  // <link-entity name="contact" from="contactid" to="partyid" alias="c" link-type="outer">
  //   <attribute name="firstname"/>
  //   <attribute name="lastname"/>
  // </link-entity>
  // <link-entity name="account" from="accountid" to="partyid" alias="a" link-type="outer">
  //   <attribute name="name"/>
  // </link-entity>

  let regardingJoins = "";
  //links with regardings if any other field is needed
  // <link-entity name="contact" from="contactid" to="regardingobjectid" alias="regarding_contact" link-type="outer">
  //   <attribute name="contactid" />
  //   <attribute name="firstname"/>
  //   <attribute name="lastname"/>
  // </link-entity>
  // <link-entity name="msdyn_warehouse" from="msdyn_warehouseid" to="regardingobjectid" alias="regarding_storage" link-type="outer">
  //   <attribute name="msdyn_warehouseid" />
  //   <attribute name="msdyn_name" />
  // </link-entity>
  // <link-entity name="account" from="accountid" to="regardingobjectid" alias="regarding_account" link-type="outer">
  //   <attribute name="accountid" />
  //   <attribute name="name" />
  // </link-entity>
  // <link-entity name="arades_konto" from="arades_kontoid" to="regardingobjectid" alias="regarding_bankaccount" link-type="outer">
  //   <attribute name="arades_kontoid" />
  //   <attribute name="arades_kontoinhaber" />
  // </link-entity>
  // <link-entity name="arades_zahlungsverkehr" from="arades_zahlungsverkehrid" to="regardingobjectid" alias="regarding_payment" link-type="outer">
  //   <attribute name="arades_zahlungsverkehrid" />
  //   <attribute name="arades_name" />
  // </link-entity>
  // <link-entity name="quote" from="quoteid" to="regardingobjectid" alias="regarding_quote" link-type="outer">
  //   <attribute name="quoteid" />
  //   <attribute name="name" />
  // </link-entity>
  // <link-entity name="salesorder" from="salesorderid" to="regardingobjectid" alias="regarding_salesorder" link-type="outer">
  //   <attribute name="salesorderid" />
  //   <attribute name="name" />
  // </link-entity>
  // <link-entity name="invoice" from="invoiceid" to="regardingobjectid" alias="regarding_invoice" link-type="outer">
  //   <attribute name="invoiceid" />
  //   <attribute name="name" />
  // </link-entity>
  // <link-entity name="msdyn_productinventory" from="msdyn_productinventoryid" to="regardingobjectid" alias="regarding_inventory" link-type="outer">
  //   <attribute name="msdyn_productinventoryid" />
  // </link-entity>

  let fetchQuery = `
      <fetch mapping="logical" version="1.0">
      <entity name="arades_nachricht_webapp" alias="nw">
        <attribute name="activityid"/>  
        <attribute name="subject"/>  
        <attribute name="description"/>
        <attribute name="createdon"/> 
        <attribute name="statecode"/>  
        <attribute name="statuscode"/> 
        <attribute name="arades_wichtige_nachricht"/> 
        <attribute name="arades_antworten_moeglich"/>
        <attribute name="regardingobjectid"/>         
        <attribute name="new_datei_name"/>
        <link-entity name="annotation" from="objectid" to="activityid" alias="at" link-type="outer">
          <attribute name="annotationid"/>
          <attribute name="filename"/>
          <attribute name="filesize"/>       
        </link-entity>                 
        <link-entity name="activityparty" from="activityid" to="activityid" alias="ap" link-type="inner">
          <attribute name="partyid"   />
          <attribute name="participationtypemask"/>          
          <filter>
          <condition attribute="partyid" operator="eq" value="${localStorage.getItem(
            WORKSPACE_ID
          )}" />
            ${directionFilter}     
          </filter>
          ${contacOrAccountJoin}

          <link-entity name="activityparty" from="activityid" to="activityid" alias="apv" link-type="inner">
            <attribute name="partyid"   />
            <attribute name="participationtypemask"/>
            <filter>
              ${directionVersFilter}
            </filter>
          </link-entity>
        </link-entity>  
        ${regardingJoins}      
      </entity>
    </fetch>`;

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/arades_nachricht_webapps?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Nachrichten könnten nicht geladen werden"); //("Messages not fetched");

  let toShowList = result.data.data.value.sort((a, b) =>
    a?.createdon >= b?.createdon ? -1 : 1
  );

  const workspaceName = localStorage.getItem(WORKSPACE_NAME);

  let list = [];
  toShowList.map((item) => {
    if (!list.find((x) => x.id == item.activityid)) {
      const otherPartyName =
        item["apv.partyid@OData.Community.Display.V1.FormattedValue"];
      let labels = [];
      if (item.arades_wichtige_nachricht == "100000000")
        labels.push({ type: "IMPORTANT" });
      if (item.arades_antworten_moeglich == "100000001")
        labels.push({ type: "NOREPLY" });
      let tmp = {
        id: item.activityid,
        from: params.Received ? otherPartyName : workspaceName,
        to: params.Received ? workspaceName : otherPartyName,
        subject: item.subject,
        regardedToId: item._regardingobjectid_value,
        regardedTo: item._regardingobjectid_value
          ? item[
              "_regardingobjectid_value@OData.Community.Display.V1.FormattedValue"
            ] +
            " _ (" +
            tablenameMap[
              item[
                "_regardingobjectid_value@Microsoft.Dynamics.CRM.lookuplogicalname"
              ]
            ] +
            ")"
          : "",
        receivedDate: dateString(item.createdon),
        receivedTime: timeString(item.createdon),
        description: item.description,
        viewed: true,
        labels: labels,
        attachedFiles: [],
      };

      let attachments = result.data.data.value.filter(
        (x) => x.activityid == item.activityid && x["at.annotationid"]
      );
      for (let i = 0; i < attachments?.length; i++) {
        tmp.attachedFiles.push({
          id: attachments[i]["at.annotationid"],
          fileName: attachments[i]["at.filename"],
          downloadLink: `${BASE_URL}download/attachment?id=${attachments[i]["at.annotationid"]}`,
          fileSize: (attachments[i]["at.filesize"] ?? 0) + " kb",
        });
      }

      list.push(tmp);
    }
  });

  return list;
};

export default getMessagesListFunc;
