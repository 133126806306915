import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONTACT_STATUS } from "../../../core/constants";
import { getPersonalDataFunc } from "./../../sharedFunctions";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import {
  BASE_URL,
  BYPASS_ADOBE_SIGN,
} from "../../../core/apiconfig/environment";
import axios from "axios";

const getPersonalDataAction = createAsyncThunk(
  "storage/getPartnerData",
  async (params, { getState }) => {
    var contact = await getPersonalDataFunc(params, { getState });
    if (
      !(
        contact?.value?.length > 0 &&
        (contact.value[0].statuscode == CONTACT_STATUS.NECT_YELLOW ||
          contact.value[0].statuscode == CONTACT_STATUS.VERIFIED)
      )
    )
      throw Error("Partnerdaten sind nicht verifiziert"); //("Partner Info is not verified");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let storagePersonResult = await axios.get(
      `${BASE_URL}data/arades_lagerplatz_persons/?$select=arades_lagerplatz_personid,_arades_lagerplatz_value&$filter=_arades_besitzer_value eq ${contact.value[0].contactid} &$expand=arades_lagerplatz($select=arades_vertrag)`,
      config
    );

    let partnerStorages = [];
    for (let i = 0; i < storagePersonResult?.data?.data?.value?.length; i++) {
      let storage = storagePersonResult?.data?.data?.value[i].arades_lagerplatz;
      if (storage) partnerStorages.push(storage);
    }

    if (!BYPASS_ADOBE_SIGN && partnerStorages?.find((x) => !x.arades_vertrag))
      throw Error(
        "Diese Person hat Verträge für Lagerplätze, welche noch nicht unterzeichnet worden sind"
      ); //("this person has storages with unsigned contract");

    return contact;
  }
);

export default getPersonalDataAction;
