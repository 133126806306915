import React from "react";
import {Controller } from 'react-hook-form';
import {Checkbox as MUICheckbox,FormControl, FormControlLabel,FormHelperText} from '@mui/material';

const MUICheckBox = ({
  control=null,
  label,
  error,
  required,
  helperText,
  component,
  onChange,
  onBlur,
  value,
  name,
  inputRef,
  sx,
  ...props
}) => {
  return (
    <>
    {control ? (
      <Controller
      control={control}
      name={name}
      render={({
          field: {onChange:formOnChange, onBlur:formOnBlur, value:formValue, name:formName, ref:fromRef },
          fieldState: { invalid, isTouched, isDirty, error:formError },
          formState,
      }) => (

        <FormControl
        required={required}
        error={Boolean(formError)}
        component={component}
        sx={sx}
      >
        <FormControlLabel 
          control={
          <MUICheckbox 
            sx={{
              '&.Mui-checked': {
              color: 'info.main',
              },
            }}
            onChange={(e) => formOnChange(e.target.checked)}
            onBlur={formOnBlur}
            checked={formValue}
            name={formName}
            inputRef={fromRef}
            {...props} 
          />
          } 
          label={label} 
        />
        {helperText && (
          <FormHelperText sx={{mx:0}}>{helperText}</FormHelperText>
        )} 
      </FormControl>
      )} />

    ):(
    <FormControl
      required={required}
      error={Boolean(error)}
      component={component}
      sx={sx}
    >
      <FormControlLabel 
        control={
        <MUICheckbox 
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
          inputRef={inputRef}
          {...props} 
        />
        } 
        label={label} 
      />
      {helperText && (
        <FormHelperText sx={{mx:0}}>{helperText}</FormHelperText>
      )} 
    </FormControl>
    )}
      
    </>
  );
};

export default MUICheckBox;
