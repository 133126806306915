import React, { useEffect, useState } from "react";
import { FormSteper } from "../../common";

import PersonalInfoStep from "./sharedSteps/PersonalInfo.step";
import StorageType from "./StorageType.step";
import { WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../../core/constants";
import ForMySelfSteps from "./forMySelfSteps/forMySelf.step.manager";
import ShareWithPartnerSteps from "./shareWithPartnerSteps/shareWithPartner.step.manager";
import ShareWithPartnerWith2FASteps from "./shareWithPartnerSteps/shareWithPartnerWith2FA.step.manager";

import { useDispatch } from "react-redux";
import { resetAll } from "../../../redux/slices/storage/storage.slice";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../core/constants";
import { useTranslation } from "react-i18next";
import { BYPASS_ADOBE_SIGN } from "../../../core/apiconfig/environment";

function AddStorageStepManager() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 3;

  const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
  const isContact =
    workspaceType == WORKSPACETYPE.CONTACT ||
    workspaceType == WORKSPACETYPE.UNDER_AGE;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HandleCurrentStep = (step) => {
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(`/${URL.STORAGE}`);
  };

  return (
    <div className="container-card">
      {!BYPASS_ADOBE_SIGN && (
        <FormSteper
          currentStep={currentStep}
          setCurrentStep={HandleCurrentStep}
          noFooter={[1, 2, 3]}
          noHeader={[2, 3]}
          hasCancel={[1]}
          onCancel={handleCancel}
          headerTitles={[
            isContact ? "Ihre Persönliche Daten" : "Firmendaten überprüfen",
            // <div>
            //   &nbsp;Bankverbindung <br></br> &nbsp;für Auszahlung von Erlösen
            // </div>,
            // "Einrichtungsgebühr",
            // "Security",
            "For myself steps",
            "For partner steps",
          ]}
        >
          {/* Step 1- Personal Info */}
          <PersonalInfoStep
            currentStep={currentStep}
            handleCurrentStep={HandleCurrentStep}
          />

          {/* Step 2- BankAccountStep */}
          {/* <BankAccountStep handleCurrentStep={HandleCurrentStep} /> */}

          {/* Step 3- PromotionCode */}
          {/* <StorageFeeStep
          currentStep={currentStep}
          handleCurrentStep={HandleCurrentStep}
        /> */}

          {/* Step 4- Security */}
          {/* <StorageType
          currentStep={currentStep}
          handleCurrentStep={HandleCurrentStep}
        /> */}

          {/* Step 2- For myself steps ----------------------------*/}
          <ForMySelfSteps parent={1} handleCurrentStep={HandleCurrentStep} />

          {/* Step 3- Share with partner steps ----------------------------*/}
          <ShareWithPartnerSteps
            parent={1}
            handleCurrentStep={HandleCurrentStep}
          />
        </FormSteper>
      )}
      {BYPASS_ADOBE_SIGN && (
        <FormSteper
          currentStep={currentStep}
          setCurrentStep={HandleCurrentStep}
          noFooter={[1, 2, 3]}
          noHeader={[2, 3]}
          hasCancel={[1]}
          onCancel={handleCancel}
          headerTitles={[
            isContact ? "Ihre Persönliche Daten" : "Firmendaten überprüfen",
            // <div>
            //   &nbsp;Bankverbindung <br></br> &nbsp;für Auszahlung von Erlösen
            // </div>,
            // "Einrichtungsgebühr",
            // "Security",
            "For myself steps",
            "For partner steps",
          ]}
        >
          {/* Step 1- Personal Info */}
          <PersonalInfoStep
            currentStep={currentStep}
            handleCurrentStep={HandleCurrentStep}
          />

          {/* Step 2- BankAccountStep */}
          {/* <BankAccountStep handleCurrentStep={HandleCurrentStep} /> */}

          {/* Step 3- PromotionCode */}
          {/* <StorageFeeStep
          currentStep={currentStep}
          handleCurrentStep={HandleCurrentStep}
        /> */}

          {/* Step 4- Security */}
          {/* <StorageType
          currentStep={currentStep}
          handleCurrentStep={HandleCurrentStep}
        /> */}

          {/* Step 2- For myself steps ----------------------------*/}
          <ForMySelfSteps parent={1} handleCurrentStep={HandleCurrentStep} />

          {/* Step 3- Share with partner steps ----------------------------*/}
          <ShareWithPartnerWith2FASteps
            parent={1}
            handleCurrentStep={HandleCurrentStep}
          />
        </FormSteper>
      )}
    </div>
  );
}

export default AddStorageStepManager;
