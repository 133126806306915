import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Panel from "./components/panel";
import Home from "./components/home";
import { TOKEN_NAME, STATUS_CODE } from "./core/apiconfig/constantApi";
import { URL, CONTACT_STATUS } from "./core/constants";
import {
  MOBILE_VERIFIED,
  ADDRESS_VERIFIED,
  NECT_VERIFIED,
} from "./core/apiconfig/constantApi";

import { SnackbarProvider, useSnackbar } from "notistack";
import SnackbarUtils from "./core/utils/snackbarUtils";
import {
  BYPASS_NECT,
  IS_UNDER_CONSTRUCTION,
} from "./core/apiconfig/environment";
import UnderConstruction from "./components/home/underConstruction";

function AppRoutes() {
  const isAuthenticated = localStorage.getItem(TOKEN_NAME);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const [registerationDone, setRegisterationDone] = useState(false);

  //  useEffect(() => {
  //   SnackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar);
  // }, []);

  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = async () => {
    setRegisterationDone(registerationIsDone());
    navigateToProperStep();
  };

  const registerationIsDone = () => {
    const firstLogin = Boolean(searchParams.get("first-login"));
    //parichehr remove when nect service is working correctly , it should set ADDRESS_VERIFIED field of contact to true itself
    if (firstLogin) localStorage.setItem(ADDRESS_VERIFIED, "true");

    if (
      BYPASS_NECT &&
      localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.NEED_DOCUMENTS
    )
      return false;
    else
      return (
        isAuthenticated &&
        isAuthenticated != "undefined" &&
        localStorage.getItem(MOBILE_VERIFIED) == "true" &&
        (localStorage.getItem(NECT_VERIFIED) == "true" ||
          localStorage.getItem(ADDRESS_VERIFIED) == "true" ||
          localStorage.getItem(STATUS_CODE) != CONTACT_STATUS.INVITED)
      );
  };

  const navigateToProperStep = () => {
    let mobileVerified = localStorage.getItem(MOBILE_VERIFIED);
    let addressVerified = localStorage.getItem(ADDRESS_VERIFIED);
    let nectVerified = localStorage.getItem(NECT_VERIFIED);
    if (
      BYPASS_NECT &&
      localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.NEED_DOCUMENTS
    )
      navigate(URL.DOCUMENTSVERIFICATION);
    else if (mobileVerified == "false") navigate(URL.MOBILEVERIFICATION);
    else if (
      nectVerified == "false" &&
      addressVerified == "false" &&
      localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.INVITED
    )
      navigate(URL.ADDRESSVERIFICATION);
  };

  return (
    <div id="approutejs">
      <Routes>
        {/* <Route path="/*" element={registerationDone ? <Panel /> : <Home />} /> */}
        <Route
          path="/*"
          element={
            IS_UNDER_CONSTRUCTION ? (
              <UnderConstruction></UnderConstruction>
            ) : registerationDone ? (
              <Panel />
            ) : (
              <Home />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default AppRoutes;
