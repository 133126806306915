import axios from "axios";
import { BASE_URL } from "../../core/apiconfig/environment";
import { GermanFloat, dateString, Currency } from "../../core/utils/convert";
import { TOKEN_NAME } from "../../core/apiconfig/constantApi";
import { QUOTE_DIRECTION } from "../../core/constants";

const getTransactionListFunc = async ({ ownerId }, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  var saleordersFetchQuery = `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="arades_zahlungsverkehr">
    <attribute name="arades_zahlungsverkehrid"/>
    <attribute name="arades_ueberweisungsdatum"/>
    <attribute name="arades_betrag"/>
    <attribute name="arades_lagerplatz"/>
    <order attribute="arades_ueberweisungsdatum" descending="true"/>
      <filter type="and">
        <condition attribute="arades_richtung" operator="eq" value="${QUOTE_DIRECTION.BUY}"/>
        <condition attribute="statuscode" operator="in">
          <value>100000003</value>
          <value>100000000</value>
        </condition>
        <condition attribute="arades_kunde" operator="eq"  value="${ownerId}"/>        
      </filter>
      <link-entity name="msdyn_warehouse" from="msdyn_warehouseid" to="arades_lagerplatz" visible="false" link-type="outer" alias="st">
        <attribute name="msdyn_name"/>
        <attribute name="arades_edelmetall_typ"/>
      </link-entity>
    </entity>
  </fetch>`;

  var encodedFetchXml = encodeURI(saleordersFetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/arades_zahlungsverkehrs?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Zahlungsverkehr nicht geladen werden"); //("transactions not fetched");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((item) => {
    if (item["st.msdyn_name"]) {
      let tmpItem = {
        amount: item.arades_betrag,
        amount_formatted: Currency(item.arades_betrag),
        createdon: item["arades_ueberweisungsdatum"],
        createdon_formatted: dateString(item["arades_ueberweisungsdatum"]),
        storageName: item["st.msdyn_name"],
        metalName:
          item[
            "st.arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"
          ],
      };
      list.push(tmpItem);
    }
  });

  return list;
};

export default getTransactionListFunc;
