import axios from "axios";
import { BASE_URL } from "../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../core/apiconfig/constantApi";
import { Currency } from "../../core/utils/convert";

const getMetalPriceFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let price = 0;
  let fetchQuery = `<fetch top="1" version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="arades_preis">
        <attribute name="arades_preisid"/>
        <attribute name="createdon"/>
        <attribute name="arades_preis"/>
        <attribute name="arades_datum"/>
        <attribute name="arades_edelmetall_typ"/>
        <order attribute="createdon" descending="true"/>
        <link-entity name="product" from="productid" to="arades_edelmetall_typ" link-type="inner" alias="pr">
          <filter type="and">
           <condition attribute="productnumber" operator="eq" value="${filter.productName}"/>
          </filter>
        </link-entity>
      </entity>
    </fetch>`;

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/arades_preises?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  if (result?.data?.data?.value?.length > 0)
    price = result.data.data.value[0].arades_preis;

  if (!price) throw Error("Kein Preis gefunden"); //("No Price Found");

  return {
    product: filter.productName,
    amount: price,
    formattedAmount: Currency(price),
  };
};

export default getMetalPriceFunc;
