import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getActionCodeDataFunc = async (actionCode, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.get(
    `${BASE_URL}data/arades_aktionscodes/?$select=arades_aktionscodeid,statuscode,statecode,arades_aktuelle_anzahl,arades_aktionscode_limitiert,arades_aktionscode_unbegrenzt_gueltig,arades_prozent,arades_enddatum,arades_startdatum,arades_maximum_anzahl,_arades_firma_value,arades_name&$filter=arades_name eq '${actionCode}' `,
    config
  );

  if (result?.data?.status != 200 || result?.data?.data?.value?.length == 0)
    throw Error("Aktionscode wurde nicht gefunden"); //("Action Code not found");

  const today = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  let actionCodeData = result.data.data.value[0];
  actionCodeData.id = result.data.data.value[0].arades_aktionscodeid;

  if (!actionCodeData.arades_prozent) actionCodeData.arades_prozent = 0;

  actionCodeData.isValidDate =
    actionCodeData.arades_aktionscode_unbegrenzt_gueltig ||
    (new Date(actionCodeData.arades_enddatum) >= new Date(today) &&
      new Date(actionCodeData.arades_startdatum) <= new Date(today));

  actionCodeData.isValidUserNum =
    !actionCodeData.arades_aktionscode_limitiert ||
    actionCodeData.arades_aktuelle_anzahl <
      actionCodeData.arades_maximum_anzahl;

  actionCodeData.isActive =
    actionCodeData.statuscode == 1 && actionCodeData.statecode == 0;

  return actionCodeData;
};

export default getActionCodeDataFunc;
