import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import changeContactInfoWorkflowFunc from "./changeContactInfoWorkflow.func";

const changeEmailAddressFunc = async (data, { getState }) => {
  const { contactID, email } = data;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.patch(
    `${BASE_URL}data/contacts(${contactID})/`,
    { emailaddress1: email },
    config
  );

  if (result?.data?.status != 200)
    throw Error("E-Mail Addresse könnte nicht geändert werden"); //("Email Address not changed");

  const changeWorkFlow = await changeContactInfoWorkflowFunc(data.contactID, {
    getState,
  });

  return result.data.data;
};

export default changeEmailAddressFunc;
