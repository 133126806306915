import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";
import { useApi } from "../../core/hooks/useApi";
import { BASE_URL } from "../../core/apiconfig/environment";
import { CONTACT_ID } from "../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";

const EmployeeList = ({ companyId, canAdd, canEdit }) => {
  const [data, setData] = useState([]);
  const { get } = useApi();
  const { t } = useTranslation();

  const loadData = async () => {
    const result = await get(
      `${BASE_URL}data/arades_mitarbeiters/?$select=*&$filter=_arades_firma_value eq '${companyId}'&$expand=arades_person($select=*)`
    );

    if (result.data && result.data.value) {
      let tempdata = [];
      result.data.value.map((l) => {
        let item = l.arades_person;
        if (l.arades_person)
          tempdata.push({
            firstname: item.firstname,
            lastname: item.lastname,
            createdon: item.createdon,
          });
      });
      setData(tempdata);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <DataTable
      title={t("employee-list.title")}
      entityName={"quote"}
      size={"sm"}
      data={data}
      nofetch={true}
      canAdd={canAdd}
      canEdit={canEdit}
      columns={[
        { name: "firstname", caption: t("employee-list.first-name") },
        { name: "lastname", caption: t("employee-list.last-name") },
      ]}
    ></DataTable>
  );
};
export default EmployeeList;
