import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { useSnackbar } from "notistack";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Box, IconButton, Typography } from "@mui/material";
import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import SelectOrAddBankAccount from "../../common/selectOrAddBankAccount";
import "./storageBankAccount.scss";
import { useTranslation } from "react-i18next";

const StorageBankAccount = () => {
  const { patch } = useApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const OTHER_ACCOUNT = "other";
  const storageId = useParams().storageid;
  const bankAccountId = useParams().bankaccountid;
  const [selectedAccount, setSelectedAccount] = useState(OTHER_ACCOUNT);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (bankAccountId) setSelectedAccount(bankAccountId);
  }, []);

  const saveAndReturn = async (accountId) => {
    setLoading(true);
    let updateResult = await updateStorageAccount(accountId);
    if (updateResult) navigate("/" + URL.STORAGE + "/" + storageId);
    setLoading(false);
  };

  const updateStorageAccount = async (accountId) => {
    if (!storageId || !accountId) return;

    let saveObj = {};
    saveObj["arades_referenzkonto@odata.bind"] =
      "/arades_kontos(" + accountId + ")";

    let result = await patch(
      `${BASE_URL}data/msdyn_warehouses(${storageId})`,
      saveObj
    );
    if (result.status == 200 || result.status == 201) {
      enqueueSnackbar("Die Änderungen wurden gespeichert", {
        variant: "success",
        autoHideDuration: 3000,
      });
      return true;
    } else {
      enqueueSnackbar(result.statusText + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      return false;
    }
  };

  return (
    <>
      <LoadingOverlay show={loading}>
        <div className="container-card">
          <Box
            sx={{
              marginBottom: "32px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <IconButton
                sx={{ fontSize: "15px", color: "#000" }}
                aria-label="back"
                onClick={() => navigate("/" + URL.STORAGE + "/" + storageId)}
              >
                <MdArrowBackIosNew />
              </IconButton>
              <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
                {t("bank-account.bank-account")}
              </Typography>
            </Box>
          </Box>
          <SelectOrAddBankAccount
            selectedBankAccount={selectedAccount}
            onBankAccountSelected={saveAndReturn}
            confirmButtonText={t("buttons.confirm")}
          ></SelectOrAddBankAccount>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default StorageBankAccount;
