import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid, {
  SearchPanel,
  Column,
  Export,
  Item,
  Pager,
  Paging,
  Toolbar,
  Selection
} from "devextreme-react/data-grid";
import Button from "../buttons/button";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  URL,
  CELL_RENDER_TYPE,
  ERROR_CONTACT_US,
} from "../../../core/constants";
import "./grid.scss";
import { useSnackbar } from "notistack";
import add from "../../../assets/images/add.svg";
import MetalCell from "./metalCell";
import GridButton from "devextreme-react/button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";

const DataTableLeftEdit = ({
  title,
  entityName,
  columns,
  data,
  defaultPageSize,
  showPageSizeSelector = false,
  size,
  hideTitle,
  canSearch,
  canExport,
  canAdd,
  disableAdd,
  canEdit,
  canDelete,
  canSelect,
  canView,
  nofetch,
  filterKeyVal,
  query,
  onSelect, //for lookup , better to get it done in a wrapper component
  titleFieldName, //for lookup , better to get it done in a wrapper component
  addPath,
  editPath,
  customOperations,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [gridData, setGridData] = useState(null);
  //const [showPayInfo, setShowPayInfo] = useState(true);
  const [showPayInfo, setShowPayInfo] = useState(false);
  const navigate = useNavigate();
  const defPageSize = defaultPageSize ?? (size == "sm" ? 5 : 10);
  const pagesizes = [10, 25, 50, 100, "all"];
  const { get, post, remove } = useApi();

  const dataGridRef = React.createRef();

  const onSelectChanged = function(e){
    setShowPayInfo(dataGridRef.current.instance.getSelectedRowsData().length == 0);
  }

  const exportGrid = React.useCallback(() => {
    const doc = new jsPDF({ orientation: "l" });
    const dataGrid = dataGridRef.current.instance;

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
    }).then(() => {
      doc.save(entityName + "_list_" + new Date().toLocaleString("en") + ".pdf");
    });
  });

  const formatCreateDate = (listData) => {
    let mylist = [];
    listData.map((item) => {
      var myItem = { ...item };
      if (item?.createdon)
        myItem.createdon = item.createdon.toString().substring(0, 10);
      mylist.push(myItem);
    });
    return mylist;
  };

  const sortList = (listData) =>
    listData.sort((a, b) => (a[columns[0].name] > b[columns[0].name] ? 1 : -1));

  const selectQuery = () => {
    let selQuery = "?$select=" + entityName + "id";
    columns.map((item) => {
      selQuery += "," + item.name;
    });
    return selQuery;
  };

  const filterQuery = () => {
    let filterQuery = "";
    if (filterKeyVal)
      filterQuery =
        "&$filter=contains(" +
        filterKeyVal.key +
        "," +
        filterKeyVal.value +
        ")";
    return filterQuery;
  };

  const orderQuery = () => {
    return "&$orderby=createdon desc";
  };

  const loadData = async () => {
    let fetchQuery =
      query ||
      `${BASE_URL}data/${entityName}s/${selectQuery()}${orderQuery()}${filterQuery()}`;
    const result = await get(fetchQuery);

    if (result.data && result.data.value) {
      let listData = formatCreateDate(result.data.value);
      sortList(listData);
      setGridData(listData);
    }
  };

  useEffect(() => {
    if (!nofetch) loadData();
    else if (data) {
      let listData = formatCreateDate(data);
      setGridData(listData);
    }
  }, [data]);

  const operationCellRender = (row) => {
    return (
      <>
        <div className="d-flex justify-content-start ">
          {canView && (
            <div
              onClick={() => {
                navigate(row.data[entityName + "id"].toString());
              }}
            >
              <span className="icon">{">"}</span>
            </div>
          )}
          {canEdit && (
            <div
              onClick={() => {
                let rowId = row.data[entityName + "id"];
                navigate(editPath ? editPath + "/" + rowId : rowId);
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} />
              </span>
            </div>
          )}
          {canDelete && (
            <div
              onClick={async () => {
                const result = await remove(
                  `${BASE_URL}data/${entityName}s(${
                    row.data[entityName + "id"]
                  })`
                );
                if (result.status == 204) {
                  enqueueSnackbar(t("messages.success"), {
                    variant: "success",
                    autoHideDuration: 3000,
                  });
                  const tempdata = gridData.filter(
                    (item) =>
                      item[entityName + "id"] != row.data[entityName + "id"]
                  );
                  setGridData(tempdata);
                } else if (result.status == 405)
                  enqueueSnackbar(
                    result.data.error.message + ERROR_CONTACT_US,
                    {
                      variant: "error",
                      autoHideDuration: 20000,
                    }
                  );
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
          )}
          {canSelect && (
            <div
              onClick={async () => {
                onSelect({
                  id: row.data[entityName + "id"],
                  title: row.data[titleFieldName],
                });
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
          )}
          {customOperations?.map((btnOperation, index) => {
            let showMe = true;
            for (let i = 0; i < btnOperation.showConditions?.length; i++) {
              let condition = btnOperation.showConditions[i];
              showMe =
                showMe && row.data[condition.fieldName] == condition.fieldValue;
            }

            if (showMe) {
              return (
                <Button
                  key={"b" + index}
                  className={"no-border btn-small"}
                  style={{ paddingTop: 10, marginRight: 10 }}
                  onClick={() => {
                    btnOperation.onClick(row.data);
                  }}
                >
                  <span key={"s" + index} className="add-span">
                    {btnOperation.title}
                  </span>
                </Button>
              );
            } else {
              return null;
            }
          })}
        </div>
      </>
    );
  };

  const customizedCellRender = (col, row) => {
    if (col.cellRenderType == CELL_RENDER_TYPE.METAL_CELL){
      //if(!row.data.arades_uebergeordneter_lagerplatz){
        return (
          <MetalCell
            metal={row.data.metalName}
            disabled={data.disabled}
          ></MetalCell>
        );
      //}else{
      //}
    } else if (col.cellRenderType == CELL_RENDER_TYPE.LINK)
      return (
        <Link onClick={() => col.onClick(row.data, col)}>
          {col.name && (
            <Typography sx={{ fontSize: "14px" }}>
              {row.data[col.name]}
            </Typography>
          )}
          {col.names &&
            col.names.map((myname) => {
              return (
                <Typography sx={{ fontSize: "14px" }}>
                  {row.data[myname]}&nbsp;
                </Typography>
              );
            })}
        </Link>
      );
    else return null;
  };

  function renderLabel() {
    return <div className="small-title">{title}</div>;
  }

  const renderColumn = () => {
    return columns.map((col) => {
      if (col.cellRenderType)
        return (
          <Column
            key={col.name}
            dataField={col.name}
            caption={col.caption}
            dataType={"string"}
            alignment={col.alignment || "left"}
            minWidth={col.width}
            cellRender={(row) => customizedCellRender(col, row)}
          />
        );
      else
        return (
          <Column
            key={col.name}
            dataField={col.name}
            caption={col.caption}
            dataType={"string"}
            minWidth={col.width}
            alignment={col.alignment || "left"}
            onCellPrepared={() => {
              alert("1");
            }}
          />
        );
    });
  };

  
  return (
    <div>
      {!hideTitle && (
        <div className="grid-row">
          {!canExport && (
            <h1 className={` ${size == "sm" ? "small-title" : "title"}`}>
              {title}
            </h1>
          )}
          {canAdd && (
            <Button
              disabled={disableAdd}
              className={`${size == "sm" ? "no-border btn-small" : "primary"}`}
              onClick={() => {
                navigate(addPath ? addPath : "add");
              }}
            >
              <span className="add-span">
                <span style={{ fontSize: 18 }}>{"+"}</span>&nbsp;&nbsp;
                {t("buttons.create-storage")}
              </span>
            </Button>
          )}
          {canAdd &&(
            <Button
              disabled={showPayInfo}
              className={`${size == "sm" ? "no-border btn-small" : "primary"}`}
              onClick={async () => {
                //var selected = dataGridRef.current.instance.getSelectedRowsData();
                var selected = gridData;
                var warehouses = [];
                for (let i = 0; i < selected.length; i += 1) {
                  //console.log(selected[i].msdyn_name);
                  //console.log(selected[i]);

                  
                  if((selected[i].msdyn_name).includes("-AU-00")){
                    warehouses.push(selected[i].msdyn_warehouseid)
                  }

                }
                console.log(warehouses);
                let fetchQuery = `${BASE_URL}powerplatform/paymentInfo`;
                let data = {warehouses: warehouses};
                const result = await post(fetchQuery, data);

                //console.log(result);
                //alert(result.statusText);
                //todo show Popup

                setShowPayInfo(true);
                dataGridRef.current.instance.deselectAll();
              }}
            >
              <span className="add-span">
                <span style={{ fontSize: 18 }}>{""}</span>&nbsp;&nbsp;
                {t("buttons.payInfo")}
              </span>
            </Button>
          )}
        </div>
      )}
      <div className={`grid-row ${size === "sm" && !canExport ? "" : "mt-4"} `}>
        <DataGrid
          ref={dataGridRef}
          columnAutoWidth={true}
          dataSource={gridData}
          allowColumnReordering={true}
          showBorders={true}
          width={"100%"}
          height={size === "sm" ? 340 : null}
          allowColumnResizing={true}
          showRowLines={true}
          showColumnLines={false}
          //selection={{ mode: "multiple"}}
          columnResizingMode={"widget"}
          onSelectionChanged={onSelectChanged}
        >
          <Pager
            allowedPageSizes={pagesizes}
            showPageSizeSelector={showPageSizeSelector}
          />
          <Paging defaultPageSize={defPageSize} />
          {canSearch && (
            <SearchPanel
              visible={true}
              placeholder={t("buttons.search-here")}
            />
          )}
          {canExport && (
            //Excell
            // <Export
            //   enabled={true}
            //   fileName={entityName + "List" + Date.toLocaleString()}
            // />
            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                render={renderLabel}
              />
              <Item location="after">
                <GridButton
                  icon="exportpdf"
                  text={t("buttons.export-pdf")}
                  onClick={exportGrid}
                />
              </Item>
            </Toolbar>
          )}
          {(canEdit || canDelete || canView || customOperations) && (
            <Column
              alignment="left"
              caption={" "}
              type="buttons"
              width={customOperations ? 140 : 100}
              cellRender={operationCellRender}
            />
          )}
          {renderColumn()}
        </DataGrid>
      </div>
    </div>
  );
};
export default DataTableLeftEdit;
