import React, { useState, useEffect } from "react";
import { NECT_URL } from "../../../core/apiconfig/environment";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL, BASE_URL_UI } from "../../../core/apiconfig/environment";
import { useSnackbar } from "notistack";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";
import "./nectRedirect.scss";
import { ERROR_CONTACT_US } from "../../../core/constants";

const NectRedirect = ({ title, classNames, redirectUrl, style }) => {
  const { post } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [caseUUID, setCaseUUID] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    nectStart();
  }, []);

  const nectStart = async () => {
    setLoading(true);
    const result = await post(`${BASE_URL}auth/nect/start`, {
      redirectUrl: redirectUrl,
    });
    setLoading(false);
    if (result.status != 200) {
      enqueueSnackbar(result.statusText + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      return false;
    } else {
      setCaseUUID(result.data.caseUUID);
      return true;
    }
  };

  return (
    <LoadingOverlay show={loading}>
      <form action={NECT_URL} method="get" target="_top">
        <input type="hidden" name="case_uuid" value={caseUUID} />
        <input
          disabled={!caseUUID}
          type="submit"
          // src="https://cms.nect.com/assets/nect_button_alt_1.png"
          value={title}
          name="submit"
          alt="Einfach und schnell identifiziert mit Nect."
          style={
            !caseUUID
              ? { ...style, border: "none", backgroundColor: "lightGrey" }
              : { ...style, border: "none" }
          }
          className={`cta-btn blue ${classNames} ${caseUUID ? "" : "disabled"}`}
        />
      </form>
    </LoadingOverlay>
  );
};
export default NectRedirect;
