import axios from "axios";
import { BASE_URL } from "../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../core/apiconfig/constantApi";

const getOptionSetFunc = async (filters, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let dictionary = [];
  const result = await axios.get(
    `${BASE_URL}data/EntityDefinitions(LogicalName='${filters.ownerTable}')/Attributes(LogicalName='${filters.optionFieldName}')/Microsoft.Dynamics.CRM.PicklistAttributeMetadata?$select=LogicalName&$expand=OptionSet($select=Options)`,
    config
  );

  if (result.data?.data?.OptionSet?.Options) {
    result.data.data.OptionSet.Options.map((item) => {
      if (item.Value && item.Label && item.Label.LocalizedLabels)
        dictionary.push({
          key: item.Value,
          value: item.Label.LocalizedLabels[0].Label,
        });
    });
  }

  return dictionary;
};

export default getOptionSetFunc;
