import axios from "axios";
import {
  BASE_URL,
  BYPASS_ADOBE_SIGN,
  EMAIL_IMPERSONATED_USER,
} from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { WORKFLOW_IDS } from "../../../core/constants";

const signSellerContractWorkFlowFunc = async ({ ownerId }, { getState }) => {
  let headers = !BYPASS_ADOBE_SIGN
    ? {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
        MSCRMCallerID: EMAIL_IMPERSONATED_USER, // FIXME this should be handled in backend
      }
    : {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      };

  const config = {
    headers: headers,
  };

  const reuslt = await axios.post(
    `${BASE_URL}data/workflows(${
      BYPASS_ADOBE_SIGN
        ? WORKFLOW_IDS.SIGN_SELLER_CONTRACT_BYPASS
        : WORKFLOW_IDS.SIGN_SELLER_CONTRACT
    })/Microsoft.Dynamics.CRM.ExecuteWorkflow`,
    { EntityId: ownerId },
    config
  );

  if (
    !reuslt?.data?.status ||
    (reuslt.data.status != 204 && reuslt.data.status != 200)
  )
    throw Error("Vertragsunterzeichnungsprozess fehlgeschlagen"); //("Sign seller contract workflow failed ");
};

export default signSellerContractWorkFlowFunc;
