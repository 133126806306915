import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  TOKEN_NAME,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import {
  WORKSPACETYPE,
  QUOTE_DIRECTION,
  QUOTE_TYPE,
  METAL_CHARS,
} from "../../../core/constants";
import { MetalPart } from "../../../core/utils/business";

const postSellInfoAction = createAsyncThunk(
  "sell/postSellInfo",
  async (dataParams, { getState }) => {
    const standardPriceLevelId =
      getState().globals.ratiorData.standardPriceLevelId;
    const ratiorAccountId = getState().globals.ratiorData.ratiorInfo.accountid;
    const ratiorGoldAccountId =
      getState().globals.ratiorData.ratiorGoldAccountId;
    const storageBankAccountData = getState().sell.storageBankAccount;
    const fromStorage = getState().sell.sellSource.fromStorage;
    //const ratiorStorages = getState().sell.globalData.ratiorStorages;
    const enterAmountData = getState().sell.enterAmount;
    const enterDescriptionData = getState().sell.enterDescription;
    const ratiorSettingData = getState().globals.ratiorData;

    if (!fromStorage.arades_Edelmetall_Typ?._defaultuomid_value)
      throw Error("Es wurde keine Gewichtseinheit zum Produkt angegeben"); //("There is no Weight unit set for product");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const workspaceId = localStorage.getItem(WORKSPACE_ID);
    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);

    let quoteObj = {
      // parichehr >>  bank account koja zakhire mishe?! na too quote hast na quotedetail
      description: enterDescriptionData.description,
      arades_typ: QUOTE_TYPE.CUSTOMER,
      arades_richtung: QUOTE_DIRECTION.SELL,
      name: "Edelmetallverkauf", //"Sell",
    };

    let ratiorSameMetalstorageId = null;
    let filteredRatiorStorage = ratiorSettingData.ratiorStorages.filter(
      (x) => x.metal == fromStorage._arades_edelmetall_typ_value
    );

    if (filteredRatiorStorage.length > 0)
      ratiorSameMetalstorageId = filteredRatiorStorage[0].id;
    else
      throw Error(
        "Lagerplatz mit gleichem Metalltyp könnte nicht gefunden werden"
      ); //("There is no storage for Ratior with same product");

    quoteObj["arades_lagerplatz_verkaeufer@odata.bind"] =
      "/msdyn_warehouses(" + fromStorage.msdyn_warehouseid + ")";

    quoteObj["arades_lagerplatz_kaeufer@odata.bind"] =
      "/msdyn_warehouses(" + ratiorSameMetalstorageId + ")";

    quoteObj["customerid_account@odata.bind"] =
      "/accounts(" +
      (MetalPart(fromStorage.msdyn_name) == METAL_CHARS.GOLD
        ? ratiorGoldAccountId
        : ratiorAccountId) +
      ")";

    quoteObj["arades_konto@odata.bind"] =
      "/arades_kontos(" + storageBankAccountData.selectedBankAccount + ")";

    if (
      workspaceType == WORKSPACETYPE.CONTACT ||
      workspaceType == WORKSPACETYPE.UNDER_AGE
    )
      quoteObj["arades_verkaeufer_contact@odata.bind"] =
        "/contacts(" + workspaceId + ")";
    else
      quoteObj["arades_verkaeufer_account@odata.bind"] =
        "/accounts(" + workspaceId + ")";

    quoteObj["pricelevelid@odata.bind"] =
      "/pricelevels(" +
      (fromStorage._arades_gebuehrentabelle_value
        ? fromStorage._arades_gebuehrentabelle_value
        : standardPriceLevelId) +
      ")";

    const quoteResult = await axios.post(
      `${BASE_URL}data/quotes/`,
      quoteObj,
      config
    );

    if (quoteResult?.data?.status != 200 && quoteResult?.data?.status != 201)
      throw Error("Angebot wurde nicht gespeichert"); //("Quote not saved");

    if (quoteResult?.data?.data) {
      let quoteId = quoteResult.data.data.quoteid;
      let quoteProductObj = {
        quantity: enterAmountData.amountPerGram * 1,
      };

      quoteProductObj["quoteid@odata.bind"] = "/quotes(" + quoteId + ")";
      // quoteProductObj["uomid@odata.bind"] =
      //   "/uoms(" + fromStorage._arades_gewichtseinheit_value + ")";
      quoteProductObj["uomid@odata.bind"] =
        "/uoms(" + fromStorage.arades_Edelmetall_Typ._defaultuomid_value + ")";
      quoteProductObj["productid@odata.bind"] =
        "/products(" + fromStorage._arades_edelmetall_typ_value + ")";

      const quoteDetailResult = await axios.post(
        `${BASE_URL}data/quotedetails/`,
        quoteProductObj,
        config
      );

      if (
        quoteDetailResult?.data?.status != 200 &&
        quoteDetailResult?.data?.status != 201
      )
        throw Error("Angebotdetails wurden nicht gespeichert");
      //("Quote detail not saved");
      else {
        const productFieldResult = await axios.patch(
          `${BASE_URL}data/quotes(${quoteId})/`,
          {
            arades_produktanlage_abgeschlossen: true,
            arades_weiterleiten: true,
          },
          config
        );

        if (
          productFieldResult?.data?.status != 200 &&
          productFieldResult?.data?.status != 201
        )
          throw Error("Produktfeld in Angebot wurde nicht aktualisiert"); //("product field not updated in quote");
      }
    }
  }
);

export default postSellInfoAction;
