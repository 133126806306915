import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Input from "../common/inputs/input";
import { FormSteperStructure } from "../common";
import { Datepicker } from "../common";
import { useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ERROR_CONTACT_US } from "../../core/constants";
import {
  getPersonalDataAction,
  verificationSendSMSCodeAction,
} from "../../redux/slices/underage";
import { useDispatch, useSelector } from "react-redux";
import {
  setLegalGuardianStatus,
  legalGuardianStatusFields,
  underAgeFullState,
  resetError,
} from "../../redux/slices/underage/underage.slice";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function OtherLegalGuardianStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const requiredMsg = "validation.required";
  const fields = useSelector(legalGuardianStatusFields);
  const underageFullData = useSelector(underAgeFullState);

  useEffect(() => {
    if (fields.partnerPhoneNumber && !underageFullData.error) {
      dispatch(
        verificationSendSMSCodeAction({
          mobileNumber: fields.partnerPhoneNumber,
        })
      );
      handleCurrentStep("next");
    } else if (underageFullData.error != null) {
      enqueueSnackbar(underageFullData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [fields.partnerPhoneNumber, underageFullData.error]);

  const submitForm = (data) => {
    dispatch(setLegalGuardianStatus(data));

    const filter = {
      ratiorKey: data.partnerKey,
      birthdate: data.partnerBirthDate,
    };
    dispatch(getPersonalDataAction(filter));
  };

  const schema = yup.object().shape({
    legalStatus: yup.string().required(requiredMsg),
    partnerKey: yup.string().when("legalStatus", {
      is: "partner",
      then: yup.string().required(requiredMsg),
      otherwise: yup.string(),
    }),
    partnerBirthDate: yup.string().when("legalStatus", {
      is: "partner",
      then: yup
        .string()
        .required(requiredMsg)
        .test("DOB", t("validation.age-above-18"), (value) => {
          return moment().diff(moment(value), "years") >= 18;
        })
        .nullable(),
      otherwise: yup.string(),
    }),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
      agreed: false,
    },
    resolver: yupResolver(schema),
  });

  return (
    <Box>
      <Typography
        mb="50px"
        as="div"
        sx={{
          fontSize: "16px",
        }}
      >
        Bitte geben Sie den RatiorKey und das Geburtsdatum der Person an, mit
        der Sie gemeinsam erziehungsberechtigt für den Minderjährigen sind (im
        Folgenden „Erziehungsberechtigter 2“ genannt):
      </Typography>

      <Box sx={{ maxWidth: "50%", margin: "0 auto" }}>
        <Grid spacing={8} container direction="column">
          <Grid xs={12} lg={6} item>
            <Input
              control={control}
              error={Boolean(errors.partnerKey)}
              helperText={t(errors.partnerKey?.message)}
              name="partnerKey"
              label={`${t("underage.ratior-key-of-other-guardian")}*`}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <Datepicker
              label={`${t("underage.birthdate-of-the-other-guardian")}*`}
              control={control}
              name="partnerBirthDate"
              maxDate={Date.now()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.partnerBirthDate)}
                  helperText={t(errors.partnerBirthDate?.message)}
                  onBlur={() => {
                    if (getValues("partnerBirthDate") === "") {
                      setError("partnerBirthDate", {
                        type: "manual",
                        message: requiredMsg,
                      });
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <FormSteperStructure.Footer>
        <Button
          onClick={handleSubmit(submitForm)}
          fullWidth
          sx={{
            backgroundColor: "info.main",
            maxWidth: "192px",
            marginTop: "30px",
          }}
          variant="contained"
        >
          {t("buttons.next")}
        </Button>
      </FormSteperStructure.Footer>
    </Box>
  );
}

export default OtherLegalGuardianStep;
