export const screenIsDesktop = () => {
  var agents = ["android", "webos", "iphone", "ipad", "blackberry"];
  for (let i = 0; i < agents.length; i++) {
    if (navigator?.userAgent?.toLowerCase().match(agents[i])) {
      console.log("screenIsDesktop:false");
      return false;
    }
  }
  console.log("screenIsDesktop:true");
  return true;
};
