import { createAsyncThunk } from "@reduxjs/toolkit";
import { postCustomerActionCodeFunc } from "../../sharedFunctions";

const postCustomerActionCodeAction = createAsyncThunk(
  "customerList/postCustomerActionCode",
  async (params, { getState }) => {
    let saveObj = {
      statecode: 0,
      ["arades_aktionscode@odata.bind"]: `/arades_aktionscodes(${params.actionCodeId})`,
    };

    if (params.contactId)
      saveObj[
        "arades_kunde_contact@odata.bind"
      ] = `/contacts(${params.contactId})`;
    else if (params.accountId)
      saveObj[
        "arades_kunde_account@odata.bind"
      ] = `/accounts(${params.accountId})`;

    await postCustomerActionCodeFunc(saveObj, { getState });

    return true;
  }
);

export default postCustomerActionCodeAction;
