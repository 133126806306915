import { createSlice } from "@reduxjs/toolkit";
import { METAL_CHARS } from "../../../core/constants";
import {
  getVermittlerAction,
  getTransactionListAction,
  getCustomerStorageListAction,
  getCompanyDataAction,
  getCustomerActionCodeListAction,
  postCustomerActionCodeByNameAction,
  getInvoiceTransferDataAction,
  getInvoiceTotalDataAction,
} from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  companyData: {},
  storageList: [],
  transactionList: [],
  vermittler: {},
  investList: {
    gold: { amount: 0, price: 0, percent: 0, abbreviation: METAL_CHARS.GOLD },
    silver: {
      amount: 0,
      price: 0,
      percent: 0,
      abbreviation: METAL_CHARS.SILVER,
    },
    platin: {
      amount: 0,
      price: 0,
      percent: 0,
      abbreviation: METAL_CHARS.PLATIN,
    },
    palladium: {
      amount: 0,
      price: 0,
      percent: 0,
      abbreviation: METAL_CHARS.PALLADIUM,
    },
  },
  actionCodeList: [],
  invoiceBox: { transferSum: 0, totalSum: 0 },
  metalPrices: {
    goldPrice: 0,
    silverPrice: 0,
    platinPrice: 0,
    PaladiumPrice: 0,
  },
};
export const clientDashboardSlice = createSlice({
  name: "clientDashboard",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //Get vermittler transaction List Action
    [getCompanyDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCompanyDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "getCompanyData";
      state.companyData = action.payload;
      state.globalData.actionStatus = true;
    },
    [getCompanyDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // Get Vermittler Action
    [getVermittlerAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getVermittlerAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;

      const {
        accountid,
        name,
        emailaddress1,
        address1_city,
        address1_line1,
        address1_postalcode,
        address1_line2,
        _arades_land_value,
        address1_latitude,
        address1_longitude,
        telephone1,
        arades_firmenname_partner,
        telephone3,
        arades_mobil_partner,
        emailaddress3,
        arades_address3_line1,
        arades_address3_line2,
        arades_address3_postalcode,
        arades_address3_city,
        arades_address3_latitude,
        arades_address3_longitude,
        _arades_land_partner_value,
      } = action.payload;

      state.vermittler.accountid = accountid;
      state.vermittler.name = arades_firmenname_partner ?? name;
      state.vermittler.email = emailaddress3 ?? emailaddress1;
      state.vermittler.phoneNumber = telephone3 ?? telephone1;
      state.vermittler.latitude = arades_address3_line1
        ? arades_address3_latitude
        : address1_latitude;
      state.vermittler.longitude = arades_address3_line1
        ? arades_address3_longitude
        : address1_longitude;
      state.vermittler.landTitle = _arades_land_partner_value
        ? action.payload[
            "_arades_land_partner_value@OData.Community.Display.V1.FormattedValue"
          ]
        : action.payload[
            "_arades_land_value@OData.Community.Display.V1.FormattedValue"
          ];
      state.vermittler.addressLine1 = arades_address3_line1 ?? address1_line1;
      state.vermittler.postalcode =
        arades_address3_postalcode ?? address1_postalcode;
      state.vermittler.city = arades_address3_city ?? address1_city;
      state.vermittler.addressLine2 = arades_address3_line2 ?? address1_line2;
    },
    [getVermittlerAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // Get Transaction List Action
    [getTransactionListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getTransactionListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.transactionList = action.payload.slice(0, 5);
    },
    [getTransactionListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // Get customer Invoice Transfer sum Action
    [getInvoiceTransferDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getInvoiceTransferDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.invoiceBox.transferSum = action.payload;
    },
    [getInvoiceTransferDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // Get customer Invoice Total sum Action
    [getInvoiceTotalDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getInvoiceTotalDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.invoiceBox.totalSum = action.payload;
    },
    [getInvoiceTotalDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // Get customer Storage List Action
    [getCustomerStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCustomerStorageListAction.fulfilled]: (state, action) => {
      state.globalData.actionType = "storageDetailList";
      state.globalData.actionStatus = true;
      state.globalData.loading = false;
      state.storageList = action.payload;

      state.investList = {
        gold: { amount: 0, price: 0, abbreviation: METAL_CHARS.GOLD },
        silver: { amount: 0, price: 0, abbreviation: METAL_CHARS.SILVER },
        platin: { amount: 0, price: 0, abbreviation: METAL_CHARS.PLATIN },
        palladium: { amount: 0, price: 0, abbreviation: METAL_CHARS.PALLADIUM },
      };

      state.storageList.map((storage) => {
        if (storage.metalName.toLowerCase().indexOf("gold") >= 0) {
          {
            state.investList.gold.price += storage.price;
            state.investList.gold.amount += storage.arades_verfuegbare_menge;
          }
        } else if (storage.metalName.toLowerCase().indexOf("silber") >= 0) {
          state.investList.silver.price += storage.price;
          state.investList.silver.amount += storage.arades_verfuegbare_menge;
        } else if (storage.metalName.toLowerCase().indexOf("platin") >= 0) {
          state.investList.platin.price += storage.price;
          state.investList.platin.amount += storage.arades_verfuegbare_menge;
        } else if (storage.metalName.toLowerCase().indexOf("palladium") >= 0) {
          state.investList.palladium.price += storage.price;
          state.investList.palladium.amount += storage.arades_verfuegbare_menge;
        }
      });

      let totalPrice =
        state.investList.gold.price +
        state.investList.silver.price +
        state.investList.platin.price +
        state.investList.palladium.price;

      state.investList.gold.percent = (
        (state.investList.gold.price / (totalPrice ?? 1)) *
        100
      ).toFixed(2);
      state.investList.silver.percent = (
        (state.investList.silver.price / (totalPrice ?? 1)) *
        100
      ).toFixed(2);
      state.investList.platin.percent = (
        (state.investList.platin.price / (totalPrice ?? 1)) *
        100
      ).toFixed(2);
      state.investList.palladium.percent = (
        (state.investList.palladium.price / (totalPrice ?? 1)) *
        100
      ).toFixed(2);
    },
    [getCustomerStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Get Action Code List Action
    [getCustomerActionCodeListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCustomerActionCodeListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "getCustomerActionCodes";
      state.globalData.actionStatus = true;
      state.actionCodeList = action.payload;
    },
    [getCustomerActionCodeListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //post Customer ActionCode By Name Action
    [postCustomerActionCodeByNameAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postCustomerActionCodeByNameAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "postCustomerActionCode";
      state.globalData.actionStatus = true;
      state.actionCodeList = action.payload;
    },
    [postCustomerActionCodeByNameAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const { resetError, resetActionStatus, resetAll } =
  clientDashboardSlice.actions;

export const fullData = (state) => state.clientDashboard;
export const globalData = (state) => state.clientDashboard.globalData;
export const companyData = (state) => state.clientDashboard.companyData;
export const storageList = (state) => state.clientDashboard.storageList;
export const transactionList = (state) => state.clientDashboard.transactionList;
export const vermittler = (state) => state.clientDashboard.vermittler;
export const investList = (state) => state.clientDashboard.investList;
export const invoiceBoxData = (state) => state.clientDashboard.invoiceBox;
export const actionCodeList = (state) => state.clientDashboard.actionCodeList;

export default clientDashboardSlice.reducer;
