import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { WORKSPACETYPE } from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getContractListFunc = async (params, { getState }) => {
  const { ownerId, ownerType } = params;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let fetchQuery = `${BASE_URL}data/adobe_agreements/?$select=adobe_name,adobe_datesent,adobe_datesigned,adobe_esagreementid,&$filter=${
    ownerType == WORKSPACETYPE.CONTACT || ownerType == WORKSPACETYPE.UNDER_AGE
      ? "_adobe_parentcontactid_value"
      : "_adobe_parentaccountid_value"
  } eq ${ownerId}`;

  const result = await axios.get(fetchQuery, config);

  if (!result?.data?.data?.value || result.status != 200)
    throw Error("Auftragsliste konnte nicht geladen werden"); //("contract list not fetched");

  let list = [];
  result.data.data.value.map((item) => {
    let tmpItem = item;
    tmpItem.downloadLink = `${BASE_URL}download/adobe/agreement?id=${item.adobe_esagreementid}`;
    console.log(tmpItem.downloadLink);
    list.push(tmpItem);
  });
  return list;
};
export default getContractListFunc;
