import { createAsyncThunk } from "@reduxjs/toolkit";
import { postCustomerActionCodeFunc } from "../../sharedFunctions";
import { getActionCodeDataFunc } from "../../sharedFunctions";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const postCustomerActionCodeByNameAction = createAsyncThunk(
  "customerList/postCustomerActionCode",
  async (params, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let actionCodeData = await getActionCodeDataFunc(params.actionCode, {
      getState,
    });
    if (!actionCodeData) throw Error("Aktionscode wurde nicht gefunden"); //("Action code not found");
    if (!actionCodeData.isValidDate) throw Error("Aktionscode ist abgelaufen"); //("Action code expired");
    if (!actionCodeData.isValidUserNum)
      throw Error("Maximale Anzahl Aktionscode Nutzer erreicht"); //("Action code reached max register count");
    if (!actionCodeData.isActive) throw Error("Aktionscode ist nicht aktiv"); //("Action Code not active");

    let customerFilter = params.contactId
      ? `_arades_kunde_value eq ${params.contactId}`
      : `_arades_kunde_value eq ${params.accountId}`;
    let result = await axios.get(
      `${BASE_URL}data/arades_aktionscode_nutzers?$select=statecode&$filter=_arades_aktionscode_value eq ${actionCodeData.id} and (${customerFilter}) `,
      config
    );

    if (result?.data?.status != 200)
      throw Error("Action Code user not fetched");
    else if (result?.data?.data?.value?.length > 0)
      throw Error("Aktionscode wurde Ihnen bereits zugewiesen"); //("This Action Code is already assigned to the customer");

    let saveObj = {
      statecode: 0,
      ["arades_aktionscode@odata.bind"]: `/arades_aktionscodes(${actionCodeData.id})`,
    };

    if (params.contactId)
      saveObj[
        "arades_kunde_contact@odata.bind"
      ] = `/contacts(${params.contactId})`;
    else if (params.accountId)
      saveObj[
        "arades_kunde_account@odata.bind"
      ] = `/accounts(${params.accountId})`;

    await postCustomerActionCodeFunc(saveObj, { getState });

    return true;
  }
);

export default postCustomerActionCodeByNameAction;
