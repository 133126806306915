import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const cancelSellAction = createAsyncThunk(
  "sell/postSellInfo",
  async ({ quoteid }, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let result = await axios.patch(
      `${BASE_URL}data/quotes(${quoteid})`,
      {
        arades_angebot_annahme: "100000002",
        statecode: 3,
      },
      config
    );

    if (result?.data?.status != 200 && result?.data?.status != 201)
      throw Error("Angebot wurde nicht gespeichert"); //("Quote not saved");
  }
);

export default cancelSellAction;
