import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getTitleListAction = createAsyncThunk(
  "global/titles",
  async (dataParams, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const result = await axios.get(
      `${BASE_URL}data/arades_titels/?$select=arades_name,arades_titelid`,
      config
    );

    if (!result?.data?.status == 200 || !result?.data?.data?.value?.length > 0)
      throw Error("Anrede könnte nicht geladen werden"); //("Titles not fetched");

    let list = [];
    result.data.data.value.map((item) => {
      list.push({
        key: item.arades_titelid,
        value: item.arades_name,
      });
    });
    return list;
  }
);

export default getTitleListAction;
