import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";
import { useApi } from "../../core/hooks/useApi";
import { BASE_URL } from "../../core/apiconfig/environment";
import { CONTACT_ID } from "../../core/apiconfig/constantApi";

const MandateList = () => {
  const [data, setData] = useState([]);
  const { get } = useApi();

  const loadData = async () => {
    const result = await get(
      `${BASE_URL}data/arades_vollmachts/?$select=*&$filter=_arades_bevollmaechtigte_person_value eq '${localStorage.getItem(
        CONTACT_ID
      )}'`
    );
    if (result.data && result.data.value) setData(result.data.value);
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="container-card">
      <DataTable
        title={"Vollmachten"}
        entityName={"arades_vollmacht"}
        data={data}
        nofetch={true}
        columns={[
          { name: "arades_name", caption: "Name" },
          { name: "createdon", caption: "Erstellt am" },
        ]}
      ></DataTable>
    </div>
  );
};
export default MandateList;
