import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getUpcomingBirthdaysAction = createAsyncThunk(
  "vermittlerDashboard/getUpcomingBirthdays",
  async (vermittlerId, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const today = new Date().toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const result = await axios.get(
      `${BASE_URL}data/contacts?$select=contactid,firstname,lastname,birthdate&$top=4&$filter=_arades_vermittler_value eq ${vermittlerId} `,
      config
    );

    if (result.data.status != 200)
      throw Error("Geburtsdatum könnte nicht geladen werden"); //("could not fetch birthdates");

    let list = [];
    let birthDate;
    let todayDate = new Date(today);
    result.data.data.value.map((item) => {
      birthDate = new Date(item.birthdate);
      if (
        birthDate.getMonth() == todayDate.getMonth() &&
        birthDate.getDay() >= todayDate.getDay()
      )
        list.push(item);
    });

    return list.sort((a, b) => (a?.birthdate >= b?.birthdate ? -1 : 1));

    // var now = moment("2015-02-10");
    // var birthDates = [];

    // bdates.forEach(function (birthDate) {
    //   var birthDay = moment(birthDate).year(now.year());
    //   var birthDayNextYear = moment(birthDate).year(now.year() + 1);
    //   var daysRemaining = Math.min(
    //     Math.abs(birthDay.diff(now, "days")),
    //     Math.abs(birthDayNextYear.diff(now, "days"))
    //   );

    //   if (daysRemaining >= 0 && daysRemaining <= 7) {
    //     birthDates.push(birthDate);
    //   }
    // });
  }
);

export default getUpcomingBirthdaysAction;
