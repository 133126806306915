import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getCountryListAction = createAsyncThunk(
  "global/countries",
  async (dataParams, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };
    const result = await axios.get(
      `${BASE_URL}data/arades_lands/?$select=arades_name,arades_landid&$orderby=arades_favorit desc`,
      config
    );

    if (!result?.data?.status == 200 || !result?.data?.data?.value?.length > 0)
      throw Error("Länderliste könnte nicht geladen werden"); //("country List not fetched");

    let list = [];
    result.data.data.value.map((item) => {
      list.push({ key: item.arades_landid, value: item.arades_name });
    });
    return list;
  }
);

export default getCountryListAction;
