import React from "react";
import logo from "../../assets/images/whiteLogo.svg";
import home from "../../assets/images/home.svg";
import register from "../../assets/images/register.png";
import PersonalInfoVerification from "./registration/personalInfoVerification";
import MobileVerification from "./registration/mobileVerification";
import AddressVerification from "./registration/addressVerification";
import LoginStepper from "./login/loginStepper";
import DefinePass from "./definePass/definePass";
import ForgetPass from "./forgetPass/forgetPass";
import ForgetRatiorKey from "./forgotRatiorkey/forgetRatiorKey";
import { Routes, Route } from "react-router-dom";
import { URL } from "../../core/constants";
import "./home.scss";
import DocumentVerification from "./registration/documentVerification";

const Home = () => {
  return (
    <div className=" home-wrapper">
      <div className="col-container">
        {/* <div>
          <ul className="ul">
            <li className="li">
              <a href="">Home</a>
            </li>
            <li className="li">
              <a href="">Contact</a>
            </li>
            <li className="li">
              <a href="">About</a>
            </li>
            <li className="li">
              <a href="">FAQ</a>
            </li>
          </ul>
        </div> */}

        <Routes>
          <Route path="/*" element={<LoginStepper />} />
          <Route path={URL.LOGIN} element={<LoginStepper />} />
          <Route path={URL.FIRST_LOGIN} element={<LoginStepper />} />
          <Route
            path={URL.FORGETPASS}
            element={
              <ForgetPass
                title={"PASSWORT VERGESSEN"}
                subTitle={"E-Mail senden"}
              />
            }
          />
          <Route
            path={URL.FORGOTRATIORKEY}
            element={
              <ForgetRatiorKey
                title={"RATIORKEY VERGESSEN"}
                subTitle={"Ratiorkey versenden"}
              />
            }
          />
          <Route
            path={URL.DEFINEPASS}
            element={
              <DefinePass
                title={"ANMELDUNG"}
                subTitle={"Passwort festlegen"}
                buttonTxt={"PASSWORT FESTLEGEN"}
                needEmailVerification={true}
              />
            }
          />
          <Route
            path={URL.CHANGEPASS}
            element={
              <DefinePass
                title={"PASSWORT VERGESSEN"}
                subTitle={"E-Mail senden"}
                buttonTxt={"PASSWORT FESTLEGEN"}
              />
            }
          />
          <Route
            path={URL.REGISTERSTERPERSONALINFO}
            element={<PersonalInfoVerification />}
          />
          <Route
            path={URL.MOBILEVERIFICATION}
            element={<MobileVerification />}
          />
          <Route
            path={URL.ADDRESSVERIFICATION}
            element={<AddressVerification />}
          />
          <Route
            path={URL.DOCUMENTSVERIFICATION}
            element={<DocumentVerification />}
          />
        </Routes>
          <div className="footer">&copy;2021-2023 Ratior alle Rechte vorbehalten.</div>
          <div className="footer2"><a href="https://ratior.ag/impressum/"  target="_blank">Impressum</a>    •    <a href="https://ratior.ag/datenschutz/" target="_blank">Datenschutz</a></div>
      </div>
      <div className="">
        <Routes>
          <Route
            path="/*"
            element={
              <div className="div-right">
                <img className="logo" src={logo} alt="" />
                <img className="home" src={register}></img>
              </div>
            }
          />
          <Route
            path={URL.LOGIN}
            element={
              <div className="div-right">
                <img className="logo" src={logo} alt="" />
                <img className="home" src={home}></img>
              </div>
            }
          />
          <Route
            path={URL.REGISTERSTERPERSONALINFO}
            element={
              <div className="div-right">
                <img className="logo" src={logo} alt="" />
                <img className="register" src={register}></img>
              </div>
            }
          />
          <Route
            path="/registration/activation"
            element={
              <div className="div-right">
                <img className="logo" src={logo} alt="" />
                <img className="register" src={register}></img>
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Home;
