import { createSlice } from "@reduxjs/toolkit";
import { getCustomerStorageListAction, getSubStorageListAction } from ".";
import { ConvertGramToDestUnit } from "../../../core/utils/convert";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  detailedList: [],
  subStorages: [],
};
export const storageListSlice = createSlice({
  name: "storageList",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //get Customer Storage List Action
    [getCustomerStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCustomerStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;

      //filter only storages not show substorages
      state.detailedList = action.payload?.filter(
        //(x) => !x.arades_uebergeordneter_lagerplatz
        (x) => x
      );

      state.detailedList.sort((a, b) => (a.msdyn_name > b.msdyn_name) ? 1 : -1)
      
      state.globalData.actionType = "getDetailedList";
    },
    [getCustomerStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //subStorages
    [getSubStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getSubStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      let list = [];
      action.payload.forEach((item) => {
        let weightUnit = item.weightUnit.toLowerCase().startsWith("gram")
          ? "g"
          : item.weightUnit.toLowerCase().startsWith("Kilogram")
          ? "kg"
          : item.weightUnit;
        item.arades_verfuegbare_menge_formatted = !item.pending
          ? ConvertGramToDestUnit(
              item.arades_verfuegbare_menge,
              item.weightUnit
            ) +
            " " +
            weightUnit
          : "Pending";
        item.arades_menge_formatted = !item.pending
          ? ConvertGramToDestUnit(item.arades_menge, item.weightUnit) +
            " " +
            weightUnit
          : "Pending";

        list.push(item);
      });

      state.subStorages = list;
      state.globalData.actionType = "getSubStorageList";
    },
    [getSubStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const fullData = (state) => state.storageList;
export const globalData = (state) => state.storageList.globalData;

export const storageDetailedList = (state) => state.storageList.detailedList;
export const subStorageList = (state) => state.storageList.subStorages;

export const { resetError, resetActionStatus, resetAll } =
  storageListSlice.actions;
export default storageListSlice.reducer;
