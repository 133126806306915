import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { Currency, dateString, zeroPad } from "../../../core/utils/convert";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getLevels = async (workspaceId, level) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const accountFields = (prefix) => `
    <attribute name="name" alias="${prefix}name" groupby="true"/>
    <attribute name="primarycontactid" alias="${prefix}primarycontactid2" groupby="true"/>
    <attribute name="accountid" alias="${prefix}accountid" groupby="true"/>
    <attribute name="arades_vermittler" alias="${prefix}arades_vermittler" groupby="true"/>
    <attribute name="createdon" alias="${prefix}createyear" groupby="true" dategrouping="year" />
	  <attribute name="createdon" alias="${prefix}createmonth" groupby="true" dategrouping="month" />
	  <attribute name="createdon" alias="${prefix}createday" groupby="true" dategrouping="day" />
    <attribute name="arades_firmenname_partner" alias="${prefix}arades_firmenname_partner" groupby="true"/>
    <attribute name="telephone3" alias="${prefix}telephone3" groupby="true"/>
    <attribute name="arades_mobil_partner" alias="${prefix}arades_mobil_partner2" groupby="true"/>
    <attribute name="emailaddress3" alias="${prefix}emailaddress3" groupby="true"/>
    <attribute name="arades_address3_line1" alias="${prefix}arades_address3_line1" groupby="true"/>
    <attribute name="arades_address3_line2" alias="${prefix}arades_address3_line2" groupby="true"/>
    <attribute name="arades_address3_postalcode" alias="${prefix}arades_address3_postalcode" groupby="true"/>  
    <attribute name="arades_address3_city" alias="${prefix}arades_address3_city" groupby="true"/>
    <attribute name="arades_land_partner" alias="${prefix}arades_land_partner2" groupby="true"/> `;
  let furdrungs = `
    <link-entity name="arades_vermittlerkonto" from="arades_firma" to="accountid" link-type="outer" alias="av">      
      <link-entity name="arades_forderung" from="arades_vermittlerkonto" to="arades_vermittlerkontoid" link-type="outer" alias="fg">
        <attribute name="arades_betrag" alias="sum_arades_betrag" aggregate="sum"/>
        <filter type="and">
          <condition attribute="arades_provisionstyp" operator="in">
            <value>100000000</value>
            <value>100000001</value>
          </condition>
          <condition attribute="arades_ursprungsdatum" operator="this-month"/>
        </filter>
      </link-entity>
    </link-entity>`;

  var fetchQuery =
    level == 1
      ? `
      <fetch version="1.0" mapping="logical" distinct="false" aggregate="true">
        <entity name="account">
          ${accountFields("")}          
          <filter type="and">
            <condition attribute="arades_vermittlerstatus" operator="eq" value="100000000"/>
            <condition attribute="arades_vermittler" operator="eq"  value="{${workspaceId}}"/>
          </filter>
          ${furdrungs}
          <link-entity name="account" from="accountid" to="arades_vermittler" link-type="inner" >
            ${accountFields("v")}
          </link-entity>          
        </entity>
      </fetch>`
      : `
      <fetch version="1.0" mapping="logical" distinct="false"  aggregate="true">
        <entity name="account">
          ${accountFields("")}         
        <filter type="and">
          <condition attribute="arades_vermittlerstatus" operator="eq" value="100000000"/>
        </filter>
        ${furdrungs}
        <link-entity name="account" from="accountid" to="arades_vermittler" link-type="inner" >          
          ${accountFields("v")}
          <link-entity name="account" from="accountid" to="arades_vermittler" link-type="inner" >
            <filter type="and">
              <condition attribute="accountid" operator="eq" value="{${workspaceId}}"/>
            </filter>
          </link-entity>
        </link-entity>
        </entity>
      </fetch>`;

  //we need some of the furdrungs of that fetchxml for first vermitter
  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/accounts?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Meine Struktur nicht geladen werden");

  let list = [];
  result.data.data.value.map((item) => {
    let createdonStr =
      zeroPad(item.createyear, 4) +
      "-" +
      zeroPad(item.createmonth, 2) +
      "-" +
      zeroPad(item.createday, 0);
    let tmp = {
      accountid: item.accountid,
      arades_firmenname_partner: item["arades_firmenname_partner"],
      primaryContact:
        item["primarycontactid2@OData.Community.Display.V1.FormattedValue"],
      telephone3: item["telephone3"],
      arades_mobil_partner: item["arades_mobil_partner2"],
      emailaddress3: item["emailaddress3"],
      arades_address3_line1: item["arades_address3_line1"],
      arades_address3_line2: item["arades_address3_line2"],
      arades_address3_postalcode: item["arades_address3_postalcode"],
      arades_address3_city: item["arades_address3_city"],
      arades_land_partner:
        item["arades_land_partner2@OData.Community.Display.V1.FormattedValue"],
      sellerid: item.arades_vermittler,
      varades_firmenname_partner: item["varades_firmenname_partner"],
      vprimaryContact:
        item["vprimarycontactid2@OData.Community.Display.V1.FormattedValue"],
      vtelephone3: item["vtelephone3"],
      varades_mobil_partner: item["varades_mobil_partner2"],
      vemailaddress3: item["vemailaddress3"],
      varades_address3_line1: item["varades_address3_line1"],
      varades_address3_line2: item["varades_address3_line2"],
      varades_address3_postalcode: item["varades_address3_postalcode"],
      varades_address3_city: item["varades_address3_city"],
      varades_land_partner:
        item["varades_land_partner2@OData.Community.Display.V1.FormattedValue"],
      createdon: createdonStr,
      createdon_formatted: dateString(createdonStr),
      level: level,
      sum_arades_betrag: Currency(item["sum_arades_betrag"]),
    };

    list.push(tmp);
  });

  return list.sort((a, b) =>
    a?.arades_firmenname_partner >= b?.arades_firmenname_partner ? -1 : 1
  );
};

const getMyStructureAction = createAsyncThunk(
  "myStructure/getmystructure",
  async (filter, { getState }) => {
    const { workspaceId } = filter;

    let level1 = await getLevels(workspaceId, 1);
    let level2 = await getLevels(workspaceId, 2);

    return level1.concat(level2);
  }
);

export default getMyStructureAction;
