import React, { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { FormSteperStructure, Select } from "../../common";
import { WORKSPACETYPE } from "../../../core/constants";
import { WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import {
  setStorageTypeStep,
  StorageTypeStep,
} from "../../../redux/slices/storage/storage.slice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function StorageType({ handleCurrentStep, currentStep }) {
  const currentStorageType = useSelector(StorageTypeStep);
  const [storageType, setStorageType] = useState(
    currentStorageType.isForPartner ? "shared" : "myself"
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let storageTypeOptions = [];
  if (localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT) {
    storageTypeOptions = [
      { key: "myself", value: t("add-storage.for-myself") },
      { key: "shared", value: t("add-storage.shared-with-another-person") },
    ];
  } else {
    storageTypeOptions = [{ key: "myself", value: "For Myself" }];
  }

  const onSubmit = () => {
    const storageTypes = {
      isForMyself: false,
      isForCompany: false,
      isForUnderAge: false,
      isForPartner: false,
    };
    if (storageType === "shared") {
      storageTypes.isForPartner = true;
      dispatch(setStorageTypeStep(storageTypes));
      handleCurrentStep(currentStep + 2);
    } else {
      if (localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.UNDER_AGE) {
        storageTypes.isForUnderAge = true;
      } else if (
        localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT
      ) {
        storageTypes.isForMyself = true;
      } else {
        storageTypes.isForCompany = true;
      }
      dispatch(setStorageTypeStep(storageTypes));
      handleCurrentStep("next");
    }
  };
  return (
    <Box
      sx={{
        maxWidth: "816px",
        margin: "0 auto",
        marginTop: "80px",
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontSize: "16px",
          fontWeight: "400",
        }}
      >
        {t("add-storage.select-the-storage-type")}
      </Typography>

      <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.57)" }}>
        {t("add-storage.wich-type-of-storage")}
      </Typography>

      <Box mt="30px" mb="120px">
        <Select
          name="storageType"
          value={storageType}
          onChange={(e) => setStorageType(e.target.value)}
          label={t("add-storage.storage-type")}
          options={storageTypeOptions}
        />
      </Box>

      <FormSteperStructure.Footer>
        <Button
          onClick={onSubmit}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          Weiter
          {/* Next */}
        </Button>
      </FormSteperStructure.Footer>
    </Box>
  );
}

export default StorageType;
