import axios from "axios";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";

const getVermittlerDataFunc = async (vermittlerId, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let result = await axios.get(
    `${BASE_URL}data/accounts(${vermittlerId})/?$select=accountid,name,emailaddress1,address1_city,address1_line1,address1_postalcode,address1_line2,_arades_land_value,telephone1,address1_longitude,address1_latitude,arades_firmenname_partner,telephone3,arades_mobil_partner,emailaddress3,arades_address3_line1,arades_address3_line2,arades_address3_postalcode,arades_address3_city,_arades_land_partner_value,arades_address3_longitude,arades_address3_latitude`,
    config
  );

  if (!result?.data?.status == 200 || !result?.data?.data)
    throw Error("Vermittler könnten nicht geladen werden"); //("vermittler not fetched");

  return result.data.data;
};
export default getVermittlerDataFunc;
