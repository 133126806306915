import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { FormSteperStructure, LoadingOverlay, Select } from "../../common";
import InputUpload from "../../common/inputs/inputUpload";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../common/popups/confirmModal";
import { URL } from "../../../core/constants";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { useApi } from "../../../core/hooks/useApi";
import { getUnderAgeDocumentListAction } from "../../../redux/slices/underage";
import {
  underAgedocumnets,
  underAgeFullState,
  documentsStepData,
  setDocumentsStep,
  setDocuments,
} from "../../../redux/slices/underage/underage.slice";
import {
  CONTACT_ID,
  STATUS_CODE,
  DOCUMENTS_UPLOADED,
} from "../../../core/apiconfig/constantApi";
import { CONTACT_STATUS, ERROR_CONTACT_US } from "../../../core/constants";
import "./registration.scss";
import Ausweis from "../../../assets/documents/Ausweis_Musterbeispiel.pdf";
import Reisepass from "../../../assets/documents/Reisepass_Musterbeispiel.pdf";

function DocumentVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchOptions, post, patch } = useApi();

  const MAXFILESIZE = 20000000;

  const documents = useSelector(underAgedocumnets);
  const underAgeData = useSelector(underAgeFullState);
  const documentsStepInfo = useSelector(documentsStepData);
  const contactId = localStorage.getItem(CONTACT_ID);
  const [docType, setDocType] = useState();
  const [docTypeList, setDocTypeList] = useState([]);

  //const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDocTypes();
  }, []);

  const fetchDocTypes = async () => {
    setLoading(true);
    const result = await fetchOptions("contact", "arades_dokumenttyp");
    setLoading(false);
    setDocTypeList(result);
  };

  //parichehr move to redux in refactor
  const handleFileChange = async (e, documentId) => {
    if (!e.target.files || e.target.files.length == 0) return;

    if (e.target.files[0].size > MAXFILESIZE) {
      enqueueSnackbar(t("messages.max-file"), {
        variant: "error",
        autoHideDuration: 20000,
      });
    }

    dispatch(
      setDocumentsStep({
        ...documentsStepInfo.uploadedDouments,
        ["d" + documentId]: e.target.files[0].name,
      })
    );

    const formData = new FormData();
    formData.append("documentId", documentId);
    formData.append("contactId", contactId);
    formData.append("file", e.target.files[0]);
    setLoading(true);
    const result = await post(`${BASE_URL}upload/kontaktdokument`, formData);
    setLoading(false);
    if (result.status == 200)
      enqueueSnackbar("Update erfolgreich", {
        variant: "success",
        autoHideDuration: 3000,
      });
    else
      dispatch(
        setDocumentsStep({
          ...documentsStepInfo.uploadedDouments,
          ["d" + documentId]: null,
        })
      );
  };

  const submitForm = async () => {
    await verifyContact();
  };
  const updateContactDocType = async (docType) => {
    setLoading(true);
    const result = await patch(
      `${BASE_URL}data/contacts(${localStorage.getItem(CONTACT_ID)})/`,
      {
        arades_dokumenttyp: docType,
      }
    );
    if (result.status != 200) {
      setLoading(false);
      enqueueSnackbar(result.statusText + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
    } else dispatch(getUnderAgeDocumentListAction(contactId));
  };

  const verifyContact = async () => {
    setLoading(true);
    const result = await patch(
      `${BASE_URL}data/contacts(${localStorage.getItem(CONTACT_ID)})/`,
      {
        statuscode: CONTACT_STATUS.VERIFIED,
        arades_nectverifiziert: true,
        arades_adresse_verifiziert: true,
      }
    );
    setLoading(false);
    if (result.status != 200) {
      enqueueSnackbar(result.statusText + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
    }
    if (result.status == 200) {
      localStorage.setItem(STATUS_CODE, CONTACT_STATUS.VERIFIED);
      localStorage.setItem(DOCUMENTS_UPLOADED, "true");

      navigate(URL.DASHBOARD);
      window.location.reload();
      //setConfirmOpen(true);
    }
  };

  useEffect(() => {
    setLoading(underAgeData.loading);
  }, [underAgeData.loading]);

  const formIsValid = () => {
    console.log(documentsStepInfo.uploadedDouments);
    if (!docType || !documents?.length > 0) return false;
    let result = true;

    for (let i = 0; i < documents?.length; i++) {
      if (
        !documentsStepInfo.uploadedDouments[
          "d" + documents[i].arades_kontaktdokumentid
        ]
      )
        result = false;
    }
    return result;
  };

  return (
    <LoadingOverlay show={loading}>
      <div style={{ width: 0 }}>
        {/* <ConfirmModal
          open={confirmOpen}
          title={"Super!"}
          showCloseButton={false}
          size="md"
          message={
            <div>
              Wir werden die Unterlagen prüfen und anschließend Kontakt zu Ihnen
              aufnehmen.<br></br>
              Lagerplätze können Sie einrichten, nachdem Sie von uns die
              Mitteilung über die erfolgte Freigabe erhalten haben.
            </div>
          }
          noImage={true}
          okTitle={"FERTIG"}
          onOk={() => {
            navigate(URL.DASHBOARD);
            window.location.reload();
          }}
        ></ConfirmModal> */}
      </div>
      <div className="register-title">{"REGISTRIERUNG"}</div>
      <div className="step">Dokumente hochladen</div>
      <div className="register-box-wrapper">
        <div className="flex-column register-box padding-10">
          <Box
            sx={{
              margin: "0 auto",
              width: "500px",
              minHeight: "calc(100vh - 250px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Bitte wählen Sie eine der folgenden Unterlagen aus und laden Sie
              diese hoch:
            </Typography>
            <Typography
              variant="subtitle2"
              mb="10px"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              <br></br>* Die maximale Upload-Größe beträgt 20MB
              <br></br>* Erlaubte Dateitypen: pdf, png, jpeg, jpg
              <br></br>* Beispiele wie Sie die Dokumente hochladen sollen,
              finden Sie hier:
              <br></br>&nbsp;&nbsp;&nbsp;
              <a
                className="blue"
                href={Ausweis}
                target="_blank"
                rel="noreferrer"
              >
                Ausweis
              </a>
              &nbsp; &nbsp;
              <a
                className="blue"
                href={Reisepass}
                target="_blank"
                rel="noreferrer"
              >
                Reisepass
              </a>
            </Typography>

            <div className="col-12 col-md-6 mt-4 " style={{ width: "100%" }}>
              <Select
                name={"docType"}
                value={docType}
                onChange={(e) => {
                  setDocType(e.target.value);
                  dispatch(setDocumentsStep({}));
                  dispatch(setDocuments([]));
                  updateContactDocType(e.target.value);
                }}
                options={docTypeList}
                label={"Dokumenttyp*"}
              />
            </div>
            {documents?.map((doc) => {
              return (
                <div
                  className="col-12 col-md-6 mt-4 "
                  style={{ width: "100%" }}
                >
                  <InputUpload
                    name={"d" + doc.arades_kontaktdokumentid}
                    value={
                      documentsStepInfo.uploadedDouments[
                        "d" + doc.arades_kontaktdokumentid
                      ]
                    }
                    onChange={(e) =>
                      handleFileChange(e, doc.arades_kontaktdokumentid)
                    }
                    placeholder={
                      doc[
                        "_arades_dokument_value@OData.Community.Display.V1.FormattedValue"
                      ]
                    }
                    label={
                      doc[
                        "_arades_dokument_value@OData.Community.Display.V1.FormattedValue"
                      ]
                    }
                    //parichehr ask
                    accept=".pdf,.jpg,.jpeg,.png,.svg"
                  />
                </div>
              );
            })}
            <FormSteperStructure.Footer>
              <Button
                disabled={!formIsValid()}
                onClick={submitForm}
                className="btn-action"
                fullWidth
                sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
                variant="contained"
              >
                {"ANMELDUNG"}
              </Button>
            </FormSteperStructure.Footer>
          </Box>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default DocumentVerification;
