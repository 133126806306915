import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  FormSteperStructure,
  LoadingOverlay,
  ConfirmModal,
  DownloadGroup,
  MUICheckBox,
} from "../../../common";
import { Box, Button, Typography, Grid } from "@mui/material";
import {
  signContract,
  setSignContract,
  resetActionStatus,
  resetError,
  storageFullData,
  StorageTypeStep,
} from "../../../../redux/slices/storage/storage.slice";
import {
  signContractWorkflowAction,
  verificationSendSMSCodeAction,
} from "../../../../redux/slices/storage";
import {
  getStorageNumbersAction,
  getDocumentsAction,
} from "../../../../redux/slices/storage";
import { useSelector, useDispatch } from "react-redux";
import blueCircle from "../../../../assets/images/blueCircle.svg";
import { useNavigate } from "react-router-dom";
import {
  URL,
  ERROR_CONTACT_US,
  STORAGE_TYPE,
} from "../../../../core/constants";
import { useParams } from "react-router";
import {
  WORKSPACE_ID,
  MOBILE_NUMBER,
} from "../../../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";
import { BYPASS_ADOBE_SIGN } from "../../../../core/apiconfig/environment";

function SignStorageContract({ handleCurrentStep, justSign = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const storageAllData = useSelector(storageFullData);
  const signContractData = useSelector(signContract);
  const storageTypeData = useSelector(StorageTypeStep);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSuccessJustSignModal, setOpenSuccessJustSignModal] =
    useState(false);

  //just sign
  const storageType = useParams()?.type;
  const wait14Days = useParams()?.wait14Days;
  const storageId = useParams()?.id;

  useEffect(() => {
    let folderName = "";

    if (storageTypeData.isForMyself || storageType == STORAGE_TYPE.MYSELF)
      folderName = "EinzelLagerplätze";
    else if (
      storageTypeData.isForCompany ||
      storageType == STORAGE_TYPE.COMPANY
    )
      folderName = "FirmenLagerplätze";
    else if (
      storageTypeData.isForUnderAge ||
      storageType == STORAGE_TYPE.UNDER_AGE
    )
      folderName = "MinderjährigLagerplätze";
    else if (
      storageTypeData.isForPartner ||
      storageType == STORAGE_TYPE.PARTNER
    )
      folderName = "PartnerLagerplätze";

    dispatch(getDocumentsAction(folderName));
  }, []);

  useEffect(() => {
    if (
      storageAllData?.globalData?.actionStatus &&
      !storageAllData.error &&
      storageAllData.globalData.actionType == "getStorageNumbers"
    ) {
      dispatch(resetActionStatus);
      handleCurrentStep("next");
    } else if (
      storageAllData?.globalData?.actionStatus &&
      !storageAllData.error &&
      storageAllData.globalData.actionType == "signContractWorkflow"
    ) {
      dispatch(resetActionStatus);
      setOpenSuccessJustSignModal(true);
      //handleCurrentStep("next");
      //navigate("/" + URL.STORAGE);
    }
  }, [storageAllData.globalData.actionStatus]);

  const onSubmit = (skipSign) => {
    if (skipSign) {
      dispatch(setSignContract({ signDone: false }));
      if (justSign) navigate("/" + URL.STORAGE);
      else dispatch(getStorageNumbersAction());
    } else setOpenConfirm(true);
  };

  useEffect(() => {
    setLoading(storageAllData.loading);
  }, [storageAllData.loading]);

  useEffect(() => {
    if (storageAllData.error) {
      enqueueSnackbar(storageAllData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [storageAllData.error]);

  return (
    <Box
      sx={{
        marginTop: "32px",
      }}
    >
      <LoadingOverlay show={loading}>
        <ConfirmModal
          open={openConfirm}
          size="md"
          title="Verträge unterzeichnen"
          imageSrc={blueCircle}
          showCloseButton={true}
          onClose={() => setOpenConfirm(false)}
          message={
            <div>
              Sie erhalten im Anschluss eine E-Mail mit Instruktionen zur
              Unterzeichnung Ihrer Verträge mit der Ratior AG, der Ratior GmbH
              und der Ratior Gold GmbH.<br></br>
              Verwenden Sie den darin enthaltenen Link zu ADOBE SIGN, um den
              Unterzeichnungsvorgang zu starten.
            </div>
          }
          acceptTitle="E-MAIL SENDEN"
          onAccept={() => {
            setOpenConfirm(false);
            dispatch(setSignContract({ signDone: true }));
            if (justSign)
              dispatch(
                signContractWorkflowAction({
                  storageType: storageType,
                  ownerId: localStorage.getItem(WORKSPACE_ID),
                  wait14Days: wait14Days,
                })
              );
            else dispatch(getStorageNumbersAction());
          }}
          rejectTitle="SPÄTER UNTERSCHREIBEN"
          onReject={() => {
            setOpenConfirm(false);
            dispatch(setSignContract({ signDone: false }));
            if (justSign) navigate("/" + URL.STORAGE);
            else dispatch(getStorageNumbersAction());
          }}
        ></ConfirmModal>
        <ConfirmModal
          open={openSuccessJustSignModal}
          showCloseButton={false}
          title={"Ihre Vertragsunterlagen sind auf dem Weg"} //this legal guardian does nothave access
          message={
            <div>
              In Kurze erhalten Sie eine E-Mail mit Ihren Vertragsunterlagen.
              Sollten Sie unsere E-Mail nicht erhalten haben, wenden Sie sich
              bitte per Telefon +49-(0)6227-65506 10 oder per E-Mail
              Kunde@Ratior.de an uns an.
            </div>
          }
          noImage={true}
          okTitle={"OK"}
          onOk={() => {
            setOpenSuccessJustSignModal(false);
            navigate("/" + URL.STORAGE);
          }}
        ></ConfirmModal>
        <Box
          sx={{
            marginTop: "32px",
          }}
        >
          <Typography
            variant="subtitle2"
            mb="13px"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Fantastisch, alle notwendigen Angaben liegen nun vor
          </Typography>
          <Box
            sm={12}
            sx={{
              width: "100%",
              borderRadius: "8px",
              backgroundColor: "#E3F2FD",
              padding: 10,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Sie können nun Ihre Verträge mit der Ratior AG, der Ratior GmbH
              und der Ratior Gold GmbH abschließen. Hier im Überblick die
              Vertragsdokumente der drei Firmen. Sie können sich vor der
              Unterzeichnung noch einmal ansehen:
            </Typography>
          </Box>
          <Box
            sm={12}
            mt={20}
            mb={30}
            sx={{
              width: "100%",
              "& span": {
                marginRight: "0 !important",
              },
            }}
          >
            <Grid container spacing={10}>
              {signContractData?.documents?.map((item) => (
                <DownloadGroup
                  title={item.folder}
                  downloads={item.files}
                ></DownloadGroup>
              ))}
            </Grid>
          </Box>

          <Box
            sm={12}
            sx={{
              width: "100%",
              borderRadius: "8px",
              backgroundColor: "#E3F2FD",
              padding: 10,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Nachdem Sie die Vertragsunterlagen unterschrieben haben, werden
              sie von uns geprüft und gegengezeichnet. Anschließend erhalten Sie
              per E-Mail das gesamte Vertragswerk für Ihre Unterlagen.
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            mb="13px"
            mt="13px"
            sx={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Im nächsten Schritt haben Sie die Möglichkeit, Ihre Verträge mit
            Ihrer Unterschrift zu versehen:
          </Typography>
          <Grid xs={12} lg={12} item>
            <MUICheckBox
              name="agreement"
              onClick={() => setAgreement(!agreement)}
              label={
                "Hiermit bestätige ich die oben aufgelisteten Dokument gelesen und verstanden zu haben. Weiter erkläre ich mit dem Inhalt der Dokumente Einverstanden zu sein. *"
              }
            />
          </Grid>
          <FormSteperStructure.Footer>
            {!BYPASS_ADOBE_SIGN && (
              <>
                <Button
                  onClick={() => onSubmit(true)}
                  fullWidth
                  sx={{
                    borderColor: "info.main",
                    color: "info.main",
                    marginRight: 10,
                    maxWidth: "250px",
                  }}
                  variant="outlined"
                >
                  SPÄTER UNTERSCHREIBEN
                </Button>
                <Button
                  onClick={() => onSubmit(false)}
                  fullWidth
                  sx={{ bgcolor: "info.main", maxWidth: "300px" }}
                  variant="contained"
                >
                  VERTRÄGE JETZT UNTERZEICHNEN
                </Button>
              </>
            )}
            {BYPASS_ADOBE_SIGN && (
              <>
                <Button
                  disabled={!agreement}
                  onClick={() => {
                    dispatch(
                      verificationSendSMSCodeAction({
                        mobileNumber: localStorage.getItem(MOBILE_NUMBER),
                      })
                    );
                    handleCurrentStep("next");
                  }}
                  fullWidth
                  sx={{ bgcolor: "info.main", maxWidth: "192px" }}
                  variant="contained"
                >
                  WEITER
                </Button>
              </>
            )}
          </FormSteperStructure.Footer>
        </Box>
      </LoadingOverlay>
    </Box>
  );
}

export default SignStorageContract;
