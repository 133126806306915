import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getGeschlechtListAction = createAsyncThunk(
  "global/getGeschlechtList",
  async (dataParams, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };
    const ownerTable = "contact";
    const optionFieldName = "arades_geschlecht";

    const {
      data: { data },
    } = await axios.get(
      `${BASE_URL}data/EntityDefinitions(LogicalName='${ownerTable}')/Attributes(LogicalName='${optionFieldName}')/Microsoft.Dynamics.CRM.PicklistAttributeMetadata?$select=LogicalName&$expand=OptionSet($select=Options)`,
      config
    );
    let dictionary = [];
    if (data?.OptionSet?.Options) {
      data.OptionSet.Options.map((item) => {
        if (item.Value && item.Label && item.Label.LocalizedLabels)
          dictionary.push({
            key: item.Value,
            value: item.Label.LocalizedLabels[0].Label,
          });
      });
    }
    return dictionary;
  }
);

export default getGeschlechtListAction;
