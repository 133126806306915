import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Input from "../common/inputs/input";
import Button from "../common/buttons/button";
import { useSnackbar } from "notistack";
import { useApi } from "../../core/hooks/useApi";
import { BASE_URL } from "../../core/apiconfig/environment";
import Select from "../../components/common/inputs/select";

const EmployeeAdd = () => {
  const id = useParams().id;
  const { get, patch, post } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    contactid: null,
    firstname: "",
    lastname: "",
    telephone1: "",
    arades_anrede: "",
    mobilephone: "",
    address1_name: "",
    address1_line1: "",
    address1_country: "",
    address1_city: "",
    address1_postalcode: "",
    emailaddress1: "",
  });

  const setContact = async () => {
    const result = await get(`${BASE_URL}data/contacts(${id})/`);

    if (result.data) {
      const contact = result.data;
      setValue({
        ...value,
        contactid: contact.contactid,
        firstname: contact.firstname,
        lastname: contact.lastname,
        telephone1: contact.telephone1,
        arades_anrede: contact.arades_anrede,
        mobilephone: contact.mobilephone,
        address1_line1: contact.address1_line1,
        address1_country: contact.address1_country,
        address1_city: contact.address1_city,
        address1_postalcode: contact.address1_postalcode,
        emailaddress1: contact.emailaddress1,
      });
    }
  };

  const save = async () => {
    try {
      let result = null;
      if (id) result = await patch(`${BASE_URL}data/contacts(${id})`, value);
      else result = await post(`${BASE_URL}data/contacts/`, value);
      if (result.status == 200 || result.status == 201)
        enqueueSnackbar("Die Änderungen wurden gespeichert", {
          variant: "success",
          autoHideDuration: 3000,
        });
    } catch (error) {
      console.log(`error`, error);
    }
  };

  useEffect(() => {
    if (id) setContact();
  }, []);

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  return (
    <div className="container-card">
      <h1 className="title">{"Mitarbeiter"}</h1>
      <div className="row d-flex">
        <div className="d-flex col-md-6 col-12">
          <div className="row d-flex col-12 align-self-start ">
            <div className="col-6 ">
              <Input
                name={"firstname"}
                value={value.firstname}
                onChange={handleChange}
                label={"Vorname"}
              />
            </div>
            <div className=" col-6 ">
              <Input
                name={"lastname"}
                value={value.lastname}
                onChange={handleChange}
                label={"Nachname"}
              />
            </div>
            <div className="col-md-6 col-12 mt-4 mr-0 pr-4 ">
              <Select
                name={"arades_anrede"}
                value={value.arades_anrede}
                options={[
                  { key: "100000000", value: "Frau" },
                  { key: "100000001", value: "Herr" },
                ]}
                onChange={handleChange}
                label={"Sex"}
              />
            </div>
            <div className="col-6">
              <Input
                name={"mobilephone"}
                value={value.mobilephone}
                onChange={handleChange}
                label={"Mobiltelefon"}
              />
            </div>
            <div className="col-6 ">
              <Input
                name={"telephone1"}
                value={value.telephone1}
                onChange={handleChange}
                label={"Telephone"}
              />
            </div>
            <div className="col-6 ">
              <Input
                name={"emailaddress1"}
                value={value.emailaddress1}
                onChange={handleChange}
                label={"E-Mail"}
              />
            </div>
            <div className="col-6 ">
              <Input
                name={"address1_country"}
                value={value.address1_country}
                onChange={handleChange}
                label={"Land"}
              />
            </div>
            <div className="col-6 ">
              <Input
                name={"address1_city"}
                value={value.address1_city}
                onChange={handleChange}
                label={"Ort"}
              />
            </div>
            <div className=" col-12 ">
              <Input
                name={"address1_postalcode"}
                value={value.address1_postalcode}
                onChange={handleChange}
                label={"Postleitzahl"}
                type="textarea"
              />
            </div>
            <div className=" col-12 ">
              <Input
                name={"address1_line1"}
                value={value.address1_line1}
                onChange={handleChange}
                label={"Straße und Hausnummer"}
                type="textarea"
              />
            </div>
            <div className="row d-flex col-12 mt-4">
              <Button primary onClick={save}>
                {"SPEICHERN"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAdd;
