import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { storageIsPending } from "../../../core/utils/business";
import { getStorageAmountFunc } from "../";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getSubStoragesOfOneStorageFunc = async (params, { getState }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    var fetchQuery = `
    <fetch mapping="logical" version="1.0" >
      <entity name="msdyn_warehouse" >
        <attribute name="msdyn_warehouseid"  />
        <attribute name="msdyn_name" />
        <attribute name="createdon" />
        <attribute name="statecode"/>
        <attribute name="statuscode" />
        <attribute name="arades_lagerplatz_typ" />
        <attribute name="arades_verzicht_auf_widerruf" />
        <attribute name="arades_edelmetall_typ" />
        <attribute name="arades_gewichtseinheit"   />                
        <attribute name="arades_einrichtungsgebuehr_beglichen" />
        <attribute name="arades_vertrag" />
        <attribute name="msdyn_description" />   
        <order attribute="createdon" descending="true"/>
        <link-entity name="product" from="productid" to="arades_edelmetall_typ" link-type="inner" alias="po">
          <attribute name="productid" />
          <attribute name="defaultuomid" />        
        </link-entity>     
        <link-entity name="msdyn_productinventory" from="msdyn_warehouse" to="msdyn_warehouseid" link-type="outer" alias="pi">
          <attribute name="msdyn_productinventoryid" />
          <attribute name="arades_verfuegbare_menge" />
          <attribute name="arades_menge" />          
        </link-entity>
        <filter>
          <condition attribute="arades_uebergeordneter_lagerplatz" operator="eq" value="${params.storageId}"/>
        </filter>
      </entity>
    </fetch>`;

    var encodedFetchXml = encodeURI(fetchQuery);
    const result = await axios.get(
      `${BASE_URL}data/msdyn_warehouses?fetchXml=${encodedFetchXml}`,
      config
    );

    if (result?.data?.status != 200) throw Error("substorages not fetched"); //("substorages not fetched");

    let list = [];
    for (let i = 0; i < result?.data?.data?.value?.length; i++) {
      let storage = result.data.data.value[i];
      storage.pending = storageIsPending(storage);
      storage.arades_verfuegbare_menge = storage["pi.arades_verfuegbare_menge"];
      storage.arades_menge = storage["pi.arades_menge"];
      storage.arades_Edelmetall_Typ = {
        productid: storage["po.productid"],
        _defaultuomid_value: storage["po.defaultuomid"],
      };
      storage.weightUnit =
        storage[
          "_arades_gewichtseinheit_value@OData.Community.Display.V1.FormattedValue"
        ];

      list.push(storage);
    }

    return list;
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};
export default getSubStoragesOfOneStorageFunc;
