import React, { useState } from "react";
import { Box, Button, Typography, FormControl, FormLabel } from "@mui/material";
import {
  FormSteperStructure,
  ConfirmModal,
  RadioListIcon,
} from "../../../common";
import numberTwoInCircle from "../../../../assets/images/numberTwoInCircle.svg";
import { useDispatch, useSelector } from "react-redux";
import { verificationSendSMSCodeAction } from "../../../../redux/slices/storage";
import {
  setWait14Days,
  storageGlobalData,
  setVerificationType,
} from "../../../../redux/slices/storage/storage.slice";
import { WORKSPACE_TYPE } from "../../../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../../../core/constants";
import { useTranslation } from "react-i18next";

function StorageInformation({ handleCurrentStep }) {
  const { t } = useTranslation();
  const checkVerification =
    localStorage.getItem(WORKSPACE_TYPE) != WORKSPACETYPE.CONTACT;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [open2ndConfirm, setOpen2ndConfirm] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const dispatch = useDispatch();
  const storageData = useSelector(storageGlobalData);

  const radioList = [
    {
      label: (
        <div>
          Die Ratior AG soll mit ihrer Tätigkeit bereits vor Ablauf der
          gesetzlichen Widerrufsfrist von 14 Tagen beginnen.<br></br>Der Kunde
          wünscht ausdrücklich die sofortige Eröffnung der Lagerplätze.
        </div>
      ),
      value: "startnow",
    },
    {
      label: (
        <div>
          Der Kunde wünscht, dass die Ratior AG nicht vor Ablauf der
          Widerrufsfrist von 14 Tagen<br></br> mit ihrer Tätigkeit für ihn
          beginnt.
        </div>
      ),
      value: "after14days",
    },
  ];

  const onSubmit = () => {
    if (selectedOption == "startnow") setOpenConfirm(true);
    else if (selectedOption == "after14days") setOpen2ndConfirm(true);
  };

  // const sendSMS = () => {
  //   if (!storageData.verification) {
  //     const currentAccountPhoneNumber = localStorage.getItem("mobileNumber");
  //     dispatch(setVerificationType("myself"));
  //     dispatch(
  //       verificationSendSMSCodeAction({
  //         mobileNumber: currentAccountPhoneNumber,
  //       })
  //     );
  //   }
  // };
  return (
    <>
      <ConfirmModal
        open={openConfirm}
        size="lg"
        title="Einverständnis mit dem Beginn der Tätigkeit vor Ablauf der Widerrufsfrist"
        imageSrc={numberTwoInCircle}
        showCloseButton={false}
        message={
          <div>
            <center>Wichtige Hinweise:</center>
            <div>
              <ul>
                <li>
                  Wenn der Kunde dieses Einverständnis zum sofortigen Beginn
                  unserer Tätigkeit vor dem Ende der Widerrufsfrist erteilt, hat
                  dies zur Folge, dass er zur Zahlung von Wertersatz für die bis
                  zum Widerruf erbrachte Dienstleistung verpflichtet ist. Dies
                  kann dazu führen, dass Sie er vertraglichen
                  Zahlungsverpflichtungen für die Einrichtung der Lagerplätze
                  für den Zeitraum bis zum Widerruf dennoch erfüllen muss.{" "}
                </li>
                <li>
                  Das Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von
                  beiden Seiten auf Ihren ausdrücklichen Wunsch vollständig
                  erfüllt ist, bevor das Widerrufsrecht ausgeübt wurde.
                </li>
              </ul>
              <div>
                <center>
                  <b>
                    Diese Entscheidung kann zu einem späteren Zeitpunkt nicht
                    mehr revidiert werden!
                  </b>
                </center>
              </div>
            </div>
          </div>
        }
        acceptTitle="Einverständnis erteilen"
        rejectTitle="zurück"
        onReject={() => {
          setOpenConfirm(false);
        }}
        onAccept={() => {
          setOpenConfirm(false);
          handleCurrentStep("next");
          dispatch(setWait14Days(false));
          // if (checkVerification) {
          //   sendSMS();
          // }
        }}
      ></ConfirmModal>
      <ConfirmModal
        open={open2ndConfirm}
        size="lg"
        title="Ablehnungserklärung"
        imageSrc={numberTwoInCircle}
        showCloseButton={false}
        message={
          <div>
            <center>Wichtige Hinweise:</center>
            <div>
              <ul>
                <li>
                  Wünscht der Kunde nicht ausdrücklich von der Ratior AG, mit
                  ihrer Tätigkeit bereits vor Ablauf der gesetzlichen
                  Widerrufsfrist zu beginnen, führt dies dazu, dass die Ratior
                  AG die Edelmetalllagerplätze für den Kunden erst nach diesem
                  Zeitraum von 14 Tagen ab Vertragsschluss einrichtet.
                </li>
                <li>
                  <b>
                    Solange der Kunde nicht über die entsprechenden Lagerplätze
                    verfügt, können seitens der Ratior GmbH oder der Ratior Gold
                    GmbH keine Edelmetallkäufe für den Kunden durchgeführt
                    werden.
                  </b>
                </li>
                <li>
                  Sollte der Kunde vor Ablauf der Widerrufsfrist Gelder zum
                  Zweck des Edelmetallerwerbs an die Ratior GmbH oder der Ratior
                  Gold GmbH überwiesen haben, wird mit der Bearbeitung der
                  entsprechenden Kaufaufträge erst nach Einrichtung der
                  Lagerplätze begonnen, da deren Tätigkeit das Bestehen eines
                  Lagerplatzes bei der Ratior AG voraussetzt.
                </li>
              </ul>
              <div>
                <center>
                  <b>
                    Diese Entscheidung kann zu einem späteren Zeitpunkt nicht
                    mehr revidiert werden! Der Kunde muss sich bewusst machen,
                    dass mit Edelmetallkäufen unwiderruflich erst nach
                    Einrichtung der Lagerplätze begonnen werden kann.
                  </b>
                </center>
              </div>
            </div>
          </div>
        }
        acceptTitle="Ich wünsche diese Ablehnung"
        rejectTitle="zurück"
        onReject={() => {
          setOpen2ndConfirm(false);
        }}
        onAccept={() => {
          setOpen2ndConfirm(false);
          handleCurrentStep("next");
          dispatch(setWait14Days(true));
          // if (checkVerification) {
          //   sendSMS();
          // }
        }}
      ></ConfirmModal>

      <Box
        sx={{
          marginTop: "32px",
        }}
      >
        <Typography
          variant="subtitle2"
          mb="13px"
          sx={{
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Bitte geben Sie an, ob Sie ausdrücklich den Beginn unserer Tätigkeit
          für Sie VOR ABLAUF IHRER WIDERRUFSFRIST wünschen:
        </Typography>

        <Box
          sm={12}
          sx={{
            margin: "0 auto",
            width: "100%",
            "& span": {
              marginRight: "0 !important",
            },
          }}
        >
          <RadioListIcon
            currentValue={selectedOption}
            handleSetCurrentValue={(value) => {
              setSelectedOption(value);
            }}
            data={radioList}
          />
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={onSubmit}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            WEITER
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </>
  );
}

export default StorageInformation;
