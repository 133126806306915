import React, { useState, useEffect } from "react";
import { DataTableLeftEdit, LoadingOverlay, Ribbon } from "../common";
import {
  URL,
  CELL_RENDER_TYPE,
  STORAGE_STATUS,
  METAL_CHARS,
  ERROR_CONTACT_US,
} from "../../core/constants";
import { WORKSPACE_ID, WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import { MetalPart } from "../../core/utils/business";
import { useDispatch, useSelector } from "react-redux";
import { resetAll } from "../../redux/slices/storage/storage.slice";
import { getCustomerStorageListAction } from "../../redux/slices/storageList";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import {
  storageDetailedList,
  globalData,
  resetError,
} from "../../redux/slices/storageList/storageList.slice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BYPASS_ADOBE_SIGN } from "../../core/apiconfig/environment";

const StorageList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const storageList = useSelector(storageDetailedList);
  const storageListGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getCustomerStorageListAction({
        workspaceId: localStorage.getItem(WORKSPACE_ID),
        workspaceType: localStorage.getItem(WORKSPACE_TYPE),
        fetchSubStorages: true,
        fetchPrices: true,
      })
    );
  }, []);

  useEffect(() => {
    if (storageListGlobalData.error != null) {
      enqueueSnackbar(storageListGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [storageListGlobalData.error]);

  useEffect(() => {
    setLoading(storageListGlobalData.loading);
  }, [storageListGlobalData.loading]);

  return (
    <>
      <LoadingOverlay show={loading}>
        {!BYPASS_ADOBE_SIGN &&
          storageList.map((item, index) => {
            if (
              item.statuscode == STORAGE_STATUS.PENDING_FOR_CONTRACT &&
              MetalPart(item.msdyn_name) == METAL_CHARS.GOLD
            )
              return (
                <Ribbon
                  key={index}
                  buttonText={t("buttons.sign-now")}
                  description={`${t(
                    "storage-list.sign-1"
                  )} "${item.msdyn_name.substring(0, 8)}" ${t(
                    "storage-list.sign-2"
                  )}`}
                  onClick={() => {
                    navigate(
                      "/" +
                        URL.SIGN_STORAGE_CONTRACT_BASE +
                        "/" +
                        item.msdyn_warehouseid +
                        "/" +
                        item.arades_lagerplatz_typ +
                        "/" +
                        (item.arades_verzicht_auf_widerruf ? 0 : 1)
                    );
                  }}
                ></Ribbon>
              );
          })}

        {storageList.map((item, index) => {
          if (
            item.statuscode == STORAGE_STATUS.PENDING_FOR_PAYMENT &&
            MetalPart(item.msdyn_name) == METAL_CHARS.GOLD
          )
            return (
              <Ribbon
                buttonText={t("buttons.payment-info")}
                description={`${t(
                  "storage-list.payment-1"
                )} "${item.msdyn_name.substring(0, 7)}" ${t(
                  "storage-list.payment-2"
                )}`}
                onClick={() => {
                  navigate(
                    "/" + URL.STORAGE_PAYMENT_BASE + "/" + item.msdyn_name
                  );
                }}
              ></Ribbon>
            );
        })}
        <div className="container-card">
          <Box>
            <DataTableLeftEdit
              title={t("storage-list.title")}
              entityName={"msdyn_warehouse"}
              canAdd={true}
              disableAdd={
                !BYPASS_ADOBE_SIGN &&
                storageList.filter(
                  (item) =>
                    item.statuscode == STORAGE_STATUS.PENDING_FOR_CONTRACT
                ).length != 0
              }
              addPath={"/" + URL.ADDSTORAGE}
              canDelete={false}
              canEdit={true}
              canExport={true}
              nofetch={true}
              data={storageList}
              columns={[
                {
                  name: "metalName",
                  caption: t("storage-list.metal-type"),
                  cellRenderType: CELL_RENDER_TYPE.METAL_CELL,
                },
                { name: "msdyn_name", caption: t("storage-list.number") },
                /*{
                  name: "arades_verfuegbare_menge_formatted",
                  caption: t("storage-list.available-amount"),
                },*/
                {
                  name: "arades_menge_formatted",
                  caption: t("storage-list.amount"),
                },
                { name: "statusText", caption: t("storage-list.status") },
                /*{
                  name: "subStorageCount",
                  caption: t("storage-list.sub-storage"),
                },*/
                {
                  name: "msdyn_description",
                  caption: "",                  
                },
                /*{
                  name: "createdon_formatted",
                  caption: t("storage-list.created-on"),
                },*/
              ]}
            ></DataTableLeftEdit>
          </Box>
        </div>
      </LoadingOverlay>
    </>
  );
};
export default StorageList;
