import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { getMetalPriceFunc } from "../";
import { dateString, GermanFloat, Currency } from "../../../core/utils/convert";
import { storageIsPending, storageStatus } from "../../../core/utils/business";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { METAL_CHARS } from "../../../core/constants";

const getVermittlerStorages = async (isContact, vermittlerId, prices) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const contactsLink = `<link-entity name="contact" from="contactid" to="arades_besitzer" alias="c" link-type="inner" >
    <attribute name="contactid" />
    <attribute name="firstname" />
    <attribute name="lastname" />    
    <link-entity name="arades_titel" from="arades_titelid" to="arades_titel" alias="t" link-type="outer" >
      <attribute name="arades_name" />         
    </link-entity>
    <link-entity name="arades_vermittlerkonto" from="arades_vermittlerkontoid" to="arades_vermittlerkonto" link-type="inner" alias="ab">
      <filter type="and">
        <condition attribute="arades_firma" operator="eq" value="{${vermittlerId}}"/>
      </filter>
    </link-entity>
  </link-entity> `;

  const accountsLink = `<link-entity name="account" from="accountid" to="arades_besitzer" alias="a" link-type="inner" >
    <attribute name="name" />
    <link-entity name="arades_vermittlerkonto" from="arades_vermittlerkontoid" to="arades_vermittlerkonto" link-type="inner" alias="ad">
      <filter type="and">
        <condition attribute="arades_firma" operator="eq" value="{${vermittlerId}}"/>
      </filter>
    </link-entity>
  </link-entity>`;

  var fetchQuery = `
  <fetch mapping="logical" version="1.0">
    <entity name="arades_lagerplatz_person">
      <attribute name="arades_lagerplatz_personid" />
      <filter type="or">
        <condition attribute="arades_typ" operator="eq" value="100000003" />
        <condition attribute="arades_typ" operator="eq" value="100000001" />
      </filter>
      <link-entity name="msdyn_warehouse" from="msdyn_warehouseid" to="arades_lagerplatz" alias="s" link-type="inner">
        <attribute name="msdyn_warehouseid" />
        <attribute name="msdyn_name" />
        <attribute name="statuscode" />
        <attribute name="createdon" />
        <attribute name="arades_verzicht_auf_widerruf" />
        <attribute name="arades_einrichtungsgebuehr_beglichen" />
        <attribute name="arades_vertrag" />
        <attribute name="arades_vertrag_unterschrieben_am" />
        <link-entity name="msdyn_productinventory" from="msdyn_warehouse" to="msdyn_warehouseid" alias="sm" link-type="outer" >
          <attribute name="msdyn_productinventoryid" />
          <attribute name="arades_verfuegbare_menge" />
          <attribute name="arades_menge" />          
        </link-entity>
        <link-entity name="product" from="productid" to="arades_edelmetall_typ" alias="p" link-type="inner" >
          <attribute name="name" />
          <attribute name="productnumber" />          
        </link-entity>
      </link-entity> ${isContact ? contactsLink : accountsLink}
    </entity>
  </fetch>`;

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/arades_lagerplatz_persons?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  let list = [];
  result.data.data.value.map((item) => {
    let unitPrice =
      item["p.productnumber"] == "AU"
        ? prices.gold
        : item["p.productnumber"] == "AG"
        ? prices.silver
        : item["p.productnumber"] == "PT"
        ? prices.platin
        : item["p.productnumber"] == "PL"
        ? prices.palladium
        : 0;
    let tmp = {
      isPending: storageIsPending(item),
      storageNumber: item["s.msdyn_name"],
      arades_verfuegbare_menge: item["sm.arades_verfuegbare_menge"] ?? 0,
      arades_verfuegbare_menge_formatted: GermanFloat(
        item["sm.arades_verfuegbare_menge"] ?? 0
      ),
      arades_menge: item["sm.arades_menge"],
      arades_menge_formatted: GermanFloat(item["sm.arades_menge"]),
      arades_verzicht_auf_widerruf: item["s.arades_verzicht_auf_widerruf"],
      arades_einrichtungsgebuehr_beglichen:
        item["s.arades_einrichtungsgebuehr_beglichen"],
      arades_vertrag: item["s.arades_vertrag"],
      contractSignDate: item["s.arades_vertrag_unterschrieben_am"],
      storageStatus: storageStatus(item["s.statuscode"]),
      createDate: item["s.createdon"],
      createDate_formatted: dateString(item["s.createdon"]),
      customerId: item["c.contactid"], //account ham biad toosh
      customerName: item["c.firstname"]
        ? item["t.arades_name"]
          ? item["t.arades_name"] +
            " " +
            item["c.firstname"] +
            " " +
            item["c.lastname"]
          : "" + item["c.firstname"] + " " + item["c.lastname"]
        : item["a.name"],
      metalName: item["p.name"],
      productNumber: item["p.productnumber"],
      unitPrice: unitPrice,
      totalPrice: unitPrice * (item["sm.arades_verfuegbare_menge"] ?? 0),
      totalPrice_formatted: Currency(
        unitPrice * (item["sm.arades_verfuegbare_menge"] ?? 0)
      ),
    };
    list.push(tmp);
  });
  return list;
};

const getVermittlerStorageListFunc = async (filter, { getState }) => {
  let prices = { gold: 0, silver: 0, platin: 0, palladium: 0 };

  let priceResult = {};

  priceResult = await getMetalPriceFunc(
    { productName: METAL_CHARS.GOLD },
    { getState }
  );
  prices.gold = priceResult.amount;

  priceResult = await getMetalPriceFunc(
    { productName: METAL_CHARS.SILVER },
    { getState }
  );
  prices.silver = priceResult.amount;

  priceResult = await getMetalPriceFunc(
    { productName: METAL_CHARS.PLATIN },
    { getState }
  );
  prices.platin = priceResult.amount;

  priceResult = await getMetalPriceFunc(
    { productName: METAL_CHARS.PALLADIUM },
    { getState }
  );
  prices.palladium = priceResult.amount;

  var storagesOfContacts = await getVermittlerStorages(
    true,
    filter.vermittlerId,
    prices
  );
  var StoragesOfAccounts = await getVermittlerStorages(
    false,
    filter.vermittlerId,
    prices
  );

  return storagesOfContacts
    .concat(StoragesOfAccounts)
    .sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getVermittlerStorageListFunc;
