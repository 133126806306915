import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Box } from "@mui/system";
import React from "react";
import { CircularProgress, Typography } from "@mui/material";

const handleShowOverlay = ({ show }) =>
  !show &&
  css`
    display: none;
  `;

const LoadingBackground = styled(Box)`
  background-color: rgb(255, 255, 255);
  opacity: 0.75;
  position: absolute;
  inset: 0px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  ${handleShowOverlay}
`;
function LoadingOverlay({ children, show, message }) {
  return (
    <Box sx={{ position: "relative" }}>
      {children}
      <LoadingBackground show={show}>
        <Typography>{message}</Typography>
        <CircularProgress />
      </LoadingBackground>
    </Box>
  );
}

export default LoadingOverlay;
