import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { Currency } from "../../../core/utils/convert";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getRatiorDataFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let result = await axios.get(
    `${BASE_URL}data/arades_einstellungs/?` +
      `$select=*&$expand=` +
      `arades_lagerplatz_gold($select=_arades_edelmetall_typ_value,arades_name,msdyn_warehouseid)` +
      `,arades_lagerplatz_silber($select=_arades_edelmetall_typ_value,arades_name,msdyn_warehouseid)` +
      `,arades_lagerplatz_platin($select=_arades_edelmetall_typ_value,arades_name,msdyn_warehouseid)` +
      `,arades_lagerplatz_palladium($select=_arades_edelmetall_typ_value,arades_name,msdyn_warehouseid)` +
      `,arades_konto($select=arades_kontoid,arades_waehrung,arades_kontoinhaber,arades_iban,arades_bic,arades_bank,arades_kontonummer,arades_bankleitzahl)` +
      `,arades_konto_einmalige_lagergebuehr($select=arades_kontoid,arades_waehrung,arades_kontoinhaber,arades_iban,arades_bic,arades_bank,arades_kontonummer,arades_bankleitzahl)` +
      `,arades_Ratior_Vermittler($select=accountid,name,emailaddress1,address1_city,address1_line1,address1_postalcode,address1_line2,_arades_land_value)` +
      `,arades_ratior_ag($select=accountid,name,emailaddress1,address1_city,address1_line1,address1_postalcode,address1_line2,_arades_land_value)`,
    config
  );

  if (!result?.data?.status == 200 || !result?.data?.data?.value?.length > 0)
    throw Error("Firmendatenssatz könnte nicht geladen werden"); //("Ratior Account not fetched");

  const settingRow = result.data.data.value[0];
  if (
    !settingRow.arades_lagerplatz_gold ||
    !settingRow.arades_lagerplatz_silber ||
    !settingRow.arades_lagerplatz_platin ||
    !settingRow.arades_lagerplatz_palladium
  )
    throw Error("Lagerplätze könnte nicht geladen werden"); //("Ratior storages are not defined");

  const ratiorData = {
    ratiorBankAccount: settingRow.arades_konto,
    ratiorAGBankAccount: settingRow.arades_konto_einmalige_lagergebuehr,
    ratiorInfo: settingRow.arades_Ratior_Vermittler,
    ratiorAGInfo: settingRow.arades_ratior_ag,
    ratiorStorages: [
      {
        id: settingRow.arades_lagerplatz_gold?.msdyn_warehouseid,
        metal: settingRow.arades_lagerplatz_gold?._arades_edelmetall_typ_value,
        name: settingRow.arades_lagerplatz_gold?.msdyn_name,
      },
      {
        id: settingRow.arades_lagerplatz_silber?.msdyn_warehouseid,
        metal:
          settingRow.arades_lagerplatz_silber?._arades_edelmetall_typ_value,
        name: settingRow.arades_lagerplatz_silber?.msdyn_name,
      },
      {
        id: settingRow.arades_lagerplatz_platin?.msdyn_warehouseid,
        metal:
          settingRow.arades_lagerplatz_platin?._arades_edelmetall_typ_value,
        name: settingRow.arades_lagerplatz_platin?.msdyn_name,
      },
      {
        id: settingRow.arades_lagerplatz_palladium?.msdyn_warehouseid,
        metal:
          settingRow.arades_lagerplatz_palladium?._arades_edelmetall_typ_value,
        name: settingRow.arades_lagerplatz_palladium?.msdyn_name,
      },
    ],
    standardPriceLevelId: settingRow._arades_standardgebuehrentabelle_value,
    staticFee: settingRow.arades_einmalige_lagergebuhr
      ? settingRow.arades_einmalige_lagergebuhr
      : 0,
    transferToOtherFee: settingRow.arades_gebuehr_geschenk
      ? settingRow.arades_gebuehr_geschenk
      : 0,
    transferFee: settingRow.arades_gebuehr_transfer
      ? settingRow.arades_gebuehr_transfer
      : 0,
    buyFee: settingRow.arades_gebuehr_kauf ? settingRow.arades_gebuehr_kauf : 0,
    sellFee: settingRow.arades_gebuehr_verkauf
      ? settingRow.arades_gebuehr_verkauf
      : 0,
    staticFee_formatted: Currency(settingRow.arades_einmalige_lagergebuhr),
    ratiorAgAccountId: settingRow._arades_ratior_ag_value,
    ratiorGmbHAccountId: settingRow._arades_ratior_weisse_edelmetalle_value,
    ratiorGoldAccountId: settingRow._arades_ratior_gold_value,
    ratiorAgAccountName:
      settingRow[
        "_arades_ratior_ag_value@OData.Community.Display.V1.FormattedValue"
      ],
    ratiorGmbHAccountName:
      settingRow[
        "_arades_ratior_weisse_edelmetalle_value@OData.Community.Display.V1.FormattedValue"
      ],
    ratiorGoldAccountName:
      settingRow[
        "_arades_ratior_gold_value@OData.Community.Display.V1.FormattedValue"
      ],
  };

  return ratiorData;
};

export default getRatiorDataFunc;
