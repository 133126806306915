import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";
import { URL } from "../../core/constants";
import { BASE_URL } from "../../core/apiconfig/environment";
import { useApi } from "../../core/hooks/useApi";
import { CONTACT_ID } from "../../core/apiconfig/constantApi";

const CompanyList = () => {
  const [listData, setListData] = useState();
  const { get } = useApi();

  const loadData = async () => {
    let fetchQuery = `${BASE_URL}data/arades_mitarbeiters/?$select=arades_mitarbeiterid&$filter=_arades_person_value eq '${localStorage.getItem(
      CONTACT_ID
    )}'&$expand=arades_firma($select=*)`;
    const result = await get(fetchQuery);

    if (result.data && result.data.value) {
      let tempdata = [];
      result.data.value.map((l) => {
        let item = l.arades_firma;
        tempdata.push({
          accountid: item.accountid,
          name: item.name,
          telephone1: item.telephone1,
          telephone2: item.telephone2,
          address1_line1: item.address1_line1,
          createdon: item.createdon,
          address1_postalcode: item.address1_postalcode,
          address1_country: item.address1_country,
          address1_city: item.address1_city,
          emailaddress1: item.emailaddress1,
        });
      });
      setListData(tempdata);
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="container-card">
      <DataTable
        title={"Firmen"}
        entityName={"account"}
        data={listData}
        //canAdd={true}
        canDelete={false}
        canEdit={true}
        nofetch={true}
        columns={[
          { name: "name", caption: "Name" },
          { name: "telephone1", caption: "Telefon" },
          // { name: "address1_line1", caption: "Straße" },
          { name: "address1_postalcode", caption: "Postleitzahl" },
          { name: "address1_country", caption: "Land" },
          { name: "address1_city", caption: "Ort" },
          // { name: "emailaddress1", caption: "E-Mail" },
          { name: "createdon", caption: "Erstellt am" },
        ]}
      ></DataTable>
    </div>
  );
};
export default CompanyList;
