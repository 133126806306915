import { Avatar, AvatarGroup } from "@mui/material";
import React from "react";

function MaterialToAvatar({
  data: { spacing = 4, maxCount = 4, materialData },
}) {
  return (
    <>
      <AvatarGroup
        spacing={spacing}
        sx={{
          "&": {
            float: "left",
            ".MuiAvatar-root": {
              width: "20px",
              height: "20px",
              fontSize: "10px",
              fontWeight: "500",
              color: "#000",
              border: "none",
              lineHeight: "20px",
            },
            ".MuiAvatar-root:not(.main-avatar-fields)": {
              width: "auto",
              backgroundColor: "#000",
              color: "#fff",
              zIndex: 4,
              borderRadius: "10px",
              letterSpacing: "1.5px",
              paddingX: "5px",
            },
          },
        }}
        max={maxCount}
      >
        {Array.isArray(materialData) ? (
          materialData.map((item, index) => (
            <Avatar
              key={item.id}
              className="main-avatar-fields"
              sx={{ bgcolor: item.color, zIndex: index }}
              alt={item.label.toUpperCase()}
            >
              {item.label.toUpperCase()}
            </Avatar>
          ))
        ) : (
          <Avatar
            className="main-avatar-fields"
            sx={{ bgcolor: materialData.color }}
            alt={materialData.label.toUpperCase()}
          >
            {materialData.label.toUpperCase()}
          </Avatar>
        )}
      </AvatarGroup>
    </>
  );
}

export default MaterialToAvatar;
