import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { useSnackbar } from "notistack";
import PhoneInput from "../../common/inputs/phoneInput";
import Datepicker from "../../common/inputs/datepicker";
import Button from "../../common/buttons/button";
import { useNavigate } from "react-router-dom";
import back from "../../../assets/images/back.svg";
import backdisabled from "../../../assets/images/backdisabled.svg";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./registration.scss";
import InputCode from "../common/inputCode";
import ConfirmModal from "../../common/popups/confirmModal";
import { longDateTo10 } from "../../../core/utils/convert";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { Box, Typography } from "@mui/material";
import {
  CONTACT_BIRTHDATE,
  TOKEN_NAME,
  MOBILE_VERIFIED,
  MOBILE_NUMBER,
} from "../../../core/apiconfig/constantApi";
import { useRegisterState } from "../../../context/register/RegisterState";
import { resetCache } from "../../../core/utils/cachManager";
import { calculateAge } from "../../../core/utils/commonfuncs";
import eighteen from "../../../assets/images/eighteen.svg";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";

const MobileVerification = () => {
  const { post, patch, get } = useApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);
  const [timeEnded, setTimeEnded] = useState(false);
  const [counter, setCounter] = React.useState(0);
  const [counterText, setCounterText] = React.useState("01:00");
  const [showMobile, setShowMobile] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { personalInfo, personalInfoAction } = useRegisterState();
  const [mobilephone, setMobilephone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [token, setToken] = useState();

  useEffect(() => {}, [mobilephone]);
  useEffect(() => {}, [token]);
  useEffect(() => {
    if (localStorage.getItem(CONTACT_BIRTHDATE))
      setBirthDate(localStorage.getItem(CONTACT_BIRTHDATE));
  }, []);

  React.useEffect(() => {
    if (personalInfo && personalInfo.mobileNumber)
      setMobilephone(personalInfo.mobileNumber);
  }, []);

  React.useEffect(() => {
    if (counter == 0) {
      setTimeEnded(true);
    } else {
      setTimeEnded(false);

      const timerID = setInterval(() => {
        setCounter(counter - 1);
        let ctxt = (counter - 1).toString();
        if (counter - 1 < 10) ctxt = "0" + (counter - 1).toString();
        setCounterText("00:" + ctxt);
      }, 1000);
      return () => clearInterval(timerID);
    }
  }, [counter]);

  const handleChange = (event) => {
    const mn = event.target.value;
    personalInfoAction({ ...personalInfo, ["mobileNumber"]: mn });
    setMobilephone(mn);
  };

  const smsSent = async () => {
    let smsObj = {
      birthdate: birthDate,
      mobileNumber: mobilephone,
    };

    setLoading(true);
    let smsResult = await post(`${BASE_URL}verify/sms/send`, smsObj);
    setLoading(false);
    if (smsResult && smsResult.status != 200 && smsResult.status != 201) {
      let error = "";
      if (smsResult.data && smsResult.data.length > 0)
        error = smsResult.data[0].msg;
      else error = smsResult.statusText;
      enqueueSnackbar(error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      return false;
    } else if (
      smsResult &&
      (smsResult.status == 200 || smsResult.status == 201)
    )
      return true;
    else return false;
  };
  const sendCode = async () => {
    let age = calculateAge(birthDate);
    if (age < 18) setConfirmOpen(true);
    else {
      setActivationCode("");
      let success = await smsSent();
      if (success) {
        setCounter(60);
        setCounterText("01:00");
        setShowMobile(false);
      }
    }
  };

  const codeVerified = async () => {
    let smsObj = {
      code: activationCode,
    };

    let smsResult = await post(`${BASE_URL}verify/sms`, smsObj);
    if (
      smsResult &&
      smsResult.status != 200 &&
      smsResult.status != 201 &&
      smsResult.data &&
      smsResult.data.length > 0
    ) {
      enqueueSnackbar(smsResult.data[0].msg, {
        variant: "error",
        autoHideDuration: 20000,
      });
      return false;
    } else if (
      smsResult &&
      (smsResult.status == 200 || smsResult.status == 201)
    ) {
      localStorage.setItem(MOBILE_VERIFIED, "true");
      localStorage.setItem(MOBILE_NUMBER, mobilephone);
      localStorage.setItem(TOKEN_NAME, smsResult.data.token);
      setToken(smsResult.data.token);
      return true;
    } else return false;
  };
  const handleSubmit = async () => {
    setLoading(true);
    let success = codeVerified();
    setLoading(false);
    if (success) navigate(URL.ADDRESSVERIFICATION);
  };

  const formIsValid = () => {
    return mobilephone.length >= 12 && birthDate;
  };

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={confirmOpen}
        title={"Tut mir leid, du bist minderjährig"}
        imageSrc={eighteen}
        showCloseButton={true}
        message={
          "Wir können nichts für dich erstellen, weil du unter 18 bist. Aber du kannst deine Eltern bitten, ein Konto für dich zu erstellen."
        }
        okTitle={"AUSLOGGEN"}
        onOk={() => {
          resetCache();
          navigate(URL.LOGIN);
          window.location.reload();
        }}
        onClose={() => setConfirmOpen(false)}
      ></ConfirmModal>
      <div className="register-title">{"ANMELDUNG"}</div>
      <div className="step">Mobilnumber</div>
      <div className="register-box-wrapper">
        <div className="flex-column register-box">
          <div className="d-flex flex-column">
            {showMobile && (
              <>
                <div className="col-12 form-item padding-50">
                  <span className="span-2fa">
                    Legitimation für Sicherheit und zukünftige
                    Zwei-Faktor-Authentifizierung
                  </span>
                </div>
                <div className="col-12 form-item padding-20">
                  <Datepicker
                    maxDate={Date.now()}
                    name={"birthDate"}
                    value={birthDate}
                    onChange={(e) => {
                      setBirthDate(longDateTo10(e.target.value));
                    }}
                    label={"Geburtsdatum*"}
                    required={false}
                  />
                </div>

                <div className="col-12 form-item padding-20">
                  <PhoneInput
                    country="de"
                    name={"mobilephone"}
                    value={mobilephone}
                    onChange={handleChange}
                    label={"Mobilnumber:*"}
                    className="phone-input-small"
                  />
                </div>
              </>
            )}

            {!showMobile && (
              <>
                <div className="activation-text">
                  <span>
                    Wir haben Ihnen einen Code auf Ihre Telefonnummer <br />{" "}
                    gesendet:{" "}
                    <span className="phone-text">{mobilephone}&nbsp;</span>
                    <span
                      onClick={() => {
                        if (timeEnded) setShowMobile(true);
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          color: timeEnded ? "#3795FA" : "rgba(0, 0, 0, 0.38)",
                        }}
                        icon={faEdit}
                      />
                    </span>
                    .
                    <br />
                    Bitte geben Sie diesen hier ein:
                  </span>
                </div>
                <div className="d-flex">
                  <InputCode
                    length={4}
                    label="Code Label"
                    loading={loading}
                    clearMe={!activationCode}
                    onComplete={(code) => {
                      //setLoading(true);
                      //setTimeout(() => setLoading(false), 10000);
                      setActivationCode(code);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    disabled={!timeEnded}
                    onClick={() => {
                      if (timeEnded) sendCode();
                    }}
                    style={{
                      minWidth: "300px",
                      marginTop: "20px",
                      marginBottom: "10px",
                    }}
                    className={`btn-action ${
                      !timeEnded ? "disabled" : "enabled"
                    }`}
                  >
                    {"CODE ERNEUT SENDEN   " + counterText}
                  </Button>
                </div>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="caption">
                    You have to wait 1 minutes then you can change your number
                  </Typography>
                </Box>
              </>
            )}
          </div>
        </div>
        <div className="div-btn-register">
          {showMobile && (
            <div
              className="div-btn-register"
              style={{ paddingRight: 128, paddingTop: 80 }}
            >
              <div
                className="link-btn"
                onClick={() => {
                  resetCache();
                  navigate(URL.LOGIN);
                }}
              >
                <span>
                  <img src={back} className="imgback"></img>{" "}
                  {"ZURÜCK ZUR ANMELDUNG"}
                </span>
              </div>
              <Button
                disabled={!formIsValid()}
                className="btn-action"
                onClick={sendCode}
              >
                {"CODE SENDEN"}
              </Button>
            </div>
          )}

          {!showMobile && (
            <div
              className="div-btn-register"
              style={{ paddingRight: 128, paddingTop: 25 }}
            >
              <div
                disabled={!timeEnded}
                className={`link-btn ${!timeEnded ? "disabled" : ""}`}
                onClick={() => {
                  if (timeEnded) setShowMobile(true);
                }}
              >
                <span
                // style={{
                //   color: timeEnded ? "#1565c0" : "rgba(0, 0, 0, 0.38)",
                // }}
                >
                  <img
                    src={timeEnded ? back : backdisabled}
                    className="imgback"
                  ></img>{" "}
                  {"Nummer bearbeiten"}
                </span>
              </div>
              <Button
                disabled={!mobilephone || activationCode.length < 4}
                className={`btn-action ${
                  !mobilephone || activationCode.length < 4
                    ? "disabled"
                    : "enabled"
                }`}
                onClick={handleSubmit}
              >
                {"Continue"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default MobileVerification;
