import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getLoyaltyDataFunc = async (params, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.get(
    `${BASE_URL}data/arades_treuebonuses(${params.id})/`,
    config
  );
  if (!result?.data?.data) throw Error("Keine Loyalitätsmonate gefunden"); //("No Loyalty Month Data Found");

  return {
    name: result.data.data.arades_name,
    fromMonth: result.data.data.arades_ab,
    toMonth: result.data.data.arades_bis,
    feePercent: result.data.data.arades_reduzierter_abschlag,
    feePercent_formatted:
      result.data.data[
        "arades_reduzierter_abschlag@OData.Community.Display.V1.FormattedValue"
      ],
  };
};

export default getLoyaltyDataFunc;
