import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getCurrencyListAction = createAsyncThunk(
  "global/currencies",
  async (dataParams, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };
    const result = await axios.get(
      `${BASE_URL}data/transactioncurrencies/?$select=currencyname,transactioncurrencyid`,
      config
    );

    if (!result?.data?.status == 200 || !result?.data?.data?.value?.length > 0)
      throw Error("Währungen könnten nicht geladen werden"); //("Currencies not fetched");

    let list = [];
    result.data.data.value.map((item) => {
      list.push({
        key: item.transactioncurrencyid,
        value: item.currencyname,
      });
    });
    return list;
  }
);

export default getCurrencyListAction;
