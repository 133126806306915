import React, { useEffect, useState } from "react";
import { FormSteper } from "../common";
import { useTranslation } from "react-i18next";
import ProfitCalculatorStep from "./profitCalculator.step";
import EnterAmountStep from "./enterAmount.step";
import SelectStorageTypeStep from "./selectStorageType.step";
import SelectStorageStep from "./selectStorage.step";
import SummaryStep from "./summary.step";
import VerificationStep from "./Verification.step";
import VerificationPartnerStep from "./VerificationPartner.step";
import SelectSourceStorageStep from "./selectSourceStorage.step";

import { useDispatch, useSelector } from "react-redux";
import { resetAll } from "../../redux/slices/exchange/exchange.slice";
import { useNavigate, useParams } from "react-router-dom";
import { URL, WORKSPACETYPE } from "../../core/constants";
import { WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import {
  globalData,
  exchangeInfo,
  setActionType,
} from "../../redux/slices/exchange/exchange.slice";

function ExchangeStepManager() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const detailStorageId = useParams().storageid;
  const parentstorageid = useParams().parentstorageid;

  const stepsCount = 8;
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const exchangeInfoData = useSelector(exchangeInfo);

  const HandleCurrentStep = (step) => {
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(
      `/${parentstorageid ? URL.SUBSTORAGE : URL.STORAGE}/${
        parentstorageid ? parentstorageid + "/" : ""
      }${detailStorageId}`
    );
  };

  return (
    <div className="container-card">
      <FormSteper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        loading={loading}
        noFooter={[1, 2, 3, 4, 5, 6, 7, 8]}
        hasCancel={[1, 2, 3, 4, 5, 6, 7, 8]}
        noBackButton={[7, 8]}
        onCancel={handleCancel}
        headerTitles={[
          t("exchange.select-source"),
          exchangeInfoData.from
            ? `${t("exchange.change")} ${
                exchangeInfoData.from == "Gold"
                  ? t("exchange.gold")
                  : t("exchange.silver")
              } ${t("exchange.to")} ${
                exchangeInfoData.to == "Gold"
                  ? t("exchange.gold")
                  : t("exchange.silver")
              }`
            : "",
          t("exchange.enter-amount"),
          t("exchange.select-storage-type"),
          t("exchange.select-storage"),
          t("exchange.summary"),
          t("exchange.verification"),
          `${
            localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE
              ? t("exchange.other-guardian")
              : t("exchange.storage-partner")
          } `,
        ]}
      >
        {/* Step 1 */}
        <SelectSourceStorageStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 2 */}
        <ProfitCalculatorStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 3 */}
        <EnterAmountStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 4 */}
        <SelectStorageTypeStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 5 */}
        <SelectStorageStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 6 */}
        <SummaryStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 7 */}
        <VerificationStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 8 */}
        <VerificationPartnerStep handleCurrentStep={HandleCurrentStep} />
      </FormSteper>
    </div>
  );
}

export default ExchangeStepManager;
