import React, { useState } from "react";
import { FormSteper } from "../../../../common";

import BankAccountStep from "../../sharedSteps/bankAccount.step";
import StorageFeeStep from "../../storageFeeStep.step";
import StorageUnits from "../../sharedSteps/StorageUnits.step";
import StorageInformation from "../../sharedSteps/StorageInformation.step";
import GWG from "../../sharedSteps/gwg.step";
import PEP from "../../sharedSteps/pep.step";
import Verification from "../../sharedSteps/Verification.step";
import SignStorageContract from "../../sharedSteps/signStorageContract.step";
import StorageCreated from "../../sharedSteps/StorageCreated.step";
//import StoragePaymentStep from "../../sharedSteps/storagePaymentStep.step";
import Security from "./security.step";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { resetAll } from "../../../../../redux/slices/storage/storage.slice";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../../../core/constants";
import { storagePersonalInfo } from "../../../../../redux/slices/storage/storage.slice";
import { useTranslation } from "react-i18next";

function UnderAgeWith2FAStepManager({
  handleCurrentStep: handlerParentStep,
  parent,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const personalInfo = useSelector(storagePersonalInfo);

  const hasTwoLegalGauridans = personalInfo.hasTwoLegalGaurdians ? true : false;

  const stepsCount = hasTwoLegalGauridans ? 10 : 9;
  const headerTitles = [
    t("add-storage.storage-fee"),
    <div>
      &nbsp;{t("add-storage.bank-account-1")} <br></br> &nbsp;
      <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
        {t("add-storage.bank-account-2")}
      </span>
    </div>,
  ]
    .concat(
      hasTwoLegalGauridans
        ? [
            <div>
              &nbsp;{t("add-storage.security-1")} <br></br> &nbsp;
              <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
                {t("add-storage.security-2")}
              </span>
            </div>,
          ]
        : []
    )
    .concat([
      t("add-storage.storage-units"),
      t("add-storage.wait-14"),
      <div>
        &nbsp;{t("add-storage.gwg-1")}
        <br></br>
        &nbsp;{" "}
        <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
          {t("add-storage.gwg-2")}
        </span>
      </div>,
      <div>
        &nbsp;{t("add-storage.gwg-1")}
        <br></br>
        &nbsp;
        <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
          {t("add-storage.pep")}
        </span>
      </div>,
      t("add-storage.sign-contract"),
      "Zwei-Faktor-Authentifizierung",
      t("add-storage.storage-created"),
      //"Payment",
    ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HandleCurrentStep = (step, parent) => {
    if (parent) {
      handlerParentStep(parent);
    } else {
      if (isNaN(parseInt(step))) {
        if (step === "next" && currentStep < stepsCount) {
          setCurrentStep((prev) => prev + 1);
        } else if (step === "prev" && currentStep > 1) {
          setCurrentStep((prev) => prev - 1);
        }
      } else {
        if (currentStep <= stepsCount && currentStep >= 1) {
          setCurrentStep(step);
        }
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(`/${URL.STORAGE}`);
  };

  return (
    <>
      {hasTwoLegalGauridans ? (
        <>
          <FormSteper
            parent={parent}
            currentStep={currentStep}
            setCurrentStep={HandleCurrentStep}
            loading={loading}
            noBackButton={[9]}
            hasCancel={[1, 2, 3, 4, 5, 6, 7]}
            onCancel={handleCancel}
            noFooter={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            headerTitles={headerTitles}
          >
            {/* Step 1- PromotionCode */}
            <StorageFeeStep
              currentStep={currentStep}
              handleCurrentStep={HandleCurrentStep}
            />

            {/* Step 2- BankAccountStep */}
            <BankAccountStep handleCurrentStep={HandleCurrentStep} />

            {/* Step 3- Security */}
            <Security handleCurrentStep={HandleCurrentStep} />

            {/* Step 4- StorageType */}
            <StorageUnits handleCurrentStep={HandleCurrentStep} />

            {/* Step 5- StorageInformation */}
            <StorageInformation handleCurrentStep={HandleCurrentStep} />

            {/* Step 6- StorageInformation */}
            <GWG handleCurrentStep={HandleCurrentStep} />

            {/* Step 7- StorageInformation */}
            <PEP handleCurrentStep={HandleCurrentStep} />

            {/* Step 8- SignStorageContract*/}
            <SignStorageContract handleCurrentStep={HandleCurrentStep} />

            {/* Step 9- Verification*/}
            <Verification handleCurrentStep={HandleCurrentStep} />

            {/* Step 10- Storage created */}
            <StorageCreated handleCurrentStep={HandleCurrentStep} />

            {/* Step 11- StoragePaymentStep */}
            {/* <StoragePaymentStep handleCurrentStep={HandleCurrentStep} /> */}
          </FormSteper>
        </>
      ) : (
        <>
          <FormSteper
            parent={parent}
            currentStep={currentStep}
            setCurrentStep={HandleCurrentStep}
            loading={loading}
            noBackButton={[8, 9]}
            hasCancel={[1, 2, 3, 4, 5, 6, 7]}
            onCancel={handleCancel}
            noFooter={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
            headerTitles={headerTitles}
          >
            {/* Step 1- PromotionCode */}
            <StorageFeeStep
              currentStep={currentStep}
              handleCurrentStep={HandleCurrentStep}
            />

            {/* Step 2- BankAccountStep */}
            <BankAccountStep handleCurrentStep={HandleCurrentStep} />

            {/* Step 3- StorageType */}
            <StorageUnits handleCurrentStep={HandleCurrentStep} />

            {/* Step 4- StorageInformation */}
            <StorageInformation handleCurrentStep={HandleCurrentStep} />

            {/* Step 5- StorageInformation */}
            <GWG handleCurrentStep={HandleCurrentStep} />

            {/* Step 6- StorageInformation */}
            <PEP handleCurrentStep={HandleCurrentStep} />

            {/* Step 7- SignStorageContract*/}
            <SignStorageContract handleCurrentStep={HandleCurrentStep} />

            {/* Step 8- Verification*/}
            <Verification handleCurrentStep={HandleCurrentStep} />

            {/* Step 9- Storage created */}
            <StorageCreated handleCurrentStep={HandleCurrentStep} />

            {/* Step 10- StoragePaymentStep */}
            {/* <StoragePaymentStep handleCurrentStep={HandleCurrentStep} /> */}
          </FormSteper>
        </>
      )}
    </>
  );
}

export default UnderAgeWith2FAStepManager;
