import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { QUOTE_DIRECTION } from "../../../core/constants";

const getTransferedStorageListFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let fetchQuery = `${BASE_URL}data/quotes/?$select=quoteid&$filter=arades_richtung eq ${QUOTE_DIRECTION.GIFT} and _arades_verkaeufer_value eq '${filter.ownerId}'&$expand=customerid_contact($select=contactid,firstname,lastname),customerid_account($select=accountid,name),arades_lagerplatz_kaeufer($select=msdyn_warehouseid,msdyn_name,_arades_edelmetall_typ_value)`;

  const result = await axios.get(fetchQuery, config);
  if (result?.data?.status != 200 || !result?.data?.data?.value?.length > 0)
    return [];

  let recipiantlist = [];
  let i = 0;
  result.data.data.value.forEach((item) => {
    let recipiant = item.customerid_contact;
    let storage = item.arades_lagerplatz_kaeufer;
    let recipiantType = "contact";
    if (!recipiant) {
      recipiant = item.customerid_account;
      recipiantType = "account";
    }
    if (
      !recipiant ||
      !storage ||
      !(storage._arades_edelmetall_typ_value == filter.metal) ||
      (!(recipiantType == "contact" && recipiant.contactid) &&
        !(recipiantType == "account" && recipiant.accountid))
    )
      return;

    if (
      i < 5 &&
      recipiantlist.filter((x) => x.storageId == storage.msdyn_warehouseid)
        .length == 0
    ) {
      i++;
      let resultItem = {
        owner: recipiant,
        storage: storage,
        storageNumber: storage.msdyn_name,
        storageId: storage.msdyn_warehouseid,
      };
      if (recipiantType == "contact") {
        resultItem.owner = recipiant;
        resultItem.ownerName = recipiant.firstname + " " + recipiant.lastname;
        resultItem.ownerId = recipiant.contactid;
        resultItem.type = "contact";
      } else if (recipiantType == "account") {
        // resultItem.ownerId = recipiant.accountid;
        // resultItem.ownerName = recipiant.name;
        // resultItem.type = "account";
      }
      if (resultItem.ownerId && resultItem.storageId)
        recipiantlist.push(resultItem);
    }
  });

  return recipiantlist;
};

export default getTransferedStorageListFunc;
