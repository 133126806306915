import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL, BASE_URL_UI } from "../../../core/apiconfig/environment";
import { useSnackbar } from "notistack";
import { LoadingOverlay, Button, Input, ConfirmModal } from "../../common";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import "../forgetPass/forgetPass.scss";

import { resetRatiorKeyAction } from "../../../redux/slices/auth";
import { globalData, resetAll } from "../../../redux/slices/auth/auth.slice";

const ForgetRatiorKey = ({ title, subTitle }) => {
  const { post } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const authGlobalData = useSelector(globalData);
  const { t } = useTranslation();

  const requiredMsg = "validation.required";
  const emailIsValidMsg = "validation.email-invalid";

  const schema = yup.object().shape({
    email: yup.string().email(emailIsValidMsg).required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(resetRatiorKeyAction(data));
  };

  useEffect(() => {
    if (
      authGlobalData.actionStatus &&
      authGlobalData.actionType === "resetRatiorKey"
    ) {
      // enqueueSnackbar("Ratior key reset successfully", {
      //   variant: "success",
      //   autoHideDuration: 3000,
      // });
      setConfirmOpen(true);
      dispatch(resetAll());
    }
  }, [authGlobalData.actionStatus]);

  useEffect(() => {
    if (authGlobalData.error) {
      // enqueueSnackbar(authGlobalData.error + ERROR_CONTACT_US, {
      //   variant: "error",
      //   autoHideDuration: 20000,
      // });
      setConfirmOpen(true);
      dispatch(resetAll());
    }
  }, [authGlobalData.error]);

  useEffect(() => {
    setLoading(authGlobalData.loading);
  }, [authGlobalData.loading]);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <ConfirmModal
        open={confirmOpen}
        title="Ratior Key wurde erfolgreich versendet"
        showCloseButton={false}
        size="lg"
        message={
          <div>
            Wir haben Ihre Anfrage nach Ihrem RatiorKey erhalten. <br></br>
            Sollte uns Ihre eingegebene Email Adresse bekannt sein, dann haben
            wir Ihnen eine E-Mail mit Ihrem RatiorKey zugeschickt. Bitte prüfen
            Sie Ihren Posteingang und gegeben falls auch Ihren SPAM Ordner.
            <br></br>
            <br></br>
            Sollten Sie keine Email erhalten haben, dann nehmen Sie bitte mit
            dem Ratior Kundenservice kontakt auf.
            <br></br>
            E-Mail: Kunden@Ratior.de
            <br></br>
            Telefon: +49-(0)6227-65506-11
          </div>
        } //"Wir haben Ihr Ratior Key an Ihrer E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihre E-Mail."
        okTitle="LOGINSEITE" //{"LOGIN PAGE"}
        onOk={() => navigate(URL.LOGIN)}
        onClose={() => setConfirmOpen(false)}
      ></ConfirmModal>
      <LoadingOverlay show={loading}>
        <div className="box-wrapper">
          <div className="box-title">{title}</div>
          <div className="step">{subTitle}</div>
          <div className="box">
            <div className="form-item">
              <Input
                control={control}
                error={Boolean(errors.email)}
                helperText={t(errors.email?.message)}
                name="email"
                value={email}
                onChange={handleChange}
                label={t("personal-info.email-address")}
                placeholder="Exp: mail@website.com"
                className="form-item"
              />
            </div>
            <div className="padding-30">
              <Button
                disabled={!email}
                className={"form-item"}
                onClick={handleSubmit(onSubmit)}
              >
                RATIORKEY VERSENDEN
              </Button>
            </div>
            <div className="d-flex justify-content-center">
              <span className="down-description">
                Wenn sie keinen Zugang zu ihrem Account haben, bitte melden Sie
                sich unter Kunden@Ratior.de, um ihr Account wiederherzustellen
              </span>
            </div>
            <hr className="margin-10"></hr>
            <div className="anotherLogin">
              <span
                onClick={() => {
                  navigate(URL.LOGIN);
                }}
              >
                Login mit anderen Account
              </span>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default ForgetRatiorKey;
