import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { dateString } from "../../../core/utils/convert";
import { storageIsPending, storageStatus } from "../../../core/utils/business";
import { WORKSPACETYPE, STORAGE_PERSON_TYPE } from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getStorageListWithSubstoragesFunc = async (filter, { getState }) => {
  const { workspaceId, workspaceType } = filter;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  //parichehr: add productinventory to ths action , and use this action in select source storage of all transactions

  var fetchQuery = `
  <fetch mapping="logical" version="1.0" >
    <entity name="arades_lagerplatz_person">
      <attribute name="arades_lagerplatz_personid" />
      <attribute name="arades_lagerplatz" />      
      <filter type="and">
        <condition attribute="arades_besitzer" operator="eq" value="${workspaceId}" />
        <filter type="or">
          <condition attribute="arades_typ" operator="eq" value="${
            workspaceType != WORKSPACETYPE.UNDER_AGE
              ? STORAGE_PERSON_TYPE.OWNER
              : STORAGE_PERSON_TYPE.UNDER_AGE
          }" />
          <condition attribute="arades_typ" operator="eq" value="${
            STORAGE_PERSON_TYPE.OTHER_PERSON
          }" />
        </filter>
      </filter>   
      <link-entity name="msdyn_warehouse" from="msdyn_warehouseid" to="arades_lagerplatz" alias="s" link-type="inner">
        <attribute name="msdyn_warehouseid"/>
        <attribute name="msdyn_name"/>
        <attribute name="statuscode"/>
        <attribute name="statecode"/>
        <attribute name="arades_uebergeordneter_lagerplatz"/>
        <attribute name="createdon"/>
        <attribute name="arades_edelmetall_typ" />
        <attribute name="arades_gewichtseinheit" />
        <attribute name="arades_verzicht_auf_widerruf"/>
        <attribute name="arades_einrichtungsgebuehr_beglichen"/>
        <attribute name="arades_vertrag"/>
        <link-entity name="msdyn_warehouse" from="arades_uebergeordneter_lagerplatz" to="msdyn_warehouseid" alias="sub" link-type="outer" >
          <attribute name="msdyn_warehouseid" />
          <attribute name="msdyn_name" />
          <attribute name="statuscode"/>
          <attribute name="statecode"/>
          <attribute name="msdyn_description" />
          <attribute name="arades_uebergeordneter_lagerplatz" />
          <attribute name="createdon" />
          <attribute name="arades_edelmetall_typ" />
          <attribute name="arades_gewichtseinheit" />
          <attribute name="arades_verzicht_auf_widerruf" />
          <attribute name="arades_einrichtungsgebuehr_beglichen" />
          <attribute name="arades_vertrag"   />
        </link-entity>
      </link-entity> 
    </entity>  
  </fetch>`;

  // <attribute name="arades_edelmetall_typ" />
  // <attribute name="arades_gewichtseinheit" />

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/arades_lagerplatz_persons?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  let list = [];
  result.data.data.value.map((item) => {
    if (!list.find((x) => x.msdyn_warehouseid == item["s.msdyn_warehouseid"])) {
      let tmp = {
        msdyn_warehouseid: item["s.msdyn_warehouseid"],
        msdyn_name: item["s.msdyn_name"],
        statuscode: item["s.statuscode"],
        statecode: item["s.statecode"],
        weightUnit:
          item[
            "s.arades_gewichtseinheit@OData.Community.Display.V1.FormattedValue"
          ],
        arades_verzicht_auf_widerruf: item["s.arades_verzicht_auf_widerruf"],
        arades_einrichtungsgebuehr_beglichen:
          item["s.arades_einrichtungsgebuehr_beglichen"],
        arades_vertrag: item["s.arades_vertrag"],
        storageStatus: storageStatus(item["s.statuscode"]),
        createdon: item["s.createdon"],
        createDate_formatted: dateString(item["s.createdon"]),
        metalId: item["s.arades_edelmetall_typ"],
        metalName:
          item[
            "s.arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"
          ],
        subStorageList: [],
      };

      tmp.pending = storageIsPending(tmp);

      let substorages = result.data.data.value.filter(
        (x) =>
          x["s.msdyn_warehouseid"] == item["s.msdyn_warehouseid"] &&
          x["sub.arades_uebergeordneter_lagerplatz"]
      );
      for (let i = 0; i < substorages?.length; i++) {
        let subItem = substorages[i];
        let subTmp = {
          msdyn_warehouseid: subItem["sub.msdyn_warehouseid"],
          msdyn_name: subItem["sub.msdyn_name"],
          msdyn_description: subItem["sub.msdyn_description"],
          statuscode: subItem["sub.statuscode"],
          statecode: subItem["sub.statecode"],
          weightUnit:
            subItem[
              "sub.arades_gewichtseinheit@OData.Community.Display.V1.FormattedValue"
            ],
          arades_verzicht_auf_widerruf:
            subItem["sub.arades_verzicht_auf_widerruf"],
          arades_einrichtungsgebuehr_beglichen:
            subItem["sub.arades_einrichtungsgebuehr_beglichen"],
          arades_vertrag: subItem["sub.arades_vertrag"],
          storageStatus: storageStatus(subItem["sub.statuscode"]),
          createdon: subItem["sub.createdon"],
          createDate_formatted: dateString(subItem["sub.createdon"]),
          metalId: subItem["sub.arades_edelmetall_typ"],
          metalName:
            subItem[
              "sub.arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"
            ],
        };

        subTmp.pending = storageIsPending(subTmp);
        tmp.subStorageList.push(subTmp);
      }

      list.push(tmp);
    }
  });
  return list.sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getStorageListWithSubstoragesFunc;
