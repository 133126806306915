import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { DEPOSIT_DIRECTION } from "../../../core/constants";
import { Currency, dateString, GermanFloat } from "../../../core/utils/convert";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getDepositList = async (isContact, vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  let entityName = isContact ? "contact" : "account";
  let contactAttributes = `
  <attribute name="firstname"/>
  <attribute name="lastname"/>
  <attribute name="arades_titel"/>
  `;
  let accountAttributes = `
  <attribute name="name"/>
  `;

  var fetchQuery = `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="salesorder">
      <attribute name="customerid"/>
      <attribute name="salesorderid"/>
      <attribute name="arades_richtung"/>
      <attribute name="arades_ueberweisungsbetrag"/>
      <attribute name="arades_lagerplatz_kaeufer"/>
      <attribute name="arades_datum_geldeingang"/>
      <attribute name="arades_einzelpreis"/>
      <attribute name="createdon"/>      
      <order attribute="createdon" descending="true"/>
      <filter type="and">        
        <condition attribute="arades_richtung" operator="eq" value="100000000"/>
        <condition attribute="arades_lagerplatz_kaeufer" operator="not-null"/>
      </filter>
      <link-entity name="${entityName}" from="${entityName}id" to="customerid" link-type="inner" alias="entity">
        ${isContact ? contactAttributes : accountAttributes}
        <link-entity name="arades_vermittlerkonto" from="arades_vermittlerkontoid" to="arades_vermittlerkonto" link-type="inner" alias="ak">
          <filter type="and">
            <condition attribute="arades_firma" operator="eq" value="${vermittlerId}"/>
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`;

  //<attribute name="arades_zahlungsverkehrid"/>
  //<attribute name="arades_ueberweisungsdatum"/>
  //<attribute name="arades_betrag"/>
  //<attribute name="arades_lagerplatz"/>
  //<attribute name="arades_kunde"/>
  //<attribute name="arades_richtung"/>

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/salesorders?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200)
    throw Error("Kundenzahlungen lListe nönnte nicht geladen werden"); //("customer deposit list not fetched");

  return result?.data?.data?.value;
};

const getCustomerDepositListFunc = async (params, { getState }) => {
  const { vermittlerId } = params;

  let contactsDeposits = (await getDepositList(true, vermittlerId)) ?? [];
  let accountsDeposits = (await getDepositList(false, vermittlerId)) ?? [];

  let list = [];
  var deposits = contactsDeposits.concat(accountsDeposits);

  deposits.map((item) => {
    let tmp = {
      arades_ueberweisungsdatum: dateString(item.arades_datum_geldeingang),
      arades_richtung: item.arades_richtung,
      arades_richtung_title:
        item.arades_richtung == DEPOSIT_DIRECTION.OUTGOING
          ? "Outgoing"
          : "Incomming",
      arades_betrag: item.arades_ueberweisungsbetrag,
      arades_betrag_formatted: Currency(item.arades_ueberweisungsbetrag),
      unit_price: item.arades_einzelpreis,
      unit_price_formatted: Currency(item.arades_einzelpreis),
      createdon: item.createdon,
      createdon_formatted: dateString(item.createdon),
      storageNumber:
        item[
          "_arades_lagerplatz_kaeufer_value@OData.Community.Display.V1.FormattedValue"
        ],
    };

    tmp.customerName = item["entity.firstname"] //customer is contact otherwise is account
      ? (item["entity.arades_titel"]
          ? item[
              "entity.arades_titel@OData.Community.Display.V1.FormattedValue"
            ] + " "
          : "") +
        item["entity.firstname"] +
        " " +
        item["entity.lastname"]
      : item["entity.name"];

    list.push(tmp);
  });

  return list;
};
export default getCustomerDepositListFunc;
