import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { FormSteperStructure, LoadingOverlay, Select } from "../common";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedStorageAndSubStoragesAction,
  getStorageListWithSubstoragesAction,
  getActionCodeCustomerDataAction,
} from "../../redux/slices/transfer";
import {
  transferSource,
  setSelectedSource,
} from "../../redux/slices/transfer/transfer.slice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { WORKSPACE_ID, WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import { ACTION_CODE_TYPE } from "../../core/constants";
import { useTranslation } from "react-i18next";

function SelectSourceStorageStep({ handleCurrentStep }) {
  const requiredMsg = "erforderlich";
  const [loading, setLoading] = useState(false);
  const detailStorageId = useParams().storageid;
  const transferSourceData = useSelector(transferSource);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    dispatch(
      getStorageListWithSubstoragesAction({
        workspaceId: localStorage.getItem(WORKSPACE_ID),
        workspaceType: localStorage.getItem(WORKSPACE_TYPE),
      })
    );

    dispatch(
      getActionCodeCustomerDataAction({
        customerId: localStorage.getItem(WORKSPACE_ID),
        actionCodeType: ACTION_CODE_TYPE.TRANSFER,
        justActive: true,
      })
    );

    //parichehr :  read from the new action used for disable enable
    dispatch(getFormattedStorageAndSubStoragesAction(detailStorageId));
  }, []);

  useEffect(() => {
    if (transferSourceData?.options?.length) setLoading(false);
  }, [transferSourceData?.options]);

  const schema = yup.object().shape({
    fromStorageId: yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      fromStorageId: transferSourceData.fromStorageId,
    },
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
    let selectedStorage = transferSourceData?.options?.filter(
      (x) => x.key == data.fromStorageId
    )[0];
    dispatch(
      setSelectedSource({
        fromStorageId: data.fromStorageId,
        fromStorage: selectedStorage.item,
        mainStorageId: selectedStorage.mainStorageId,
      })
    );
    handleCurrentStep("next");
  };

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ minWidth: "50%", margin: "0 auto" }}>
          <Select
            control={control}
            error={Boolean(errors.fromStorageId)}
            helperText={errors.fromStorageId?.message}
            name="fromStorageId"
            label={t("transfer.select-source")}
            options={transferSourceData.options}
          />
        </Box>
        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectSourceStorageStep;
