import React from "react";
import NectRedirect from "../nect/nectRedirect";
import { Typography, Box, Button } from "@mui/material";
import { BASE_URL_UI } from "../../../core/apiconfig/environment";

const Ribbon = ({
  description,
  buttonText,
  onClick,
  showNect,
  key,
  height,
}) => {
  return (
    <Box
      key={key}
      sx={{
        backgroundColor: "#E3F2FD",
        height: height || 68,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "72px",
        paddingRight: "72px",
        width: "100%",
      }}
    >
      <Typography
        as="div"
        sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)" }}
      >
        {description}
      </Typography>
      {buttonText && (
        <Button
          onClick={onClick}
          sx={{
            backgroundColor: "info.main",
            maxWidth: 450,
            maxHeight: "36px",
          }}
          variant="contained"
        >
          {buttonText}
        </Button>
      )}
      {showNect && (
        <>
          <NectRedirect
            style={{ marginTop: 13 }}
            title="WEITER ZUR IDENTIFKATION"
            redirectUrl={BASE_URL_UI + "?first-login=true"}
          ></NectRedirect>
        </>
      )}
    </Box>
  );
};

export default Ribbon;
