import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./input.scss";
const InputUpload = ({
  label,
  width,
  height,
  value,
  onChange,
  onAction,
  placeholder,
  id,
  name,
  disabled,
  style,
  className,
  accept,
  ...rest
}) => {
  return (
    <div>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
        className={`input ${className}`}
      >
        <InputBase
          id={id}
          name={name}
          autoComplete={"none"}
          value={value}
          disabled={disabled}
          type="text"
          placeholder={placeholder}
          {...rest}
          sx={{ ml: 1, flex: 1 }}
          // placeholder="Choose your file"
          // inputProps={{ "aria-label": "Choose your file" }}
        />
        <Button variant="text" component="label">
          <FontAwesomeIcon
            icon={faUpload}
            style={{ color: "rgba(0, 0, 0, 0.6)" }}
          />
          <input type="file" accept={accept} hidden onChange={onChange} />
        </Button>
      </Paper>
    </div>
  );
};
export default InputUpload;
