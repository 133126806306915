import { Box, Grid, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Select from "../common/inputs/select";
import { FormSteperStructure, ConfirmModal, RadioListIcon } from "../common";
import { URL } from "../../core/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LegalGuardianOrNotStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [confirmOrNot, setConfirmOrNot] = useState(false);
  const [isLegalGaurdian, setIsLegalGaurdian] = useState("yes");

  const legalOptions = [
    {
      key: "yes",
      value: "Ich bin erziehungsberechtigt für den Minderjährigen",
    },
    {
      key: "no",
      value: "Ich bin NICHT erziehungsberechtigt für den Minderjährigen",
    },
  ];

  const radioList = [
    {
      value: "no",
      label: "Bei mir Unterlagerplatz für den Minderjährigen einrichten",
    },
    {
      value: "yes",
      label: "Weiter als Erziehungsberechtigter",
    },
  ];

  const submitForm = (data) => {
    if (isLegalGaurdian == "no") setConfirmOrNot(true);
    else handleCurrentStep("next");
  };

  return (
    <Box>
      <ConfirmModal
        noImage={true}
        open={confirmOrNot}
        showCloseButton={false}
        title="Sind Sie sicher?"
        size="lg"
        message={
          <Box
            sm={12}
            sx={{
              margin: "0 auto",
              width: "100%",
              "& span": {
                marginRight: "0 !important",
              },
            }}
          >
            <Typography
              variant="subtitle2"
              mb="13px"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Wenn Sie nicht erziehungsberechtigt sind, können Sie für den
              Minderjährigen keine Verträge abschließen.
              <br></br>
              Sie haben in dem Fall die Möglichkeit, bei sich Unterlagerplätze
              für den Minderjährigen einrichten und Edelmetall für ihn kaufen.
              Dabei ist zu berücksichtigen, dass dann Sie der Eigentümer
              (wirtschaftlich Berechtigte) an dem Edelmetall sind, und nicht der
              Minderjährige. Wenn das Edelmetall später (z.B. bei Erreichen der
              Volljährigkeit) in sein Eigentum übergehen soll, bedarf es einer
              Schenkung, die eventuell steuerliche Auswirkungen für Sie haben
              kann. Gleiches ist für den Erbschaftsfall zu bedenken.
            </Typography>

            <RadioListIcon
              currentValue={isLegalGaurdian}
              handleSetCurrentValue={(value) => {
                setIsLegalGaurdian(value);
              }}
              data={radioList}
            />
          </Box>
        }
        okTitle="WEITER"
        onOk={() => {
          if (isLegalGaurdian == "yes") {
            setConfirmOrNot(false);
            handleCurrentStep("next");
          } else navigate("/" + URL.STORAGE);
        }}
      ></ConfirmModal>
      <Box
        sm={12}
        sx={{
          margin: "0 auto",
          width: "100%",
          borderRadius: "8px",
          backgroundColor: "#E3F2FD",
          padding: 10,
          marginBottom: 10,
        }}
      >
        <Typography
          mb="13px"
          as="div"
          sx={{
            fontSize: "16px",
          }}
        >
          Für einen Minderjährigen können nur der/die Erziehungsberechtigten
          Verträge über Lagerplätze und Edelmetallkäufe schließen.
        </Typography>
      </Box>
      <Typography
        variant="subtitle2"
        mb="50px"
        sx={{
          fontSize: "16px",
          fontWeight: "400",
        }}
      >
        Bitte geben Sie an, ob sie erziehungsberechtigt sind:
      </Typography>

      <Box sx={{ maxWidth: "50%", margin: "0 auto" }}>
        <Grid spacing={8} container direction="column">
          <Grid xs={12} lg={8} item>
            <Select
              label={"Erstellung von Ratior-Bereich"}
              value={isLegalGaurdian}
              options={legalOptions}
              onChange={(e) => setIsLegalGaurdian(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <FormSteperStructure.Footer>
        <Button
          onClick={submitForm}
          fullWidth
          sx={{
            backgroundColor: "info.main",
            maxWidth: "192px",
            marginTop: "30px",
          }}
          variant="contained"
        >
          {t("buttons.next")}
        </Button>
      </FormSteperStructure.Footer>
    </Box>
  );
}

export default LegalGuardianOrNotStep;
