import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { dateString } from "../../../core/utils/convert";
//import { zeroPad } from "../../../core/utils/commonfuncs";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { ACTION_CODE_TYPE } from "../../../core/constants";

const getActionCodeListAction = createAsyncThunk(
  "vermittlerDashboard/getActionCodeList",
  async (params, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let fetchQuery = `
    <fetch mapping="logical" version="1.0">
      <entity name="arades_aktionscode">        
        <attribute name="arades_aktionscodeid" alias="arades_aktionscodeid"  />
        <attribute name="arades_typ" alias="arades_typ"  />
        <attribute name="arades_anlas" alias="arades_anlas"  />
        <attribute name="arades_treue_monate" alias="arades_treue_monate"  />
        <attribute name="arades_aktionscode_limitiert" alias="arades_aktionscode_limitiert"  />
        <attribute name="arades_aktionscode_unbegrenzt_gueltig" alias="arades_aktionscode_unbegrenzt_gueltig"  />
        <attribute name="arades_name" alias="arades_name" />  
        <attribute name="createdon" alias="createdon"  />
        <attribute name="arades_startdatum" alias="arades_startdatum" />	     
        <attribute name="arades_enddatum" alias="arades_enddatum"  />
        <attribute name="arades_prozent" alias="arades_prozent" />
        <attribute name="arades_maximum_anzahl" alias="arades_maximum_anzahl" />
        <attribute name="arades_aktuelle_anzahl" alias="arades_aktuelle_anzahl" />
        <attribute name="arades_firma" alias="arades_firma" />
        <filter>
          <condition attribute="arades_firma" operator="eq" value="${params.ownerId}" /> 
          <condition attribute="statecode" operator="eq" value="0" /> 
          <condition attribute="arades_typ" operator="ne" value="${ACTION_CODE_TYPE.TRANSFER}" />        
        </filter>      
      </entity>
    </fetch>`;

    var encodedFetchXml = encodeURI(fetchQuery);
    const result = await axios.get(
      `${BASE_URL}data/arades_aktionscodes?fetchXml=${encodedFetchXml}`,
      config
    );

    let list = [];
    if (result?.data?.status != 200)
      throw Error("Aktionscode könnte nicht geladen werden"); //("Action Codes not fetched");

    let toShowList = result.data.data.value.sort((a, b) =>
      a?.createdon >= b?.createdon ? -1 : 1
    );

    toShowList.map((item) => {
      let tmp = {
        id: item.arades_aktionscodeid,
        actionCode: item.arades_name,
        discount: (item.arades_prozent ? item.arades_prozent : 0) + "%",
        allowRegisterCount: item.arades_maximum_anzahl,
        registeredCount: item.arades_aktuelle_anzahl,
        loyalityMonthCount: item.arades_treue_monate,
        usersUnLimited: item.arades_aktionscode_limitiert == false,
        validForever: item.arades_aktionscode_unbegrenzt_gueltig,
        arades_typ: item.arades_typ,
        startDate: dateString(item.arades_startdatum),
        endDate: dateString(item.arades_enddatum),
        description: item.arades_anlas,
      };
      list.push(tmp);
    });

    return list;
  }
);

export default getActionCodeListAction;

//code sample for having and count
{
  /* <fetch mapping="logical" aggregate="true"  version="1.0">
<entity name="arades_aktionscode">        
  <attribute name="arades_aktionscodeid" alias="arades_aktionscodeid" groupby="true" />
  <attribute name="arades_typ" alias="arades_typ" groupby="true" />
  <attribute name="createdon" alias="createyear" groupby="true" dategrouping="year" />
	<attribute name="createdon" alias="createmonth" groupby="true" dategrouping="month" />
	<attribute name="createdon" alias="createday" groupby="true" dategrouping="day" />
  <filter>
    <condition attribute="arades_firma" operator="eq" value="${ownerId}" /> 
    <condition attribute="statecode" operator="eq" value="0" />         
  </filter>
  <link-entity name="contact" from="arades_aktionscode" to="arades_aktionscodeid" link-type="outer">
    <attribute name="contactid" alias="usedCount" aggregate="countcolumn" />
  </link-entity>
</entity>
</fetch>`; 

 let toShowList = result.data.data.value.sort((a, b) =>
      zeroPad(a?.createyear, 4) +
        "-" +
        zeroPad(a?.createmonth, 2) +
        "-" +
        zeroPad(a?.createday, 0) >=
      zeroPad(b?.createyear, 4) +
        "-" +
        zeroPad(b?.createmonth, 2) +
        "-" +
        zeroPad(b?.createday, 0)
        ? -1
        : 1
    );
  */
}
