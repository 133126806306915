import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getCompanyDataFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(
    `${BASE_URL}data/accounts(${filter.id})/`,
    config
  );

  if (result?.data?.status != 200) throw Error("Firma wurde nicht gefunden"); //("Company not found");
  let company = result.data.data;
  company.signedContract = company.arades_vermittler_vertraege_unterschrieben;

  company.canBecomeSeller =
    company.statuscode == "100000000" &&
    company.arades_firmentyp != 100000001 &&
    company.arades_vermittlerwerden;

  return company;
};

export default getCompanyDataFunc;
