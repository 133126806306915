import { createSlice } from "@reduxjs/toolkit";
import { getTransactionDetailedListAction, cancelSellAction } from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  detailedList: [],
};
export const transactionListSlice = createSlice({
  name: "transactionList",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //get Transaction Detailed List Action
    [getTransactionDetailedListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getTransactionDetailedListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.detailedList = action.payload;
      state.globalData.actionType = "getDetailedList";
    },
    [getTransactionDetailedListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //cancel sell process
    [cancelSellAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [cancelSellAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "cancelSell";
    },
    [cancelSellAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const fullData = (state) => state.transactionList;
export const globalData = (state) => state.transactionList.globalData;

export const transactionDetailedList = (state) =>
  state.transactionList.detailedList;

export const { resetError, resetActionStatus, resetAll } =
  transactionListSlice.actions;
export default transactionListSlice.reducer;
