import React from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
// import "./input.scss";
import i18next from "i18next";
const Datepicker = ({
  id,
  label,
  disabled,
  name,
  value,
  placeholder,
  onChange,
  register,
  className,
  width,
  height,
  style,
  maxDate,
  renderInput,
  control = null,
  disablePast = false,
  minDate = null,
  ...rest
}) => {
  const handleChange = (e) => {
    let event = { target: { name: name, value: e } };
    onChange(event);
  };

  const maskMap = {
    en: "__/__/____",
    de: "__.__.____",
  };
  const inputFormatMap = {
    en: "dd/MM/yyyy",
    de: "dd.MM.yyyy",
  };
  const currentLanguage = i18next.language || window.localStorage.i18nextLng;

  return (
    <div className="d-flex ml-4 mr-4 flex-column ">
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({
            field: {
              onChange: formOnChange,
              onBlur: formOnBlur,
              value: formValue,
              name: formName,
              ref: fromRef,
            },
            fieldState: { invalid, isTouched, isDirty, error: formError },
            formState,
          }) => (
            <DesktopDatePicker
              id={id}
              inputFormat={inputFormatMap[currentLanguage]}
              mask={maskMap[currentLanguage]}
              label={label}
              value={formValue}
              disabled={disabled}
              onChange={formOnChange}
              name={formName}
              renderInput={(params) =>
                renderInput ? (
                  renderInput(params)
                ) : (
                  <TextField
                    {...params}
                    onBlur={formOnBlur}
                    inputRef={fromRef}
                  />
                )
              }
              maxDate={maxDate}
              disablePast={disablePast}
              // minDate={minDate}
            />
          )}
        />
      ) : (
        <DesktopDatePicker
          id={id}
          name={name}
          inputFormat={inputFormatMap[currentLanguage]}
          mask={maskMap[currentLanguage]}
          label={label}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          required={false}
          renderInput={
            renderInput ? renderInput : (params) => <TextField {...params} />
          }
          maxDate={maxDate}
        />
      )}
    </div>
  );
};

export default Datepicker;
