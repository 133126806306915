import { createTheme } from "@mui/material/styles";
import { deDE, enUS } from "@mui/material/locale";
import i18next from "i18next";
const currentLanguage = i18next.language || window.localStorage.i18nextLng;
const currentLocale = currentLanguage === "de" ? deDE : enUS;

export const theme = createTheme(
  {
    spacing: 2.5,
    shape: {
      borderRadius: 8,
    },

    tableRow: {
      "&$selected": {
        backgroundColor: "#e6e6e6",
      },
    },
    hover: {},
    selected: {},
    // overrides: {
    //   MuiFormControl: {
    //     root: {
    //       height: "50px",
    //     },
    //   },
    //   MuiInputBase: {
    //     root: {
    //       height: "20px",
    //     },
    //   },
    // },

    palette: {
      primary: {
        //light: "#757ce8",
        main: "#5e5d5d",
        //dark: "#002884",
        //contrastText: "#fff",
      },
      info: {
        main: "#1565C0",
        light: "#2196F3",
      },
      // secondary: {
      //   light: "#ff7961",
      //   main: "#f44336",
      //   dark: "#ba000d",
      //   contrastText: "#000",
      // },
    },
    label: {
      backgroundColor: "white",
    },
    typography: {
      body: {
        fontFamily: "Roboto",
      },
    },
    components: {
      MuiTableContainer: {
        styleOverrides: {
          root: {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "8px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&:not(.MuiAutocomplete-inputRoot):not(.MuiInputBase-multiline)": {
              height: "54px",
            },

            "&:not(.MuiAutocomplete-inputRoot) input": {
              lineHeight: "24px",
              padding: "15px 14px !important",
              height: "24px",
              fontSize: "16px",
              fontWeight: "400",
              letterSpacing: "0.15px",
              color: "rgba(0, 0, 0, 0.87)",
              borderRadius: 7.5,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: "#2196F3",
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          variant: "contained",
        },
        styleOverrides: {
          root: {
            "&.btn-center-text": {
              paddingTop: "12px",
              paddingBottom: "10px",
              lineHeight: "1",
            },
            "&.btn-theme-letter-space": {
              letterSpacing: "1.35px",
            },
            "&.btn-color-blue": {
              backgroundColor: "#1565C0",
            },
            "&.btn-color-grey": {
              backgroundColor: "rgb(207, 207, 207)",
            },
          },
        },
      },
    },
  },
  currentLocale
);
