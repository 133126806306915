import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  WORKSPACETYPE,
  STORAGE_PERSON_TYPE,
  STORAGE_STATUS,
} from "../../../core/constants";
import {
  WORKSPACE_TYPE,
  TOKEN_NAME,
} from "../../../core/apiconfig/constantApi";
import { storageIsPending } from "../../../core/utils/business";

const getStorageListFunc = async (ownerId, { getState }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
    let typeFilter = `arades_typ eq ${STORAGE_PERSON_TYPE.OWNER}`;

    if (workspaceType == WORKSPACETYPE.UNDER_AGE)
      typeFilter = `arades_typ eq ${STORAGE_PERSON_TYPE.UNDER_AGE}`;

    typeFilter += ` or arades_typ eq ${STORAGE_PERSON_TYPE.OTHER_PERSON}`;

    let fetchQuery = `${BASE_URL}data/arades_lagerplatz_persons/?$select=_arades_lagerplatz_value&$filter=(${typeFilter}) and _arades_besitzer_value eq '${ownerId}'&$expand=arades_lagerplatz($select=*;$filter=statecode eq 0 and _arades_uebergeordneter_lagerplatz_value eq null)`;

    const {
      data: { data },
    } = await axios.get(fetchQuery, config);

    let list = [];

    data.value.map((item) => {
      let storage = item.arades_lagerplatz;
      if (storage) {
        storage.pending = storageIsPending(storage);
        storage.metalName =
          storage[
            "_arades_edelmetall_typ_value@OData.Community.Display.V1.FormattedValue"
          ];
        storage.weightUnit =
          storage[
            "_arades_gewichtseinheit_value@OData.Community.Display.V1.FormattedValue"
          ];
        storage.statusText =
          storage.statuscode == STORAGE_STATUS.PENDING_FOR_CONTRACT
            ? "Warten auf Unterschrift"
            : storage.statuscode == STORAGE_STATUS.PENDING_FOR_PAYMENT
            ? "Warten auf Einrichtungsgebühr"
            : storage.statuscode == STORAGE_STATUS.PENDING_FOR_14_DAYS
            ? "Warten auf Widerruf"
            : storage.statuscode == STORAGE_STATUS.ACTIVE
            ? "Vollständig eingerichtet"
            : "";
        list.push(storage);
      }
    });
    return list;
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};
export default getStorageListFunc;
