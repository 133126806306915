import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { METAL_CHARS } from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { MetalPart } from "../../../core/utils/business";
import { Currency, dateString } from "../../../core/utils/convert";
import { getRatiorDataFunc } from "../../slices/globals";

const getCommissionListFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let ratiorCompanies = await getRatiorDataFunc({}, { getState });

  let fetchQuery = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="arades_forderung">
      <attribute name="arades_glaeubiger" />
      <attribute name="arades_forderungid" />
      <attribute name="arades_betrag" />
      <attribute name="arades_prozentsatz" />
      <attribute name="arades_auftrag" />
      <attribute name="arades_rabatt_partner" />
      <attribute name="createdon" />
      <filter type="and">
        <condition attribute="statuscode" operator="eq" value="100000001" />
        <filter type="or">
          <filter type="and">
              <condition attribute="arades_glaeubiger" operator="eq" value="${ratiorCompanies.ratiorAgAccountId}" />
              <condition attribute="arades_provisionstyp" operator="eq" value="100000000" />
          </filter>
          <filter type="and">
              <condition attribute="arades_glaeubiger" operator="eq" value="${ratiorCompanies.ratiorGmbHAccountId}" />
              <condition attribute="arades_provisionstyp" operator="eq" value="100000001" />
          </filter>
          <filter type="and">
              <condition attribute="arades_glaeubiger" operator="eq" value="${ratiorCompanies.ratiorGoldAccountId}" />
              <condition attribute="arades_provisionstyp" operator="eq" value="100000001" />
          </filter>
        </filter>        
      </filter>
      <link-entity name="arades_vermittlerkonto" from="arades_vermittlerkontoid" to="arades_vermittlerkonto" link-type="inner" alias="ab">
        <filter type="and">
          <condition attribute="arades_firma" operator="eq" value="${filter.workspaceId}" />
        </filter>
      </link-entity>
      <link-entity name="salesorder" from="salesorderid" to="arades_auftrag" link-type="outer" alias="so">
        <attribute name="salesorderid" />
        <attribute name="arades_geldeingang" />
        <attribute name="discountpercentage" />
        <attribute name="arades_lagerplatzstammnummer" />
        <attribute name="createdon" />
        <attribute name="arades_lagerplatz_kaeufer" />
        <attribute name="arades_ueberweisungsbetrag" />
        <attribute name="arades_anlagebetrag" />
        <attribute name="totalamount" />
        <attribute name="customerid" />                
      </link-entity>
      <link-entity name="invoice" from="invoiceid" to="arades_rechnung" link-type="outer" alias="iv">
        <attribute name="invoiceid" />
        <attribute name="arades_geldeingang" />
        <attribute name="discountpercentage" />
        <attribute name="arades_lagerplatzstammnummer" />
        <attribute name="createdon" />
        <attribute name="arades_lagerplatz_kaeufer" />
        <attribute name="arades_ueberweisungsbetrag" />
        <attribute name="arades_anlagebetrag" />
        <attribute name="totalamount" />
        <attribute name="customerid" />                
      </link-entity>
    </entity>
  </fetch>`;

  //<condition attribute="statuscode" operator="eq" value="100000001" />

  var encodedFetchXml = encodeURI(fetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/arades_forderungs?fetchXml=${encodedFetchXml}`,
    config
  );

  // let fetchQuery = `${BASE_URL}data/arades_forderungs/?$select=_arades_glaeubiger_value,arades_forderungid,arades_betrag,arades_prozentsatz,&$filter= statuscode eq 1 and _arades_vermittlerkonto_value eq ${filter.workspaceId}&$expand=arades_auftrag($select=arades_geldeingang,discountpercentage,arades_lagerplatzstammnummer,createdon,_arades_lagerplatz_kaeufer_value,arades_ueberweisungsbetrag,arades_anlagebetrag,totalamount)`;

  // const result = await axios.get(fetchQuery, config);
  if (result.data.status != 200)
    throw Error("Provisionenliste könnte nicht geladen werden"); //("commissionList not fetched");

  let list = [];
  for (let i = 0; i < result.data?.data?.value.length; i++) {
    let item = result.data.data.value[i];
    let alias =
      item._arades_glaeubiger_value == ratiorCompanies.ratiorAgAccountId
        ? "iv"
        : "so";
    let storageName =
      item[
        alias +
          ".arades_lagerplatz_kaeufer@OData.Community.Display.V1.FormattedValue"
      ];
    let tmp = {
      arades_forderungid: item.arades_forderungid,
      _arades_glaeubiger_value: item._arades_glaeubiger_value,
      arades_betrag: item.arades_betrag,
      arades_rabatt_partner: item.arades_rabatt_partner,
      arades_rabatt_partner_formatted: item.arades_rabatt_partner
        ? item.arades_rabatt_partner + "%"
        : "",
      arades_betrag_formatted: Currency(item.arades_betrag),
      arades_prozentsatz: item.arades_prozentsatz,
      arades_prozentsatz_formatted: item.arades_prozentsatz
        ? item.arades_prozentsatz + " %"
        : "",
      arades_geldeingang: item[alias + ".arades_geldeingang"],
      arades_geldeingang_formatted: dateString(
        item[alias + ".arades_geldeingang"]
      ),
      arades_anlagebetrag: item[alias + ".arades_anlagebetrag"],
      arades_anlagebetrag_formatted: Currency(
        item[alias + ".arades_anlagebetrag"]
      ),
      arades_ueberweisungsbetrag: item[alias + ".arades_ueberweisungsbetrag"],
      arades_ueberweisungsbetrag_formatted: Currency(
        item[alias + ".arades_ueberweisungsbetrag"]
      ),
      arades_lagerplatzstammnummer:
        item[alias + ".arades_lagerplatzstammnummer"],
      discountpercentage: item[alias + ".discountpercentage"],
      discountpercentage_formatted: item[alias + ".discountpercentage"]
        ? item[alias + ".discountpercentage"] + " %"
        : "",
      totalamount: item[alias + ".totalamount"],
      totalamount_formatted: Currency(item[alias + ".totalamount"]),
      detailDate: item[alias + ".createdon"],
      detailDate_formatted: dateString(item[alias + ".createdon"]),
      _arades_lagerplatz_kaeufer_value:
        item[alias + ".arades_lagerplatz_kaeufer"],
      storageName: storageName,
      customerName:
        item[alias + ".customerid@OData.Community.Display.V1.FormattedValue"],
      productName:
        MetalPart(storageName) == METAL_CHARS.GOLD
          ? "Gold"
          : MetalPart(storageName) == METAL_CHARS.SILVER
          ? "Silber"
          : MetalPart(storageName) == METAL_CHARS.PLATIN
          ? "Platin"
          : MetalPart(storageName) == METAL_CHARS.PALLADIUM
          ? "Palladium"
          : "",
    };

    console.log(tmp);
    list.push(tmp);
  }

  let lists = {
    commissionListAG: list.filter(
      (x) => x._arades_glaeubiger_value == ratiorCompanies.ratiorAgAccountId
    ),
    commissionListGmbh: list.filter(
      (x) => x._arades_glaeubiger_value == ratiorCompanies.ratiorGmbHAccountId
    ),
    commissionListGoldGmbh: list.filter(
      (x) => x._arades_glaeubiger_value == ratiorCompanies.ratiorGoldAccountId
    ),
  };

  // lists = {
  //   ...lists,
  //   sumAGBetrag: lists.commissionListAG?.reduce(
  //     (n, { arades_betrag }) => n + arades_betrag,
  //     0
  //   ),
  //   sumGmbhBetrag: lists.commissionListGmbh?.reduce(
  //     (n, { arades_betrag }) => n + arades_betrag,
  //     0
  //   ),
  //   sumGoldGmbhBetrag: lists.commissionListGoldGmbh?.reduce(
  //     (n, { arades_betrag }) => n + arades_betrag,
  //     0
  //   ),
  // };

  return lists;
};
export default getCommissionListFunc;
