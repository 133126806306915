import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  CONTACT_ID,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
  TOKEN_NAME,
} from "../../../core/apiconfig/constantApi";
import { STORAGE_PERSON_TYPE, WORKSPACETYPE } from "../../../core/constants";

//gets the storage partner or the guardian other than logged-in one
const getPartnerDataFunc = async (storageId, { getState }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let filter = { storageId: storageId };

    if (localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE)
      filter.ownerId = localStorage.getItem(CONTACT_ID);
    else filter.ownerId = localStorage.getItem(WORKSPACE_ID);

    let storagePersonResult = await axios.get(
      `${BASE_URL}data/arades_lagerplatz_persons/?$select=arades_lagerplatz_personid&$filter=_arades_lagerplatz_value eq '${filter.storageId}' and _arades_besitzer_value ne ${filter.ownerId} and arades_typ ne ${STORAGE_PERSON_TYPE.UNDER_AGE} &$expand=arades_besitzer_contact($select=*)`,
      config
    );

    if (storagePersonResult?.data?.data?.value?.length == 0)
      throw Error("Partner nicht gefunden"); //("Partner not found");

    let partner = storagePersonResult.data.data.value.filter(
      (x) => x.arades_besitzer_contact
    )[0].arades_besitzer_contact;

    return partner;
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};

export default getPartnerDataFunc;
