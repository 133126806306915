import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { dateTimeString } from "../../../core/utils/convert";

const getContactsList = async (vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  var contactsFetchQuery = `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="contact">
      <attribute name="firstname"/>
      <attribute name="lastname"/>
      <attribute name="contactid"/>
      <attribute name="createdon"/>
      <attribute name="arades_ratior_key"/>
      <attribute name="statuscode"/>
      <attribute name="arades_titel"/>
      <attribute name="arades_anrede"/>
      <attribute name="arades_email_verifiziert"/>
      <attribute name="arades_mobilnummer_verifiziert"/>
      <attribute name="arades_adresse_verifiziert"/>
      <link-entity name="arades_vermittlerkonto" from="arades_vermittlerkontoid" to="arades_vermittlerkonto" link-type="inner" alias="ab">
        <filter type="and">
          <condition attribute="arades_firma" operator="eq" value="{${vermittlerId}}"/>
        </filter>
      </link-entity>
      <link-entity name="arades_titel" from="arades_titelid" to="arades_titel" link-type="outer" alias="t">
        <attribute name="arades_name"/>        
      </link-entity>
    </entity>
  </fetch>`;

  var encodedFetchXml = encodeURI(contactsFetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/contacts?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200) throw Error("Firmen nicht geladen werden"); //("companies not fetched");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((item) => {
    let tmp = {
      id: item.contactid,
      type: "contact",
      storages: [],
      name:
        (item["t.arades_name"]
          ? item["t.arades_name"]
          : item.arades_anrede == "100000000"
          ? "Herr"
          : item.arades_anrede == "100000001"
          ? "Frau"
          : item.arades_anrede == "100000001"
          ? "Divers"
          : "") +
        " " +
        item.firstname +
        " " +
        item.lastname,
      ratiorKey: item["arades_ratior_key"],
      status:
        item["statuscode"] == 0
          ? "Active"
          : item["statuscode"] == 1
          ? "Inactive"
          : item["statuscode"] == "100000001"
          ? "Invited"
          : item["statuscode"] == "100000000"
          ? "Verifiziert"
          : "",
      lagerplatz: [],
      createDate: item.createdon,
      createDate_formatted: dateTimeString(item.createdon),
      verifiedStatus: !item["arades_email_verifiziert"]
        ? 0
        : !item["arades_mobilnummer_verifiziert"]
        ? 1
        : !item["arades_adresse_verifiziert"]
        ? 2
        : 3,
    };
    list.push(tmp);
  });

  return list;
};

const getAccountsList = async (vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  var accountsFetchQuery = `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="account">
      <attribute name="name"/>
      <attribute name="primarycontactid"/>
      <attribute name="accountid"/>
      <attribute name="createdon"/>
      <link-entity name="arades_vermittlerkonto" from="arades_vermittlerkontoid" to="arades_vermittlerkonto" link-type="inner" alias="ad">
        <filter type="and">
          <condition attribute="arades_firma" operator="eq" value="{${vermittlerId}}"/>
        </filter>
      </link-entity>
      <link-entity name="contact" from="contactid" to="primarycontactid" link-type="inner" alias="pc">
        <attribute name="arades_ratior_key"/>
        <attribute name="statuscode"/>
        <attribute name="arades_email_verifiziert"/>
        <attribute name="arades_mobilnummer_verifiziert"/>
        <attribute name="arades_adresse_verifiziert"/>
      </link-entity>
    </entity>
  </fetch>`;

  var encodedFetchXml = encodeURI(accountsFetchQuery);
  const result = await axios.get(
    `${BASE_URL}data/accounts?fetchXml=${encodedFetchXml}`,
    config
  );

  if (result?.data?.status != 200) throw Error("Firmen nicht geladen werden"); //("companies not fetched");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((item) => {
    let tmp = {
      id: item.accountid,
      type: "account",
      storages: [],
      name: item.name,
      ratiorKey: item["pc.arades_ratior_key"],
      status:
        item["pc.statuscode"] == 0
          ? "Active"
          : item["pc.statuscode"] == 1
          ? "Inactive"
          : item["pc.statuscode"] == "100000001"
          ? "Invited"
          : item["pc.statuscode"] == "100000000"
          ? "Verifiziert"
          : "",
      lagerplatz: [],
      createDate: item.createdon,
      createDate_formatted: dateTimeString(item.createdon),
      verifiedStatus: !item["pc.arades_email_verifiziert"]
        ? 0
        : !item["pc.arades_mobilnummer_verifiziert"]
        ? 1
        : !item["pc.arades_adresse_verifiziert"]
        ? 2
        : 3,
    };
    list.push(tmp);
  });

  return list;
};
const getCustomerDetailedListFunc = async ({ vermittlerId }, { getState }) => {
  const contacts = await getContactsList(vermittlerId);
  const accounts = await getAccountsList(vermittlerId);
  return contacts.concat(accounts).sort((a, b) => (a.name > b.name ? 1 : -1));
};
export default getCustomerDetailedListFunc;
