import React, { useEffect, useState } from "react";
import StoragePaymentStep from "./addStorage/sharedSteps/storagePaymentStep.step";
import { Typography } from "@mui/material";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { getActionCodeCustomerDataAction } from "../../redux/slices/storage";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CODE_TYPE } from "../../core/constants";

function JustViewStoragePayment() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getActionCodeCustomerDataAction({
        customerId: localStorage.getItem(WORKSPACE_ID),
        actionCodeType: ACTION_CODE_TYPE.STORAGE_FEE,
        justActive: true,
      })
    );
  }, []);

  return (
    <div className="container-card">
      <Typography
        mb="13px"
        sx={{
          fontSize: "24px",
          fontWeight: "500",
        }}
      >
        Einrichtungsgebühr
        {/* {"Payment"} */}
      </Typography>

      <StoragePaymentStep justSign={true}></StoragePaymentStep>
    </div>
  );
}

export default JustViewStoragePayment;
