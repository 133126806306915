import React from 'react';
import { TimerActiveButton } from '..';
import InputCode from '../../home/common/inputCode'
import { useTranslation } from "react-i18next";

function ActivationCodeHandler({time,loading,activationCode,setActivationCode,resendCode}) {
  const {t}=useTranslation()
  return <>
    <InputCode
        length={4}
        // label="Code Label"
        loading={loading}
        clearMe={!activationCode}
        onComplete={(code) => {
        setActivationCode(code);
        }}
    />
    <TimerActiveButton buttonText={t("components.activation-code-handler.resend-code")}  time={time} handleClick={()=>{
      setActivationCode(null)
      resendCode()
    }}  />
  </>;
}

export default ActivationCodeHandler;
